import React, {Component} from 'react';
import {connect} from 'react-redux';
import {signIn} from '../../store/actions/authActions';
import {Link, Redirect} from 'react-router-dom';
import Grid from '@material-ui/core/Grid/Grid';
import TextField from '@material-ui/core/TextField/TextField';
import Button from '@material-ui/core/Button/Button';
import withStyles from '@material-ui/core/styles/withStyles';
import {sectionStyles} from '../../styles/sectionStyles';
import Header from '../layout/Header';



class SignIn extends Component {
  state = {
    email: '',
    password: ''
  };
  handleOnChangeInput = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    const redirect = () => this.props.history.push('/accounts');
    this.props.signIn({credentials: this.state, redirect});
  };
  render() {
    const {auth, classes} = this.props;
    if (auth.accessToken) {
      return <Redirect to='/accounts' />
    }

    const title = `MY MONEY TRACKER`;
    return (
        <div className={classes.wrapperSection}>
          <Header title={title}/>
          <div className={classes.wrapperSectionContent}>
            <div className={classes.appBarSpacer} />
            <div className={classes.appBarSpacer} />
            <Grid container className={classes.sectionContent}>
              <Grid item xs={12}>
                <form className={classes.form} noValidate autoComplete="off">
                  <TextField id="email" label="Email" value={this.state.email} onChange={this.handleOnChangeInput}/>
                  <TextField type="password" id="password" label="Contraseña" value={this.state.password} onChange={this.handleOnChangeInput}/>
                  <Button variant="contained" color="primary" onClick={this.handleSubmit}>
                    CONTINUAR
                  </Button>
                  <p>¿No tienes cuenta? <Button component={Link} to={"/register"}>Regístrate</Button></p>
                </form>
              </Grid>
            </Grid>
          </div>
        </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signIn: (credentials) => dispatch(signIn(credentials))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(sectionStyles)(SignIn));