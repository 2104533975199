//Account list
import Api from '../../services/Api';

export const FETCH_ACCOUNTS = 'FETCH_ACCOUNTS';
export const FETCH_ACCOUNTS_SUCCESS = 'FETCH_ACCOUNTS_SUCCESS';
export const FETCH_ACCOUNTS_FAILURE = 'FETCH_ACCOUNTS_FAILURE';
export const RESET_ACCOUNTS = 'RESET_ACCOUNTS';

//Create new account
export const CREATE_ACCOUNT = 'CREATE_ACCOUNT';
export const CREATE_ACCOUNT_SUCCESS = 'CREATE_ACCOUNT_SUCCESS';
export const CREATE_ACCOUNT_FAILURE = 'CREATE_ACCOUNT_FAILURE';
export const RESET_NEW_ACCOUNT = 'RESET_NEW_ACCOUNT';

//Fetch account
export const FETCH_ACCOUNT = 'FETCH_ACCOUNT';
export const FETCH_ACCOUNT_SUCCESS = 'FETCH_ACCOUNT_SUCCESS';
export const FETCH_ACCOUNT_FAILURE = 'FETCH_ACCOUNT_FAILURE';
export const RESET_ACTIVE_ACCOUNT = 'RESET_ACTIVE_ACCOUNT';

//Update account
export const UPDATE_ACCOUNT = 'UPDATE_ACCOUNT';
export const UPDATE_ACCOUNT_SUCCESS = 'UPDATE_ACCOUNT_SUCCESS';
export const UPDATE_ACCOUNT_FAILURE = 'UPDATE_ACCOUNT_FAILURE';

//Delete account
export const DELETE_ACCOUNT = 'DELETE_ACCOUNT';
export const DELETE_ACCOUNT_SUCCESS = 'DELETE_ACCOUNT_SUCCESS';
export const DELETE_ACCOUNT_FAILURE = 'DELETE_ACCOUNT_FAILURE';
export const RESET_DELETED_ACCOUNT = 'RESET_DELETED_ACCOUNT';

export const FETCH_ACCOUNTS_TYPES = 'FETCH_ACCOUNTS_TYPES';
export const FETCH_ACCOUNTS_TYPES_SUCCESS = 'FETCH_ACCOUNTS_TYPES_SUCCESS';
export const FETCH_ACCOUNTS_TYPES_FAILURE = 'FETCH_ACCOUNTS_TYPES_FAILURE';

export function fetchAccounts() {
  return (dispatch) => {
    const request = Api.getInstance().get('/accounts');
    dispatch({
      type: FETCH_ACCOUNTS
    });
    request
        .then((response) => {
          if (response.error) {
            return dispatch(fetchAccountsFailure(response.data));
          }

          return dispatch(fetchAccountsSuccess(response.data));
        })
        .catch(error => {
          console.log(error);
        })
  }
}

export function fetchAccountsSuccess(accounts) {
  return {
    type: FETCH_ACCOUNTS_SUCCESS,
    payload: accounts
  };
}

export function fetchAccountsFailure(error) {
  return {
    type: FETCH_ACCOUNTS_FAILURE,
    payload: error
  };
}

export function resetAccounts() {
  return {
    type: RESET_ACCOUNTS
  }
}


export function editAccount({account, redirect}) {
  return (dispatch) => {
    const {id, ...params} = account;
    const request = Api.getInstance().put(`/accounts/${id}`, {
      ...params
    });
    dispatch({
      type: UPDATE_ACCOUNT
    });

    request.then(response => {
      if (response.error) {
        return dispatch(editAccountFailure(response.data));
      }
      dispatch(editAccountSuccess(response.data));
      dispatch(resetAccounts());
      if (redirect) {
        return redirect();
      }
    })
  }
}

export function editAccountSuccess(posts) {
  return {
    type: UPDATE_ACCOUNT_SUCCESS,
    payload: posts
  };
}

export function editAccountFailure(error) {
  return {
    type: UPDATE_ACCOUNT_FAILURE,
    payload: error
  };
}

export function resetActiveAccount() {
  return {
    type: RESET_ACTIVE_ACCOUNT
  }
}

export function resetNewAccount() {
  return {
    type: RESET_NEW_ACCOUNT
  }
}

export function resetDeletedAccount() {
  return {
    type: RESET_DELETED_ACCOUNT
  }
}

export function createAccount({account, redirect}) {
  return (dispatch) => {
    const {...params} = account;
    const request = Api.getInstance().post('/accounts', {
      ...params
    });
    dispatch({
      type: CREATE_ACCOUNT
    });

    request.then(response => {
      if (response.error) {
        return dispatch(createAccountFailure(response.data));
      }
      dispatch(createAccountSuccess(response.data));
      dispatch(resetAccounts());
      if (redirect) {
        return redirect();
      }
    })
  }
}

export function createAccountSuccess(posts) {
  return {
    type: CREATE_ACCOUNT_SUCCESS,
    payload: posts
  };
}

export function createAccountFailure(error) {
  return {
    type: CREATE_ACCOUNT_FAILURE,
    payload: error
  };
}

export function fetchAccount(id) {
  return (dispatch) => {
    const request = Api.getInstance().get(`/accounts/${id}`);
    dispatch({
      type: FETCH_ACCOUNT
    });

    request.then(response => {
      !response.error ? dispatch(fetchAccountSuccess(response.data)) : dispatch(fetchAccountFailure(response.data));
    })
  }
}

export function fetchAccountSuccess(msg) {
  return {
    type: FETCH_ACCOUNT_SUCCESS,
    payload: msg
  };
}

export function fetchAccountFailure(error) {
  return {
    type: FETCH_ACCOUNT_FAILURE,
    payload: error
  };
}

export function deleteAccount({id, redirect}) {
  return (dispatch) => {
    const request = Api.getInstance().delete(`/accounts/${id}`);
    dispatch({
      type: DELETE_ACCOUNT
    });

    request.then((response) => {
      if (response.error) {
        return dispatch(deleteAccountFailure(response.data));
      }
      dispatch(deleteAccountSuccess(response.data));
      dispatch(resetAccounts());
      if (redirect) {
        return redirect();
      }
    });
  }
}

export function deleteAccountSuccess(msg) {
  return {
    type: DELETE_ACCOUNT_SUCCESS,
    payload: msg
  };
}

export function deleteAccountFailure(error) {
  return {
    type: DELETE_ACCOUNT_FAILURE,
    payload: error
  };
}

export function fetchAccountTypes() {
  return (dispatch) => {
    const request = Api.getInstance().get('/account-types');
    dispatch({
      type: FETCH_ACCOUNTS_TYPES
    });
    request
      .then((response) => {
        if (response.error) {
          return dispatch(fetchAccountTypesFailure(response.data));
        }

        return dispatch(fetchAccountTypesSuccess(response.data));
      })
      .catch(error => {
        console.log(error);
      })
  }
}

export function fetchAccountTypesSuccess(accountTypes) {
  return {
    type: FETCH_ACCOUNTS_TYPES_SUCCESS,
    payload: accountTypes
  };
}

export function fetchAccountTypesFailure(error) {
  return {
    type: FETCH_ACCOUNTS_TYPES_FAILURE,
    payload: error
  };
}