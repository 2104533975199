export const INSERT_NUMERIC_VALUE = 'Introduce valor numérico';
export const getHelperTextNumericValue = amount => amount ? INSERT_NUMERIC_VALUE : '';
export const isNumberField = field => !isNaN(field);

export const INSERT_COMMA_SEPARATED_VALUE = 'Introduce valores separados por comas';
export const getHelperTextCommaSeparatedValue = amount => amount ? INSERT_COMMA_SEPARATED_VALUE : '';
export const isCommaSeparatedField = field => {
  // Verificar si el string está vacío
  if (field.length === 0) {
    return false;
  }

  // Dividir el string en substrings usando la coma como separador
  const substrings = separateFieldInCommas(field);

  // Verificar si todos los substrings están compuestos por caracteres alfanuméricos
  const regex = /^[a-zA-Z0-9.@]+$/;
  for (let i = 0; i < substrings.length; i++) {
    if (!regex.test(substrings[i])) {
      return false;
    }
  }

  return true;
};
export const separateFieldInCommas = field => field.replace(' ', '').split(',');