import React from 'react';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import IconButton from '@material-ui/core/IconButton/IconButton';
import {addCurrency} from '../../utils/currencyUtils';
import {sectionStyles} from '../../styles/sectionStyles';
import {withStyles} from '@material-ui/core';

class Category extends React.Component {
  getIconDropDownButton() {
    const {isCollapsed} = this.props;
    return isCollapsed ? <ArrowRightIcon /> : <ArrowDropDownIcon />;
  }

  getButtonDropDown() {
    const {isDropDownable, handleOnClickDropDown, classes} = this.props;
    if (!isDropDownable) {
      return null;
    }
    const icon = this.getIconDropDownButton();
    return <IconButton className={classes.iconButtonSmall} size="small" aria-label="dropdown-switch" onClick={handleOnClickDropDown}>{icon}</IconButton>;
  }

  render() {
    const {node, isDropDownable} = this.props;
    const amountTotal = addCurrency(node.amountTotal[0]);
    const drownDownButton = this.getButtonDropDown();
    const styleExtraSpace = !isDropDownable ? {paddingLeft: '22px'} : null;
    return (
        <span style={styleExtraSpace}>{drownDownButton}{node.name}: {amountTotal}</span>
    )
  }
}

export default withStyles(sectionStyles)(Category);