import React from 'react';
import Grid from '@material-ui/core/Grid/Grid';
import {Link} from 'react-router-dom';
import {sectionStyles} from '../../styles/sectionStyles';
import {withStyles} from '@material-ui/core';
import {addCurrency} from '../../utils/currencyUtils';
import Avatar from '@material-ui/core/Avatar/Avatar';
import Button from '@material-ui/core/Button/Button';

const GroupSummary = ({group, classes}) => {
  return (
    <Grid item xs={12}>
      <Button component={Link} underline={`none`} className={classes.listItemsLinks} to={`/groups/${group.id}`}>
        <div className={classes.headerContentWrapper}>
          <div className={classes.headerContentWrapper2}>
            <div className={classes.groupSummaryWrapper}>
              <Avatar src={group.file} className={classes.groupSummaryAvatar}/>
              <div style={{width: '100%'}}>
                {group.name}
                {
                  !group.isPendingUser && group.transactionSummationByUser && group.transactionSummationByUser.map((el, index) => {
                    return (
                      <div key={index} className={classes.groupSummaryWrapperEmailAndPrice}>
                        <span>{el.user.email}</span> <span>{addCurrency(el.transactionSummation[0])}</span>
                      </div>
                    );
                  })
                }
                {
                  !group.isPendingUser ? <>
                      <br/>
                      <div
                        className={`${group.balance === -1 ? classes.red : group.balance === 1 ? classes.green : classes.white} ${classes.groupSummaryWrapperEmailAndPrice}`}>
                            <span>
                              {group.balance === -1 ? 'debes' : group.balance === 1 ? 'te deben' : 'no hay deudas'}
                            </span> <span>{addCurrency(Math.abs(group.userBalance[0]))}</span>
                      </div>
                    </> :
                    <div className={classes.yellow}>
                      <span>Invitación Pendiente</span>
                    </div>
                }
              </div>
            </div>
            <div>
            </div>
          </div>
        </div>
      </Button>
    </Grid>
  )
};

export default withStyles(sectionStyles)(GroupSummary);