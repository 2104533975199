import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Toolbar from '@material-ui/core/Toolbar/Toolbar';
import AppBar from '@material-ui/core/AppBar/AppBar';
import {sectionStyles} from '../../styles/sectionStyles';
import Menu from '@material-ui/core/Menu/Menu';
import {AccountCircle} from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton/IconButton';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import {signOut} from '../../store/actions/authActions';
import {connect} from "react-redux";
import {withRouter} from 'react-router-dom';

class Header extends React.Component {
  state = {
    anchorEl: null
  };

  handleProfileMenuOpen = (event) => {
    this.setState({
      anchorEl: event.currentTarget
    });
  };

  handleSignOut = (event) => {
    event.preventDefault();
    const redirect = () => this.props.history.push('/signin');
    this.props.signOut({redirect});
  };

  handleClose = () => {
    this.setState({
      anchorEl:null
    });
  };

  render() {
    const {classes, title, headerInfo, auth} = this.props;
    return (
        <AppBar color={`default`} className={classes.header}>
          <Toolbar disableGutters={true}>
            <div className={classes.headerContentWrapper}>
              <div className={classes.headerContentWrapper2}>
                <div>
                  {auth.accessToken && (
                      <>
                        <IconButton
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={this.handleProfileMenuOpen}
                            color="inherit"
                            className={classes.headerContentMenuButton}
                        >
                          <AccountCircle />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={this.state.anchorEl}
                            anchorOrigin={{
                              vertical: 'top',
                              horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'right',
                            }}
                            open={Boolean(this.state.anchorEl)}
                            onClose={this.handleClose}
                        >
                          <MenuItem onClick={this.handleSignOut}>Sign Out</MenuItem>
                        </Menu>
                      </>
                  )}
                  {title}
                </div>
                { headerInfo ? <div className={headerInfo.classes}>
                  {headerInfo.value}
                </div> : null}

              </div>
            </div>
          </Toolbar>
        </AppBar>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signOut: ({redirect}) => dispatch(signOut({redirect}))
  }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(sectionStyles)(Header)));

