import React from 'react';
import Grid from '@material-ui/core/Grid/Grid';
import {withStyles} from '@material-ui/core';
import Fab from '@material-ui/core/Fab/Fab';
import {sectionStyles} from '../../styles/sectionStyles';
import Header from '../layout/Header';
import FilterListIcon from '@material-ui/icons/FilterList';
import FilterSummaryModal from './FilterSummaryModal';
import CategoriesList from './CategoriesList';
import {compose} from 'redux';
import connect from 'react-redux/es/connect/connect';
import {Redirect} from 'react-router-dom';
import {fetchCategoriesWithTransactions} from '../../store/actions/categoryActions';
import {
  FILTER_PARAMS_SUMMARY,
  handleOnChangeParamsFilter
} from "../../store/actions/filterActions";
import DateAdapterFactory from "../../utils/date/DateAdapterFactory";
import {fetchAccounts} from "../../store/actions/accountActions";

class Summary extends React.Component {
  state = {
    open: false
  };

  componentDidMount() {
    if (!this.props.auth.accessToken) {
      return;
    }
    this.props.fetchAccounts();
  }

  handleClickOpen = () => {
    this.setState({open: true});
  };

  handleClose = () => {
    this.setState({
      open: false
    });
  };

  handleSetFilter = (state) => {
    const type = FILTER_PARAMS_SUMMARY;
    this.props.handleOnChangeParamsFilter({
      type,
      params: {
        ...state
      }
    });
    this.setState({
        open: false
    }, () => {
      const {dateStart, dateEnd, accountsExcluded} = this.props.filterSummary;
      this.props.fetchCategoriesWithTransactions({
        dateStart: DateAdapterFactory.createInstance(dateStart).getStartDayMoment().toISOString(),
        dateEnd: DateAdapterFactory.createInstance(dateEnd).getEndDayMoment().toISOString(),
        accountsExcluded,
      });
    });
  };

  render() {

    const {classes, auth, filterSummary} = this.props;

    if (!auth.accessToken) {
      return <Redirect to='/signin' />
    }

    const title = `Resumen`;
    const headerInfo = {
      value: `${DateAdapterFactory.createInstance(filterSummary.dateStart).toDisplay()} - ${DateAdapterFactory.createInstance(filterSummary.dateEnd).toDisplay()}`,
      classes: null
    };

    return (
      <div className={classes.wrapperSection}>
        <Header title={title} headerInfo={headerInfo}/>
        <div className={classes.wrapperSectionContent}>
          <div className={classes.appBarSpacer} />
          <Grid container className={classes.sectionContent}>
            <Grid item xs={12}>
              <CategoriesList dateStart={filterSummary.dateStart} dateEnd={filterSummary.dateEnd} accountsExcluded={filterSummary.accountsExcluded} nestedTree={filterSummary.nestedTree}/>
            </Grid>
          </Grid>
          <div className={classes.appBarSpacer}/>
          <div className={classes.appBarSpacer}/>
        </div>
        <div className={classes.navbarSection}>
          <Fab color={`primary`} aria-label="calendar-filter" onClick={this.handleClickOpen} size={`small`}>
            <FilterListIcon/>
          </Fab>
        </div>
        <FilterSummaryModal open={this.state.open} dateStart={filterSummary.dateStart} dateEnd={filterSummary.dateEnd} accountsExcluded={filterSummary.accountsExcluded} nestedTree={filterSummary.nestedTree} handleClose={this.handleClose} handleSetFilter={this.handleSetFilter} />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    filterSummary: state.filter.summary,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchCategoriesWithTransactions: (data) => dispatch(fetchCategoriesWithTransactions(data)),
    handleOnChangeParamsFilter: (data) => dispatch(handleOnChangeParamsFilter(data)),
    fetchAccounts: () => dispatch(fetchAccounts()),
  }
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(sectionStyles)
)(Summary);

