import React from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import TransactionForm from './TransactionForm';
import LinearProgress from '@material-ui/core/LinearProgress/LinearProgress';
import {withStyles} from '@material-ui/core';
import {sectionStyles} from '../../styles/sectionStyles';
import {Redirect} from 'react-router-dom';
import {fetchTransaction} from '../../store/actions/transactionActions';
import {fetchCategories,} from '../../store/actions/categoryActions';
import {fetchAccounts, fetchAccountTypes} from '../../store/actions/accountActions';
import {fetchGroups} from "../../store/actions/groupActions";

class TransactionEdit extends React.Component {
  componentDidMount() {
    if (!this.props.auth.accessToken) {
      return;
    }
    this.props.fetchTransaction(this.props.id);
    this.props.fetchCategories();
    this.props.fetchAccounts();
    this.props.fetchAccountTypes();
    this.props.fetchGroups();
  }

  render() {
    const {classes, auth} = this.props;

    if (!auth.accessToken) {
      return <Redirect to='/signin' />
    }

    const {transaction, isFetching, error} = this.props.activeTransaction;

    if (!transaction) {
      return null;
    }

    if (isFetching) {
      return (
          <LinearProgress />
      )
    }

    if (error) {
      return (
          <LinearProgress />
      )
    }

    return (
        <TransactionForm transaction={transaction} type={`edit`} className={classes.wrapperSection}/>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    id: ownProps.match.params.id,
    auth: state.auth,
    activeTransaction: state.transaction.activeTransaction,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchTransaction: (id) => dispatch(fetchTransaction(id)),
    fetchCategories: () => dispatch(fetchCategories()),
    fetchAccounts: () => dispatch(fetchAccounts()),
    fetchAccountTypes: () => dispatch(fetchAccountTypes()),
    fetchGroups: () => dispatch(fetchGroups()),
  }
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(sectionStyles)
)(TransactionEdit);