import React from 'react';
import {connect} from 'react-redux';
import TransactionSummary from './TransactionSummary';
import LinearProgress from '@material-ui/core/LinearProgress/LinearProgress';
import Grid from '@material-ui/core/Grid/Grid';

class TransactionList extends React.Component {
  render() {
    const {transactions, isFetching, error} = this.props.transactionList;

    if (isFetching) {
      return (
          <LinearProgress />
      )
    }

    if (error) {
      return (
          <p>{error}</p>
      )
    }

    if (transactions.length === 0) {
      return (
          <p>No hay Transacciones creadas</p>
      )
    }

    return (
        <Grid container>
          {
            transactions && transactions.map(transaction => {
              return(
                  <TransactionSummary selectedTransactions={this.props.selectedTransactions} handleOnChangeTransactionCheckbox={this.props.handleOnChangeTransactionCheckbox} showTransactionCheckbox={this.props.showTransactionCheckbox} key={transaction.id} transaction={transaction} />
              )
            })
          }
        </Grid>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    transactionList: state.transaction.transactionList,
  };
};

export default connect(mapStateToProps)(TransactionList);