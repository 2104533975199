import React from 'react';
import GroupForm from './GroupForm';
import {sectionStyles} from '../../styles/sectionStyles';
import {withStyles} from '@material-ui/core';
import {Redirect} from 'react-router-dom';
import {connect} from "react-redux";
import {compose} from 'redux';

class GroupCreate extends React.Component {
  render() {
    const {classes, auth} = this.props;

    if (!auth.accessToken) {
      return <Redirect to='/signin' />
    }

    return (
        <GroupForm className={classes.wrapperSection}/>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default compose(
    connect(mapStateToProps),
    withStyles(sectionStyles)
)(GroupCreate);