import React from 'react';
import SubTransactionForm from './SubTransactionForm';
import {withStyles} from '@material-ui/core';
import {sectionStyles} from '../../styles/sectionStyles';
import {compose} from 'redux';
import connect from 'react-redux/es/connect/connect';
import {Redirect} from 'react-router-dom';
import {fetchCategories} from '../../store/actions/categoryActions';
import {fetchTransaction} from "../../store/actions/transactionActions";

class SubTransactionCreate extends React.Component {
  componentDidMount() {
    if (!this.props.auth.accessToken) {
      return;
    }
    this.props.fetchTransaction(this.props.transactionId);
    this.props.fetchCategories();
  }

  render() {
    const {classes, auth, transactionId} = this.props;

    if (!auth.accessToken) {
      return <Redirect to='/signin' />
    }

    return (
        <SubTransactionForm type={`create`} transactionId={transactionId} className={classes.wrapperSection}/>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.auth,
    transactionId: ownProps.match.params.id,
    activeTransaction: state.transaction.activeTransaction,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchTransaction: (id) => dispatch(fetchTransaction(id)),
    fetchCategories: () => dispatch(fetchCategories()),
  }
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(sectionStyles)
)(SubTransactionCreate);