import React from 'react';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogContentText
  from '@material-ui/core/DialogContentText/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import Button from '@material-ui/core/Button/Button';
import Dialog from '@material-ui/core/Dialog/Dialog';
import {sectionStyles} from '../../styles/sectionStyles';
import {withStyles} from '@material-ui/core';
import {compose} from 'redux';
import connect from 'react-redux/es/connect/connect';
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import DateAdapterFactory from "../../utils/date/DateAdapterFactory";
import DateAdapter from "../../utils/date/DateAdapter";
import {addCurrency} from "../../utils/currencyUtils";
import {
  closeAndResetTransactionToImportModal,
  createTransaction, setAllChecked, setUncheckedList
} from "../../store/actions/transactionActions";
import {withRouter} from "react-router-dom";

class TransactionsToImportModal extends React.Component {
  handleToggle(value) {
    const currentIndex = this.props.transactionsToImport.modal.unCheckedList.indexOf(value);
    const newUnCheckedList = [...this.props.transactionsToImport.modal.unCheckedList];

    if (currentIndex === -1) {
      newUnCheckedList.push(value);
    } else {
      newUnCheckedList.splice(currentIndex, 1);
    }

    this.props.setUncheckedList({newUnCheckedList});
  }

  handleToggleSelectAll() {
    const isSelectAllChecked = !this.props.transactionsToImport.modal.selectAllChecked;
    this.props.setAllChecked({isSelectAllChecked});
  }

  handleClickAccept = (e) => {
    e.preventDefault();
    const transactions = this.props.transactionsToImport.transactions.filter((transaction, index) => !this.props.transactionsToImport.modal.unCheckedList.includes(index))
    const redirect = () => this.props.history.push('/transactions');
    this.props.createTransaction({transactions, redirect});
    this.props.closeAndResetTransactionToImportModal();
  }

  handleClickCancel = (e) => {
    e.preventDefault();
    this.props.closeAndResetTransactionToImportModal();
  }

  getSelectAllCheckbox() {
    const labelId = 'checkbox-select-all';
    return (
      <ListItem key={'selectAll'} role={undefined} dense button onClick={() => this.handleToggleSelectAll()}>
        <ListItemIcon>
          <Checkbox
            edge="start"
            checked={this.props.transactionsToImport.modal.selectAllChecked}
            tabIndex={-1}
            disableRipple
            inputProps={{ 'aria-labelledby': labelId }}
          />
        </ListItemIcon>
        <ListItemText id={labelId} primary={`Seleccionar Todo`} />
      </ListItem>
    )
  };

  render() {
    const { classes, transactionsToImport } = this.props;
    const { modal } = transactionsToImport;
    const { unCheckedList } = modal;
    const selectAllCheckbox = this.getSelectAllCheckbox();
    const transactionsToImportCounter = transactionsToImport.transactions.length - unCheckedList.length
    return (
        <Dialog open={modal.open} onClose={()=> {}} aria-labelledby="form-dialog-title">
          <DialogContent>
            <DialogContentText>
              Transacciones a importar ({transactionsToImportCounter})
            </DialogContentText>
            <List className={classes.transactionToImportList}>
              {transactionsToImport.transactions && transactionsToImport.transactions.length > 0 ? selectAllCheckbox : null}
              {transactionsToImport.transactions.map((transaction, index) => {
                  const {valueDate, concept, amount} = transaction.paramsToAdd;
                  const valueDateText = DateAdapterFactory.createInstance(DateAdapter.getDateFromString(valueDate)).toDisplay();
                  const amountText = addCurrency(amount)
                  const labelId = `checkbox-list-label-${index}`;

                  return (
                      <ListItem key={index} role={undefined} dense button onClick={() => this.handleToggle(index)}>
                          <ListItemIcon>
                              <Checkbox
                                  edge="start"
                                  checked={!unCheckedList.includes(index)}
                                  tabIndex={-1}
                                  disableRipple
                                  inputProps={{ 'aria-labelledby': labelId }}
                              />
                          </ListItemIcon>
                          <ListItemText id={labelId} primary={`${valueDateText} ${amountText}`} secondary={`${concept}`} />
                      </ListItem>
                  );
              })}
            </List>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={this.handleClickCancel}>
              Cancelar
            </Button>
            <Button color="primary" onClick={this.handleClickAccept}>
              Aceptar
            </Button>
          </DialogActions>
        </Dialog>
    );
  }
}

const mapStateToProps = (state) => {
    return {
        transactionsToImport: state.transaction.transactionsToImport
    };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createTransaction: ({transactions, redirect}) => dispatch(createTransaction({transactions, redirect})),
    closeAndResetTransactionToImportModal: () => dispatch(closeAndResetTransactionToImportModal()),
    setUncheckedList: ({newUnCheckedList}) => dispatch(setUncheckedList({newUnCheckedList})),
    setAllChecked: ({isSelectAllChecked}) => dispatch(setAllChecked({isSelectAllChecked})),
  }
};

export default withRouter(compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(sectionStyles)
)(TransactionsToImportModal));