import React from 'react';
import {withStyles} from '@material-ui/core';
import {sectionStyles} from '../../styles/sectionStyles';
import {compose} from 'redux';
import connect from 'react-redux/es/connect/connect';
import LinearProgress from '@material-ui/core/LinearProgress/LinearProgress';
import {fetchTransactionsGroupedByDate} from "../../store/actions/transactionActions";
import {addCurrency} from "../../utils/currencyUtils";

class TransactionsListGroupedByDate extends React.Component {
  componentDidMount() {
    if (!this.props.auth.accessToken) {
      return;
    }
    this.props.fetchTransactionsGroupedByDate();
  }

  render() {
    const {classes} = this.props;
    const {transactions, isFetching, error} = this.props.transactionGroupedByDateList;

    if (isFetching) {
      return (
        <LinearProgress />
      )
    }

    if (error) {
      return (
        <p>{error}</p>
      )
    }

    if (transactions.length === 0) {
      return (
        <p>No hay transacciones</p>
      )
    }

    return (
      <ul className="tree">
        {
          transactions && transactions.reverse().map((year, indexYear) => {
            return (
              <li key={`year-${indexYear}`}>
                {year.name}
                <ul className="tree">
                  {
                    year.months && year.months.reverse().map((month, indexMonth) => {
                      const colorClassName = month.balance === - 1 ? classes.green : month.balance === 1 ? classes.red : classes.white;
                      return (
                        <li key={`month-${indexMonth}`}>
                          <span>{month.name}</span> &nbsp;
                          <span className={colorClassName}>{addCurrency(month.summation[0])}</span>
                        </li>
                      )
                    })
                  }
                </ul>
              </li>
            )
          })
        }
      </ul>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    transactionGroupedByDateList: state.transaction.transactionGroupedByDateList,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    fetchTransactionsGroupedByDate: () => dispatch(fetchTransactionsGroupedByDate()),
  }
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(sectionStyles)
)(TransactionsListGroupedByDate);

