import {TOAST_CLOSED, TOAST_OPENED} from "../actions/toastActions";

const initialState = {
  open: false,
  message: null,
  severity: 'info'
};

const toastReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOAST_OPENED: {
      const {message, severity} = action.payload;
      return {
        open: true,
        message,
        severity: severity ? severity : initialState.severity
      };
    }
    case TOAST_CLOSED: {
      return {
        open: false,
        message: null,
        severity: initialState.severity
      };
    }

    default:
      return {
        ...state
      };
  }
};

export default toastReducer;