import React from 'react';
import connect from 'react-redux/es/connect/connect';
import {compose} from 'redux';
import TextField from '@material-ui/core/TextField/TextField';
import Button from '@material-ui/core/Button/Button';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {withStyles} from '@material-ui/core';
import {sectionStyles} from '../../styles/sectionStyles';
import LinearProgress from '@material-ui/core/LinearProgress/LinearProgress';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import LoopIcon from '@material-ui/icons/Loop';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox/Checkbox';
import {fetchTransactionsAuto} from "../../store/actions/transactionActions";
import {Link, withRouter} from "react-router-dom";
import DotLoader from "../layout/DotLoader";
import Divider from "@material-ui/core/Divider";
import SubTransactionList from "./SubTransactionList";
import DateAdapterFactory from "../../utils/date/DateAdapterFactory";
import {getHelperTextNumericValue} from "../../utils/validationUtils";
import Avatar from "@material-ui/core/Avatar/Avatar";
import Typography from "@material-ui/core/Typography";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import FormControl from "@material-ui/core/FormControl";

class TransactionDetails extends React.Component {

  handleClickScraping = (e) => {
    e.preventDefault();
    const {fileType, account, accountTypeParams, otp} = this.props;
    const params = [...accountTypeParams.map(elem => elem.value), fileType, account];
    const isValidParams = params.every(param => param !== '');
    if (!isValidParams) {
        return;
    }
    if (this.props.transactionsToImport.isOtpNeeded && otp === '') {
        return;
    }
    const accountTypeParamsToSend = accountTypeParams.map(elem => {
      return {
        name: elem.name,
        value: elem.type === 'date' ? DateAdapterFactory.createInstance(elem.value).getStartDayMoment().toISOString() : elem.value,
      }
    });
    const ignoredToBalance = this.props.ignoredToBalance;
    const pendingReturn = this.props.pendingReturn;
    this.props.fetchTransactionsAuto({
        fileType,
        account,
        ignoredToBalance,
        pendingReturn,
        accountTypeParams: accountTypeParamsToSend,
        ...(this.props.transactionsToImport.isOtpNeeded && otp && {otp})
    });
  }

  render() {
    const {transactionId, subTransactions, subTransactionsTotalAmount, type, valueDate, concept, alias, amount, ignoredToBalance, pendingReturn, category, account, comments, handleOnChangeSelect, handleOnChangeFile, handleOnClickProcess, handleOnChangeInput, handleChangeDate, /*handleOnSetInputValue,*/ categoryList, accountList, groupList, classes, file, handleOnChangeCheckbox, accountTypeParams, transactionsToImport, accountTypesList, handleOnChangeSelectAccountType, handleOnChangeInputAccountTypeParam, handleChangeMultiple, error, otp} = this.props;
    const {categories, isFetching: isFetchingCategories, error: errorCategories} = categoryList;
    const {accounts, isFetching: isFetchingAccounts, error: errorAccounts} = accountList;
    const {groups, isFetching: isFetchingGroups, error: errorGroups} = groupList;
    const {isFetching: isFetchingTransactionsToImport, isOtpNeeded} = transactionsToImport;
    const {accounts: accountTypes, isFetching: isFetchingAccountTypes, error: errorAccountTypes} = accountTypesList;
    if (isFetchingCategories || isFetchingAccounts || isFetchingAccountTypes || isFetchingGroups) {
      return (
          <LinearProgress />
      )
    }

    if (errorCategories) {
      return (
          <LinearProgress />
      )
    }

    if (errorAccounts) {
      return (
          <LinearProgress />
      )
    }

    if (errorAccountTypes) {
      return (
          <LinearProgress />
      )
    }

    if (errorGroups) {
      return (
        <LinearProgress />
      )
    }

    const mapCategoriesToSelect = categories.map(elem => {
      return {
        title: elem.name,
        value: elem.id
      }
    });

    const categoryValue = mapCategoriesToSelect.find(elem => elem.value === category) || '';

    const mapAccountsToSelect = accounts.map(elem => {
      return {
        title: elem.name,
        value: elem.id,
        file: elem.file,
      }
    });

    const accountValue = mapAccountsToSelect.find(elem => elem.value === account) || '';

    const options = accountTypes.map(elem => {
      return {
        title: elem.title,
        value: elem.value,
      };
    });

    const groupsValue = this.props.groupList.groups.map(elem => elem.id).filter(elem => this.props.groups.includes(elem));

    if (type === 'upload') {
      return (
          <form className={classes.form} noValidate autoComplete="off">
            <Autocomplete
                id="fileType"
                options={options}
                getOptionLabel={option => (option.title ? option.title : "")}
                className={classes.autocomplete}
                renderInput={params => (
                    <TextField {...params} label="Tipo de Transacción" fullWidth />
                )}
                onChange={(event, value) => handleOnChangeSelectAccountType(value)}
            />
            <Autocomplete
                id="account"
                options={mapAccountsToSelect}
                getOptionLabel={option => (option.title ? option.title : "")}
                className={classes.autocomplete}
                renderInput={params => (
                    <TextField {...params} label="Cuentas" fullWidth />
                )}
                renderOption={option => {
                  return (
                    <>
                      <Avatar src={option.file} className={classes.accountAvatar}/>
                      <Typography variant="inherit" noWrap>
                        {option.title ? option.title : ''}
                      </Typography>
                    </>
                  );
                }}
                onChange={(event, value) => handleOnChangeSelect(`account`, value)}
                value={accountValue}
            />
            {
              accountTypeParams && accountTypeParams
                .filter(accountTypeParam => accountTypeParam.globalRequired)
                .map((accountTypeParam, index) => {
                  if (['accountType', 'otpType'].includes(accountTypeParam.type)) {
                    return <Autocomplete
                      key={index}
                      id={accountTypeParam.name}
                      options={accountTypeParam.options}
                      getOptionLabel={option => (option.title ? option.title : "")}
                      className={classes.autocomplete}
                      renderInput={params => (
                        <TextField {...params} label={accountTypeParam.label} fullWidth />
                      )}
                      onChange={(event, elem) => handleOnChangeInputAccountTypeParam(accountTypeParam.name, elem ? elem.value : '')}
                    />
                  }
                  return null;
              })
            }
            <div style={{marginTop: '20px'}}>
              {file ? file.name : <span>Pulse "<CloudUploadIcon style={{margin: '0 3px', verticalAlign: 'bottom'}} /> SUBIR FICHERO" para adjuntar el fichero. Pulse "<LoopIcon style={{verticalAlign: 'bottom'}}/> PROCESAR FICHERO" para procesarlo.</span>}
            </div>
            <input
              accept=".xlsx, .xls, .csv"
              id="contained-button-file"
              multiple
              type="file"
              onChange={handleOnChangeFile}
            />
            <label htmlFor="contained-button-file">
              <Button style={{width: '100%'}} variant="contained" color="primary" component="span" startIcon={<CloudUploadIcon />}>
                  {file ? 'Subir Otro Fichero' : 'Subir Fichero'}
              </Button>
            </label>
            {
                file ? <Button variant="contained" color="primary" component="span" startIcon={<LoopIcon />}  onClick={handleOnClickProcess}>
                    Procesar Fichero
                </Button> : null
            }
            <div style={{marginTop: '30px'}}>
              <span>Pulse "PROCESO AUTOMÁTICO" para conectarnos con tu banco y procesar las transacciones de forma automática. Necesitaremos las credenciales para el acceso. Estos datos no son almacenamos.</span>
            </div>

            {
              accountTypeParams && accountTypeParams
                .filter(accountTypeParam => !accountTypeParam.globalRequired)
                .map((accountTypeParam, index) => {
                  if (accountTypeParam.type === 'password') {
                    return <TextField key={index} id={accountTypeParam.name} type="password" autoComplete="new-password" label={accountTypeParam.label} value={accountTypeParam.value || ''}  onChange={(e) => handleOnChangeInputAccountTypeParam(accountTypeParam.name, e.target.value)}/>
                  }
                  if (accountTypeParam.type === 'date') {
                    return <MuiPickersUtilsProvider key={index} utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        margin="normal"
                        id={accountTypeParam.name}
                        label={accountTypeParam.label}
                        format="dd/MM/yyyy"
                        value={accountTypeParam.value}
                        onChange={(date) => handleOnChangeInputAccountTypeParam(accountTypeParam.name, date)}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  }
                  return <TextField key={index} id={accountTypeParam.name} label={accountTypeParam.label} value={accountTypeParam.value || ''} onChange={(e) => handleOnChangeInputAccountTypeParam(accountTypeParam.name, e.target.value)}/>
              })
            }

              {
                  isOtpNeeded ? <TextField id="otp" label="Otp" value={otp} onChange={handleOnChangeInput}/> : null
              }

            <Button variant="contained" onClick={this.handleClickScraping} color="primary" component="span">
              {isFetchingTransactionsToImport ? <>PROCESANDO <DotLoader /></> : 'PROCESO AUTOMÁTICO'}
            </Button>
          </form>
      )
    }

    if (type === 'create') {
      return (
          <form className={classes.form} noValidate autoComplete="off">
            <TextField id="concept" label="Concepto" value={concept} onChange={handleOnChangeInput}/>
            <TextField id="alias" label="Alias" value={alias} onChange={handleOnChangeInput}/>
            <TextField id="amount" label="Cantidad" value={amount} onChange={handleOnChangeInput} error={error && error.amount} helperText={getHelperTextNumericValue(error && error.amount)}/>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                  margin="normal"
                  id="valueDate"
                  label="Date picker dialog"
                  format="dd/MM/yyyy"
                  value={valueDate}
                  onChange={handleChangeDate}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
              />
            </MuiPickersUtilsProvider>
            <Autocomplete
                id="category"
                options={mapCategoriesToSelect}
                getOptionLabel={option => (option.title ? option.title : "")}
                className={classes.autocomplete}
                renderInput={params => (
                    <TextField {...params} label="Categorías" fullWidth />
                )}
                onChange={(event, value) => handleOnChangeSelect(`category`, value)}
                value={categoryValue}
            />

            <Autocomplete
                id="account"
                options={mapAccountsToSelect}
                getOptionLabel={option => (option.title ? option.title : "")}
                className={classes.autocomplete}
                renderInput={params => (
                    <TextField {...params} label="Cuentas" fullWidth />
                )}
                renderOption={option => {
                  return (
                    <>
                      <Avatar src={option.file} className={classes.accountAvatar}/>
                      <Typography variant="inherit" noWrap>
                        {option.title ? option.title : ''}
                      </Typography>
                    </>
                  );
                }}
                onChange={(event, value) => handleOnChangeSelect(`account`, value)}
                value={accountValue}
            />
            <FormControl style={{width: '100%'}}>
              <InputLabel id="accounts-label">Grupos</InputLabel>
              <Select
                labelId="groups-label"
                id="groups"
                multiple
                value={groupsValue}
                onChange={handleChangeMultiple}
                input={<Input />}
                renderValue={(selected) => selected.join(', ')}
                MenuProps={
                  {
                    MenuListProps: { disablePadding: true },
                    getContentAnchorEl: () => null // avoid menu movement onSelect element
                  }
                }
              >
                {
                  groups.map((elem, index) => (
                    <MenuItem key={index} value={elem.id} className={classes.summaryGroupMenu}>
                      <Checkbox checked={this.props.groups.includes(elem.id)} />
                      <ListItemAvatar className={classes.summaryGroupListItemAvatar}>
                        <Avatar src={elem.file} className={classes.summaryGroupAvatar}/>
                      </ListItemAvatar>
                      <Typography variant="inherit" noWrap>
                        {elem.name}
                      </Typography>
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
            <FormControlLabel
                control={
                  <Checkbox checked={ignoredToBalance} onChange={(e) => handleOnChangeCheckbox('ignoredToBalance', e.target.checked)} value="checkedA" />
                }
                label="Ignorar para balance"
            />
            <FormControlLabel
              control={
                  <Checkbox checked={pendingReturn} onChange={(e) => handleOnChangeCheckbox('pendingReturn', e.target.checked)} value="checkedA" />
              }
              label="Pendiente de devolución"
            />
          </form>
      )
    }

    if (type === 'edit') {
      return (
          <form className={classes.form} noValidate autoComplete="off">
            <TextField id="concept" label="Concepto" value={concept} onChange={handleOnChangeInput}/>
            <TextField id="alias" label="Alias" value={alias} onChange={handleOnChangeInput}/>
            <TextField id="comments" label="Observaciones" value={comments} readOnly={true} disabled={true}/>
            <TextField id="amount" label="Cantidad" value={amount} onChange={handleOnChangeInput} error={error && error.amount} helperText={getHelperTextNumericValue(error && error.amount)}/>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                  margin="normal"
                  id="valueDate"
                  label="Date picker dialog"
                  format="dd/MM/yyyy"
                  value={valueDate}
                  onChange={handleChangeDate}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
              />
            </MuiPickersUtilsProvider>
            <Autocomplete
                id="category"
                options={mapCategoriesToSelect}
                getOptionLabel={option => (option.title ? option.title : "")}
                className={classes.autocomplete}
                renderInput={params => (
                    <TextField {...params} label="Categorías" fullWidth />
                )}
                onChange={(event, value) => handleOnChangeSelect(`category`, value)}
                value={categoryValue}
            />
            <Autocomplete
                id="account"
                options={mapAccountsToSelect}
                getOptionLabel={option => (option.title ? option.title : "")}
                className={classes.autocomplete}
                renderInput={params => (
                    <TextField {...params} label="Cuentas" fullWidth />
                )}
                renderOption={option => {
                  return (
                    <>
                      <Avatar src={option.file} className={classes.accountAvatar}/>
                      <Typography variant="inherit" noWrap>
                        {option.title ? option.title : ''}
                      </Typography>
                    </>
                  );
                }}
                onChange={(event, value) => handleOnChangeSelect(`account`, value)}
                value={accountValue}
            />
            <FormControl style={{width: '100%'}}>
              <InputLabel id="accounts-label">Grupos</InputLabel>
              <Select
                labelId="groups-label"
                id="groups"
                multiple
                value={groupsValue}
                onChange={handleChangeMultiple}
                input={<Input />}
                renderValue={(selected) => selected.join(', ')}
                MenuProps={
                  {
                    MenuListProps: { disablePadding: true },
                    getContentAnchorEl: () => null // avoid menu movement onSelect element
                  }
                }
              >
                {
                  groups.map((elem, index) => (
                    <MenuItem key={index} value={elem.id} className={classes.summaryGroupMenu}>
                      <Checkbox checked={this.props.groups.includes(elem.id)} />
                      <ListItemAvatar className={classes.summaryGroupListItemAvatar}>
                        <Avatar src={elem.file} className={classes.summaryGroupAvatar}/>
                      </ListItemAvatar>
                      <Typography variant="inherit" noWrap>
                        {elem.name}
                      </Typography>
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
            <FormControlLabel
                control={
                  <Checkbox checked={ignoredToBalance} onChange={(e) => handleOnChangeCheckbox('ignoredToBalance', e.target.checked)} value="ignoredToBalance" />
                }
                label="Ignorar para balance"
            />
            <FormControlLabel
              control={
                  <Checkbox checked={pendingReturn} onChange={(e) => handleOnChangeCheckbox('pendingReturn', e.target.checked)} value="pendingReturn" />
              }
              label="Pendiente de devolución"
            />
            <Divider />
            {
              subTransactions && subTransactions.length > 0 ?
                <SubTransactionList subTransactions={subTransactions} subTransactionsTotalAmount={subTransactionsTotalAmount} transactionId={transactionId} transactionAmount={amount}/> : null
            }
            <Button style={{marginTop: '20px'}} variant="contained" color="primary" component={Link} to={`/transactions/${transactionId}/subtransactions/create`}>
              CREAR DIVISIÓN DE LA TRANSACCIÓN
            </Button>
          </form>
      )
    }
  }
}


const mapStateToProps = (state) => {
  return {
    categoryList: state.category.categoryList,
    accountList: state.account.accountList,
    transactionsToImport: state.transaction.transactionsToImport,
    accountTypesList: state.account.accountTypesList,
    groupList: state.group.groupList,
  };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchTransactionsAuto: ({fileType, account, ignoredToBalance, pendingReturn, accountTypeParams, otp}) => dispatch(fetchTransactionsAuto({fileType, account, ignoredToBalance, pendingReturn, accountTypeParams, otp})),
    }
};

export default withRouter(compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(sectionStyles)
)(TransactionDetails));