import React from 'react';
import TransactionForm from './TransactionForm';
import connect from 'react-redux/es/connect/connect';
import {Redirect} from 'react-router-dom';
import {fetchAccounts, fetchAccountTypes} from '../../store/actions/accountActions';

class TransactionUpload extends React.Component {
  componentDidMount() {
    if (!this.props.auth.accessToken) {
      return;
    }
    this.props.fetchAccounts();
    this.props.fetchAccountTypes();
  }
  render() {
    const {auth} = this.props;

    if (!auth.accessToken) {
      return <Redirect to='/signin' />
    }

    return (
        <TransactionForm type={`upload`}/>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchAccounts: () => dispatch(fetchAccounts()),
    fetchAccountTypes: () => dispatch(fetchAccountTypes()),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(TransactionUpload);