import React from 'react';
import TransactionForm from './TransactionForm';
import {withStyles} from '@material-ui/core';
import {sectionStyles} from '../../styles/sectionStyles';
import {compose} from 'redux';
import connect from 'react-redux/es/connect/connect';
import {Redirect} from 'react-router-dom';
import {fetchCategories} from '../../store/actions/categoryActions';
import {fetchAccounts, fetchAccountTypes} from '../../store/actions/accountActions';
import {fetchGroups} from "../../store/actions/groupActions";

class TransactionCreate extends React.Component {
  componentDidMount() {
    if (!this.props.auth.accessToken) {
      return;
    }
    this.props.fetchCategories();
    this.props.fetchAccounts();
    this.props.fetchAccountTypes();
    this.props.fetchGroups();
  }

  render() {
    const {classes, auth} = this.props;

    if (!auth.accessToken) {
      return <Redirect to='/signin' />
    }

    return (
        <TransactionForm type={`create`} className={classes.wrapperSection}/>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchCategories: () => dispatch(fetchCategories()),
    fetchAccounts: () => dispatch(fetchAccounts()),
    fetchAccountTypes: () => dispatch(fetchAccountTypes()),
    fetchGroups: () => dispatch(fetchGroups()),
  }
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(sectionStyles)
)(TransactionCreate);