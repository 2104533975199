import React from 'react';
import Grid from '@material-ui/core/Grid/Grid';
import LinearProgress from '@material-ui/core/LinearProgress/LinearProgress';
import {connect} from "react-redux";
import GroupSummary from "./GroupSummary";


class GroupList extends React.Component {
  render() {
    const {groups, isFetching, error} = this.props.groupList;
    if (isFetching) {
      return (
          <LinearProgress />
      )
    }

    if (error) {
      return (
          <p>{error}</p>
      )
    }

    if (groups.length === 0) {
      return (
          <p>No hay grupos creados</p>
      )
    }

    return (
        <Grid container>
          {
            groups && groups.map(group => {
              return(
                  <GroupSummary key={group.id} group={group} />
              )
            })
          }
        </Grid>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    groupList: state.group.groupList
  };
};

export default connect(mapStateToProps)(GroupList);