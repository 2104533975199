import React from 'react'
import {Link} from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar/AppBar';
import Toolbar from '@material-ui/core/Toolbar/Toolbar';
import Button from '@material-ui/core/Button/Button';
import GroupIcon from '@material-ui/icons/Group';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import CategoryIcon from '@material-ui/icons/Category';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import AssessmentIcon from '@material-ui/icons/Assessment';
import withStyles from '@material-ui/core/styles/withStyles';
import {compose} from 'redux';
import {connect} from "react-redux";

const styles = theme => {
  return {
    appBar: {
      zIndex: theme.zIndex.drawer + 2,
      top: 'auto',
      bottom: 0,
      position: 'absolute'
    },
    toolbar: {
      justifyContent: 'space-between'
    },
    button: {
      height: theme.mixins.toolbar.minHeight,
      fontSize: '11px',
      padding: '6px 10px'
    },
    label: {
      flexDirection: 'column'
    },
    icon: {
      fontSize: '26px !important',
    }
  }
};

const Navbar = (props) => {
  const {classes, auth} = props;
  if (!auth.accessToken) {
    return null;
  }
  return (
      <AppBar color={`default`} className={classes.appBar}>
        <Toolbar className={classes.toolbar} disableGutters={true}>
          <Button classes={{ root: classes.button, label: classes.label }} component={Link} to={"/groups"}>
            <GroupIcon className={classes.icon} />
            Grupos
          </Button>
          <Button classes={{ root: classes.button, label: classes.label }} component={Link} to={"/accounts"}>
            <AccountBalanceWalletIcon className={classes.icon} />
            Cuentas
          </Button>
          <Button classes={{ root: classes.button, label: classes.label }} component={Link} to={"/categories"}>
            <CategoryIcon className={classes.icon} />
            Categorias
          </Button>
          <Button classes={{ root: classes.button, label: classes.label }} component={Link} to={"/transactions"}>
            <SwapHorizIcon className={classes.icon} />
            Transacciones
          </Button>
          <Button classes={{ root: classes.button, label: classes.label }} component={Link} to={"/summary"}>
            <AssessmentIcon className={classes.icon} />
            Resumen
          </Button>
        </Toolbar>
      </AppBar>
  )
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};


export default compose(
    connect(mapStateToProps),
    withStyles(styles)
)(Navbar);

