import Api from '../../services/Api';

export const REQUEST_REGISTER = 'REQUEST_REGISTER';
export const REQUEST_REGISTER_SUCCESS = 'REQUEST_REGISTER_SUCCESS';
export const REQUEST_REGISTER_FAILURE = 'REQUEST_REGISTER_FAILURE';

export const REQUEST_SIGNIN = 'REQUEST_SIGNIN';
export const REQUEST_SIGNIN_SUCCESS = 'REQUEST_SIGNIN_SUCCESS';
export const REQUEST_SIGNIN_FAILURE = 'REQUEST_SIGNIN_FAILURE';

export const REQUEST_SIGNOUT = 'REQUEST_SIGNOUT';
export const REQUEST_SIGNOUT_SUCCESS = 'REQUEST_SIGNOUT_SUCCESS';
export const REQUEST_SIGNOUT_FAILURE = 'REQUEST_SIGNOUT_FAILURE';

export const UPDATE_ACCESS_TOKEN = 'UPDATE_ACCESS_TOKEN';


export function signIn({credentials, redirect}) {
  return (dispatch) => {
    const {email, password} = credentials;
    const request = Api.getInstance().post('/auth/signin', {
      email,
      password
    });

    dispatch({
      type: REQUEST_SIGNIN
    });

    request.then(response => {
      !response.error ? dispatch(signInSuccess(response.data)) : dispatch(signInFailure(response.data));
      if (redirect) {
        return redirect();
      }
    })
  }
}

export function updateAccessToken({accessToken}) {
  return {
    type: UPDATE_ACCESS_TOKEN,
    payload: accessToken
  };
}

export function signInSuccess(posts) {
  return {
    type: REQUEST_SIGNIN_SUCCESS,
    payload: posts
  };
}

export function signInFailure(error) {
  return {
    type: REQUEST_SIGNIN_FAILURE,
    payload: error
  };
}

export function signOut({redirect}) {
  return (dispatch) => {
    //const accessToken = getState().auth.accessToken;
    const request = Api.getInstance().delete('/auth/signout');
    dispatch({
      type: REQUEST_SIGNOUT
    });

    request.then(response => {
      // if (response.accessToken) {
      //   dispatch(updateAccessToken({accessToken: response.accessToken}))
      // }
      !response.error ? dispatch(signOutSuccess(response.data)) : dispatch(signOutFailure(response.data));
      if (redirect) {
        return redirect();
      }
    })
  }
}

export function signOutSuccess(posts) {
  return {
    type: REQUEST_SIGNOUT_SUCCESS,
    payload: posts
  };
}

export function signOutFailure(error) {
  return {
    type: REQUEST_SIGNOUT_FAILURE,
    payload: error
  };
}

export function register({credentials, redirect}) {
  return (dispatch) => {
    const {email, password, captcha} = credentials;
    const request = Api.getInstance().post('/auth/register', {
      email,
      password,
      captcha
    });

    dispatch({
      type: REQUEST_REGISTER
    });

    request.then(response => {
      !response.error ? dispatch(registerSuccess(response.data)) : dispatch(registerFailure(response.data));
      if (redirect) {
        return redirect();
      }
    })
  }
}

export function registerSuccess(posts) {
  return {
    type: REQUEST_REGISTER_SUCCESS,
    payload: posts
  };
}

export function registerFailure(error) {
  return {
    type: REQUEST_REGISTER_FAILURE,
    payload: error
  };
}