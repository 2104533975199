import React from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import LinearProgress from '@material-ui/core/LinearProgress/LinearProgress';
import {withStyles} from '@material-ui/core';
import {sectionStyles} from '../../styles/sectionStyles';
import {Redirect} from 'react-router-dom';
import {fetchTransaction} from '../../store/actions/transactionActions';
import {fetchCategories,} from '../../store/actions/categoryActions';
import SubTransactionForm from "./SubTransactionForm";

class SubTransactionEdit extends React.Component {
  componentDidMount() {
    if (!this.props.auth.accessToken) {
      return;
    }
    this.props.fetchTransaction(this.props.transactionId);
    this.props.fetchCategories();
  }

  render() {
    const {classes, auth, transactionId, subTransactionId} = this.props;

    if (!auth.accessToken) {
      return <Redirect to='/signin' />
    }

    const {transaction, isFetching, error} = this.props.activeTransaction;

    if (!transaction) {
      return null;
    }

    if (isFetching) {
      return (
          <LinearProgress />
      )
    }

    if (error) {
      return (
          <LinearProgress />
      )
    }

    const subTransaction = transaction.subTransactions.find(elem => elem.id === subTransactionId);
    return (
        <SubTransactionForm subTransaction={subTransaction} transactionId={transactionId} type={`edit`} className={classes.wrapperSection}/>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    transactionId: ownProps.match.params.id,
    subTransactionId: ownProps.match.params.subId,
    auth: state.auth,
    activeTransaction: state.transaction.activeTransaction,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchTransaction: (id) => dispatch(fetchTransaction(id)),
    fetchCategories: () => dispatch(fetchCategories())
  }
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(sectionStyles)
)(SubTransactionEdit);