import {
  CREATE_GROUP,
  CREATE_GROUP_FAILURE,
  CREATE_GROUP_SUCCESS,
  DELETE_GROUP,
  DELETE_GROUP_FAILURE,
  DELETE_GROUP_SUCCESS,
  FETCH_GROUP,
  FETCH_GROUP_FAILURE,
  FETCH_GROUP_SUCCESS,
  FETCH_GROUPS,
  FETCH_GROUPS_FAILURE,
  FETCH_GROUPS_SUCCESS, FETCH_GROUPS_TYPES, FETCH_GROUPS_TYPES_FAILURE, FETCH_GROUPS_TYPES_SUCCESS,
  RESET_GROUPS,
  RESET_ACTIVE_GROUP,
  RESET_DELETED_GROUP,
  RESET_NEW_GROUP,
  UPDATE_GROUP,
  UPDATE_GROUP_FAILURE,
  UPDATE_GROUP_SUCCESS,
} from '../actions/groupActions';

const initialState = {
  totalBalance: {
    value: 0,
    error: null,
    isFetching: false
  },
  groupList: {
    groups: [],
    error: null,
    isFetching: false
  },
  groupTypesList: {
    groups: [],
    error: null,
    isFetching: false
  },
  newGroup:{group:null, error: null, isFetching: false},
  activeGroup:{group:null, error:null, isFetching: false},
  deletedGroup: {group: null, error:null, isFetching: false},
};

const groupReducer = (state = initialState, action) => {
  let error;
  switch (action.type) {
    case FETCH_GROUPS:
      return {
        ...state,
        totalBalance: {value: 0, error: null, isFetching: true},
        groupList: {groups:[], error: null, isFetching: true}
      };
    case FETCH_GROUPS_SUCCESS:
      return {
        ...state,
        totalBalance: {value: action.payload.totalBalance, error: null, isFetching: false},
        groupList: {groups: action.payload.groups, error:null, isFetching: false}
      };
    case FETCH_GROUPS_FAILURE:
      error = action.payload;
      return {
        ...state,
        totalBalance: {value: 0, error: error, isFetching: false},
        groupList: {groups: [], error: error, isFetching: false}
      };
    case RESET_GROUPS:
      return initialState;
    case FETCH_GROUP:
      return { ...state, activeGroup: {group: null, error: null, isFetching: true} };
    case FETCH_GROUP_SUCCESS:
      return { ...state, activeGroup: {group: action.payload, error:null, isFetching: false} };
    case FETCH_GROUP_FAILURE:
      error = action.payload;
      return { ...state, activeGroup: {group: null, error: error, isFetching: false} };
    case RESET_ACTIVE_GROUP: {
      return initialState;
    }

    case UPDATE_GROUP: {
      const {groups, error} = state.groupList;
      return { ...state, groupList: {groups, error, isFetching: true} };
    }

    case UPDATE_GROUP_SUCCESS: {
      const {groups} = state.groupList;
      const updatedGroups = groups.map(group => {
        return group.id === action.payload.id ? action.payload : group;
      });
      return { ...state, groupList: {groups: updatedGroups, error: null, isFetching: false} };
    }

    case UPDATE_GROUP_FAILURE: {
      const {groups} = state.groupList;
      return { ...state, groupList: {groups, error: action.payload, isFetching: false} };
    }

    case CREATE_GROUP: {
      const {groups, error} = state.groupList;
      return { ...state, groupList: {groups, error, isFetching: true} };
    }

    case CREATE_GROUP_SUCCESS: {
      const groups = [...state.groupList.groups, action.payload];
      return { ...state, groupList: {groups, error: null, isFetching: false} };
    }

    case CREATE_GROUP_FAILURE: {
      const {groups} = state.groupList;
      return { ...state, groupList: {groups, error: action.payload, isFetching: false} };
    }

    case RESET_NEW_GROUP: {
      return initialState;
    }

    case DELETE_GROUP: {
      const {groups, error} = state.groupList;
      return { ...state, groupList: {groups, error, isFetching: true} };
    }

    case DELETE_GROUP_SUCCESS: {
      const groups = state.groupList.groups.filter(group => group.id !== action.payload.id);
      return { ...state, groupList: {groups, error: null, isFetching: false} };
    }

    case DELETE_GROUP_FAILURE: {
      const {groups} = state.groupList;
      return { ...state, groupList: {groups, error: action.payload, isFetching: false} };
    }

    case RESET_DELETED_GROUP: {
      return initialState;
    }

    case FETCH_GROUPS_TYPES:
      return {
        ...state,
        groupTypesList: {
          groups: [],
          error: null,
          isFetching: true
        },
      };
    case FETCH_GROUPS_TYPES_SUCCESS:
      return {
        ...state,
        groupTypesList: {
          groups: action.payload,
          error: null,
          isFetching: false
        },
      };
    case FETCH_GROUPS_TYPES_FAILURE:
      error = action.payload;
      return {
        ...state,
        groupTypesList: {
          groups: [],
          error: action.payload,
          isFetching: false
        },
      };

    default:
      return {
        ...state
      };
  }
};

export default groupReducer;