import React from 'react';
import {Link} from 'react-router-dom';
import Grid from '@material-ui/core/Grid/Grid';
import {withStyles} from '@material-ui/core';
import {sectionStyles} from '../../styles/sectionStyles';
import {addCurrency} from '../../utils/currencyUtils';
import Button from '@material-ui/core/Button/Button';


const SubTransactionSummary = ({subTransaction, transactionId, classes}) => {
  return (
      <Grid item xs={12}>
        <Button component={Link} underline={`none`}
                className={subTransaction.ignoredToBalance ? classes.listItemsLinksDisabled : (subTransaction.pendingReturn ? classes.listItemsLinksHightlight : classes.listItemsLinks)}
                to={`/transactions/${transactionId}/subtransactions/${subTransaction.id}`}
        >
          <Grid className={classes.listItemsLinksWrapperColumns}>
            <div>
              {subTransaction.category && subTransaction.category.name}
              <br/>
              {subTransaction.alias && subTransaction.alias !== '' ? subTransaction.alias : subTransaction.concept}
            </div>
            <div className={classes.listItemsLinksWrapperColumnPrice}>
              {addCurrency(subTransaction.amount[0])}
            </div>
          </Grid>
        </Button>
      </Grid>
  )
};

export default withStyles(sectionStyles)(SubTransactionSummary);