import axios from 'axios';
import config from '../config';
import {updateAccessToken} from '../store/actions/authActions';

class Api {
  static _instance = null;

   constructor({store}, callback) {
    this.store = store;
    this._setInstance();
    const promiseRefreshToken = this._getPromiseRefreshToken();
    promiseRefreshToken
        .then(response => {
          const {accessToken} = response;
          this.store.dispatch(updateAccessToken({accessToken}));
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this._setInterceptors();
          callback()
        });
    return this._adapter;
   }

   _setInstance() {
     this._adapter =  axios.create({
       baseURL: config.apiEndPointUrl,
       withCredentials: true,
     });
   }

   _setInterceptors() {
     this._adapter.interceptors.request.use(config => {
       const {accessToken} = this.store && this.store.getState().auth;
       const headers = accessToken ? {
         Authorization: `Bearer ${accessToken}`
       } : null;
       if (headers) {
         config.headers = headers;
       }
       return config;
     }, error => {
       return Promise.reject(error);
     });
     this._adapter.interceptors.response.use(response => {
       // Return a successful response back to the calling service
       return response;
     }, error => {
       // Return any error which is not due to authentication back to the calling service
       if (error.response.status !== 401) {
         return new Promise((resolve, reject) => {
           reject(error);
         });
       }

       // Return accessToken null when fail with 401 /auth/refreshToken to redirect login page
       if (error.config.url.includes('/auth/refreshToken')) {
         return new Promise((resolve, reject) => {
           this.store.dispatch(updateAccessToken({accessToken: null}));
           reject(error);
         });
       }

       // Any 401 except /auth/refreshToken will try to refreshToken
       return new Promise((resolve, reject) => {
         const promiseRefreshToken = this._getPromiseRefreshToken();
         promiseRefreshToken
           .then(({accessToken}) => {
             const {config} = error;
             config.headers['Authorization'] = `Bearer ${accessToken}`;
             const repeatRequest = this._getPromiseRepeatFailedRequest({config});
             repeatRequest
               .then(response => {
                 this.store.dispatch(updateAccessToken({accessToken}));
                 resolve(response);
               })
               .catch(error => {
                 this.store.dispatch(updateAccessToken({accessToken: null}));
                 reject(error);
               })
           })
           .catch(error => {
             this.store.dispatch(updateAccessToken({accessToken: null}));
             reject(error);
           })
       });
     });
   }

   _getPromiseRepeatFailedRequest({config}) {
     return new Promise((resolve3, reject3) => {
         axios.request(config)
         .then(response => {
           resolve3(response);
         })
         .catch((error) => {
           reject3(error);
         });
     });
   }

    _getPromiseRefreshToken() {
      return new Promise((resolve, reject) => {
          axios.post(`${config.apiEndPointUrl}/auth/refreshToken`,
              {},
              {withCredentials: true})
          .then(response => {
            resolve({...response.data});
          })
          .catch(error => {
            reject(error);
          });
        });
    }

   static create({store} = {store: null}, callback = {}) {
     Api._instance = new Api({store}, callback);
   }

   static getInstance() {
     return Api._instance;
   }
}

export default Api;