import React from 'react';
import CategoryForm from './CategoryForm';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import IconButton from '@material-ui/core/IconButton/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import OpenWithIcon from '@material-ui/icons/OpenWith';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import LabelIcon from "@material-ui/icons/Label";
import {sectionStyles} from "../../styles/sectionStyles";
import {withStyles} from "@material-ui/core";

class Category extends React.Component {

  state = {
    isAdding: false,
    isEditing: false,
  };

  handleOnClickAdd = (e) => {
    e.preventDefault();
    this.setState({
      isAdding: true
    })
  };

  handleOnClickEdit = (e) => {
    e.preventDefault();
    this.setState({
      isEditing: true
    })
  };

  handleOnClickCancel = (e) => {
    e.preventDefault();
    this.setDefaultState();
  };

  handleSuccess() {
    this.setDefaultState();
  };

  handleOpenModalLabels = (e) => {
    e.preventDefault();
    const {handleOpenModalLabels} = this.props;
    const {id, labels} = this.props.node;
    handleOpenModalLabels({categoryId: id, labels});
  }

  setDefaultState() {
    this.setState({
      isAdding: false,
      isEditing: false,
    });
  };

  getIconDropDownButton() {
    const {collapsed} = this.props;
    return collapsed ? <ArrowRightIcon /> : <ArrowDropDownIcon />;
  }

  getButtonDropDown() {
    const {isDropDownable, handleOnClickDropDown, classes} = this.props;
    if (!isDropDownable) {
      return null;
    }
    const icon = this.getIconDropDownButton();
    return <IconButton className={classes.iconButtonSmall} size="small" aria-label="dropdown-switch" onClick={handleOnClickDropDown}>{icon}</IconButton>;
  }

  getCategoryAction() {
    const {node, handleOnClickMove, handleOnClickSetParentId, handleOnClickCancelSetParentId, isMoving, elementIdEditingParent, classes} = this.props;

    if (this.state.isAdding) {
      return <CategoryForm parentId={node.id} handleOnClickCancel={this.handleOnClickCancel} handleSuccess={() => this.handleSuccess()} isInputFullWidth={true}/>;
    }

    if (this.state.isEditing) {
      return <CategoryForm node={node} handleOnClickCancel={this.handleOnClickCancel} handleSuccess={() => this.handleSuccess()} isInputFullWidth={true}/>;
    }

    if (isMoving) {
      if (elementIdEditingParent === node.id) {
        return <IconButton className={classes.iconButtonSmall} size="small" aria-label="cancel" onClick={handleOnClickCancelSetParentId}><CloseIcon /></IconButton>;
      }
      return <IconButton className={classes.iconButtonSmall} size="small" aria-label="accept" onClick={handleOnClickSetParentId}><CheckIcon /></IconButton>
    }

    return (
        <>
          <IconButton className={classes.iconButtonSmall} size="small" aria-label="labels" onClick={this.handleOpenModalLabels}><LabelIcon /></IconButton>
          <IconButton className={classes.iconButtonSmall} size="small" aria-label="move" onClick={handleOnClickMove}><OpenWithIcon /></IconButton>
          <IconButton className={classes.iconButtonSmall} size="small" aria-label="edit" onClick={this.handleOnClickEdit}><EditIcon /></IconButton>
          <IconButton className={classes.iconButtonSmall} size="small" aria-label="add" onClick={this.handleOnClickAdd}><AddIcon /></IconButton>
        </>
    );
  }

  render() {
    const {node, isDropDownable} = this.props;
    const categoryAction = this.getCategoryAction();
    const drownDownButton = this.getButtonDropDown();
    const styleExtraSpace = !isDropDownable ? {paddingLeft: '22px'} : null;
    return (
        <span style={styleExtraSpace}>{drownDownButton}{node.name}{categoryAction}</span>
    )
  }
}

export default withStyles(sectionStyles)(Category);