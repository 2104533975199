import {
  REQUEST_REGISTER, REQUEST_REGISTER_FAILURE, REQUEST_REGISTER_SUCCESS,
  REQUEST_SIGNIN,
  REQUEST_SIGNIN_FAILURE,
  REQUEST_SIGNIN_SUCCESS,
  REQUEST_SIGNOUT,
  REQUEST_SIGNOUT_FAILURE,
  REQUEST_SIGNOUT_SUCCESS,
  UPDATE_ACCESS_TOKEN,
} from '../actions/authActions';

const initialState = {
  accessToken: null,
  error: null,
  isFetching: true
};

const authReducer = (state = initialState, action) => {
  let error;
  switch (action.type) {
    case REQUEST_REGISTER:
      return {...state, isFetching: true};
    case REQUEST_REGISTER_SUCCESS:
      return {...state, accessToken: action.payload.accessToken, isFetching: false};
    case REQUEST_REGISTER_FAILURE:
      error = action.payload;
      return {...state, error, isFetching: false};
    case REQUEST_SIGNIN:
      return {...state, isFetching: true};
    case REQUEST_SIGNIN_SUCCESS:
      return {...state, accessToken: action.payload.accessToken, isFetching: false};
    case REQUEST_SIGNIN_FAILURE:
      error = action.payload;
      return {...state, error, isFetching: false};
    case REQUEST_SIGNOUT:
      return {...state, isFetching: true};
    case REQUEST_SIGNOUT_SUCCESS:
      return {...state, accessToken: action.payload.accessToken, isFetching: false};
    case REQUEST_SIGNOUT_FAILURE:
      error = action.payload;
      return {...state, error, isFetching: false};
    case UPDATE_ACCESS_TOKEN:
      return {...state, accessToken: action.payload, isFetching: false};

    default:
      return {
        ...state
      };
  }
};

export default authReducer;