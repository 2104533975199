import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import {sectionStyles} from '../../styles/sectionStyles';
import {connect} from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";
import {closeToast} from "../../store/actions/toastActions";
import Alert from "@material-ui/lab/Alert";

class Toast extends React.Component {
  handleClose = () => {
    this.props.closeToast();
  };

  render() {
    const {open, severity, message} = this.props.toast;
    return (
          <Snackbar
              open={open}
              autoHideDuration={6000}
              onClose={this.handleClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
          >
              <Alert onClose={this.handleClose} severity={severity}>
                  {message}
              </Alert>
          </Snackbar>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    toast: state.toast,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeToast: () => dispatch(closeToast())
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(sectionStyles)(Toast));

