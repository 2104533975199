import {combineReducers} from 'redux';
import accountReducer from './accountReducer';
import categoryReducer from './categoryReducer';
import transactionReducer from './transactionReducer';
import authReducer from './authReducer';
import filterReducer from "./filterReducer";
import toastReducer from "./toastReducer";
import groupReducer from "./groupReducer";

const rootReducer = combineReducers({
  account: accountReducer,
  category: categoryReducer,
  transaction: transactionReducer,
  auth: authReducer,
  filter: filterReducer,
  toast: toastReducer,
  group: groupReducer,
});

export default rootReducer;