import React from 'react';
import {withStyles} from '@material-ui/core';
import {sectionStyles} from '../../styles/sectionStyles';
import {compose} from 'redux';
import connect from 'react-redux/es/connect/connect';
import LinearProgress from '@material-ui/core/LinearProgress/LinearProgress';
import CategoriesTree from './CategoriesTree';
import {fetchCategoriesWithTransactions} from '../../store/actions/categoryActions';
import DateAdapterFactory from "../../utils/date/DateAdapterFactory";
// import Typography from "@material-ui/core/Typography";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";

class CategoriesList extends React.Component {
  componentDidMount() {
    if (!this.props.auth.accessToken) {
      return;
    }
    const {dateStart, dateEnd, accountsExcluded} = this.props;
    const ignoredToBalance = false;
    const pendingReturn = false;
    this.props.fetchCategoriesWithTransactions({
      dateStart: DateAdapterFactory.createInstance(dateStart).getStartDayMoment().toISOString(),
      dateEnd: DateAdapterFactory.createInstance(dateEnd).getEndDayMoment().toISOString(),
      accountsExcluded,
      ignoredToBalance,
      pendingReturn,
    });
  }

  state = {
    collapsedCategories: []
  };

  handleOnClickDropDown = (id, isCollapsed) => {
    const collapsedCategories = this.state.collapsedCategories;
    if (isCollapsed) {
      return this.setState({
        collapsedCategories: collapsedCategories.filter(categoryId => categoryId !== id)
      });
    }

    collapsedCategories.push(id);
    this.setState({
      collapsedCategories: collapsedCategories
    });
  };

  render() {

    const {categoryTypes, isFetching, error} = this.props.categoryWithTransactionsList;

    if (isFetching) {
      return (
          <LinearProgress />
      )
    }

    if (error) {
      return (
          <p>{error}</p>
      )
    }

    if (!categoryTypes) {
      return (
          <p>No hay categories creadas</p>
      )
    }

    if (categoryTypes.length === 0) {
      return null;
    }

    return (
        this.props.nestedTree ?
            <ul className="tree">
              <CategoriesTree node={categoryTypes.categoriesTree} depth={0} handleOnClickDropDown={this.handleOnClickDropDown}
                              collapsedCategories={this.state.collapsedCategories}/>
            </ul> : <>
              {
                categoryTypes.chartData.map((el, index) => {
                  return (
                      <div key={index}>
                        <p>{el.name}</p>
                        <ResponsiveContainer width="95%" height={400}>
                          <BarChart width={1500} height={400} data={el.data}>
                            <CartesianGrid strokeDasharray="3 3"/>
                            <XAxis dataKey="name" tick={{fontSize: 8}}/>
                            <YAxis/>
                            <Bar dataKey="amount" fill="#8884d8" />
                            <ReferenceLine y={0} stroke="#000" />
                          </BarChart>
                        </ResponsiveContainer>
                      </div>
                  )
                })
              }
            </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    categoryWithTransactionsList: state.category.categoryWithTransactionsList
  }
};

const mapDispatchToProps = dispatch => {
  return {
    fetchCategoriesWithTransactions: (data) => dispatch(fetchCategoriesWithTransactions(data))
  }
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(sectionStyles)
)(CategoriesList);

