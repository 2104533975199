import Api from '../../services/Api';
import {serializeObjectToUrlQueryParameters} from '../../utils/paramsUtils';
import DateAdapterFactory from "../../utils/date/DateAdapterFactory";
import {openToast} from "./toastActions";

//Account list
export const FETCH_TRANSACTIONS = 'FETCH_TRANSACTIONS';
export const FETCH_TRANSACTIONS_SUCCESS = 'FETCH_TRANSACTIONS_SUCCESS';
export const FETCH_TRANSACTIONS_FAILURE = 'FETCH_TRANSACTIONS_FAILURE';
export const RESET_TRANSACTIONS = 'RESET_TRANSACTIONS';

//Create new transaction
export const CREATE_TRANSACTION = 'CREATE_TRANSACTION';
export const CREATE_TRANSACTION_SUCCESS = 'CREATE_TRANSACTION_SUCCESS';
export const CREATE_TRANSACTION_FAILURE = 'CREATE_TRANSACTION_FAILURE';
export const RESET_NEW_TRANSACTION = 'RESET_NEW_TRANSACTION';

//Fetch transaction
export const FETCH_TRANSACTION = 'FETCH_TRANSACTION';
export const FETCH_TRANSACTION_SUCCESS = 'FETCH_TRANSACTION_SUCCESS';
export const FETCH_TRANSACTION_FAILURE = 'FETCH_TRANSACTION_FAILURE';
export const RESET_ACTIVE_TRANSACTION = 'RESET_ACTIVE_TRANSACTION';

//Update transaction
export const UPDATE_TRANSACTION = 'UPDATE_TRANSACTION';
export const UPDATE_TRANSACTION_SUCCESS = 'UPDATE_TRANSACTION_SUCCESS';
export const UPDATE_TRANSACTION_FAILURE = 'UPDATE_TRANSACTION_FAILURE';

//Delete transaction
export const DELETE_TRANSACTION = 'DELETE_TRANSACTION';
export const DELETE_TRANSACTION_SUCCESS = 'DELETE_TRANSACTION_SUCCESS';
export const DELETE_TRANSACTION_FAILURE = 'DELETE_TRANSACTION_FAILURE';
export const RESET_DELETED_TRANSACTION = 'RESET_DELETED_TRANSACTION';

//Create new transaction
export const UPDATE_TRANSACTIONS = 'UPDATE_TRANSACTIONS';
export const UPDATE_TRANSACTIONS_SUCCESS = 'UPDATE_TRANSACTIONS_SUCCESS';
export const UPDATE_TRANSACTIONS_FAILURE = 'UPDATE_TRANSACTIONS_FAILURE';

export const UPDATE_TRANSACTIONS_CATEGORY = 'UPDATE_TRANSACTIONS_CATEGORY';
export const UPDATE_TRANSACTIONS_CATEGORY_SUCCESS = 'UPDATE_TRANSACTIONS_CATEGORY_SUCCESS';
export const UPDATE_TRANSACTIONS_CATEGORY_FAILURE = 'UPDATE_TRANSACTIONS_CATEGORY_FAILURE';

export const FETCH_SCRAPED_TRANSACTIONS = 'FETCH_SCRAPED_TRANSACTIONS';
export const FETCH_SCRAPED_TRANSACTIONS_SUCCESS = 'FETCH_SCRAPED_TRANSACTIONS_SUCCESS';
export const FETCH_SCRAPED_TRANSACTIONS_FAILURE = 'FETCH_SCRAPED_TRANSACTIONS_FAILURE';
export const FETCH_SCRAPED_TRANSACTIONS_NEED_OTP = 'FETCH_SCRAPED_TRANSACTIONS_NEED_OTP';
export const CLOSE_AND_RESET_TRANSACTION_TO_IMPORT_MODAL = 'CLOSE_AND_RESET_TRANSACTION_TO_IMPORT_MODAL';

export const SET_UNCHECKED_LIST = 'SET_UNCHECKED_LIST';
export const SET_ALL_CHECKED = 'SET_ALL_CHECKED';

export const CREATE_SUBTRANSACTION = 'CREATE_SUBTRANSACTION';
export const CREATE_SUBTRANSACTION_SUCCESS = 'CREATE_SUBTRANSACTION_SUCCESS';
export const CREATE_SUBTRANSACTION_FAILURE = 'CREATE_SUBTRANSACTION_FAILURE';

export const UPDATE_SUBTRANSACTION = 'UPDATE_SUBTRANSACTION';
export const UPDATE_SUBTRANSACTION_SUCCESS = 'UPDATE_SUBTRANSACTION_SUCCESS';
export const UPDATE_SUBTRANSACTION_FAILURE = 'UPDATE_SUBTRANSACTION_FAILURE';

export const DELETE_SUBTRANSACTION = 'DELETE_SUBTRANSACTION';
export const DELETE_SUBTRANSACTION_SUCCESS = 'DELETE_SUBTRANSACTION_SUCCESS';
export const DELETE_SUBTRANSACTION_FAILURE = 'DELETE_SUBTRANSACTION_FAILURE';

export const RESET_SUBTRANSACTIONS = 'RESET_SUBTRANSACTIONS';

export const FETCH_TRANSACTIONS_GROUPED_BY_DATE = 'FETCH_TRANSACTIONS_GROUPED_BY_DATE';
export const FETCH_TRANSACTIONS_GROUPED_BY_DATE_SUCCESS = 'FETCH_TRANSACTIONS_GROUPED_BY_DATE_SUCCESS';
export const FETCH_TRANSACTIONS_GROUPED_BY_DATE_FAILURE = 'FETCH_TRANSACTIONS_GROUPED_BY_DATE_FAILURE';

export function updateTransactionsCategory({updateAll, callback}) {
  return (dispatch) => {
    const request = Api.getInstance().post('/transactions/categories', {updateAll});
    dispatch({
      type: UPDATE_TRANSACTIONS_CATEGORY
    });

    request.then(response => {
      if (response.error) {
        return dispatch(updateTransactionsCategoryFailure());
      }
      dispatch(updateTransactionsCategorySuccess());
      if (callback) {
        callback();
      }
    });
  }
}

export function updateTransactions({selectedTransactions, filterSetTransactions, callback}) {
  return (dispatch) => {
    const request = Api.getInstance().put('/transactions/parameters', { selectedTransactions, filterSetTransactions: {
      ...(filterSetTransactions.checkboxCategoryChecked && { category: filterSetTransactions.category }),
      ...(filterSetTransactions.checkboxGroupsChecked && { groups: filterSetTransactions.groups }),
      } });
    dispatch({
      type: UPDATE_TRANSACTIONS_CATEGORY
    });

    request.then(response => {
      if (response.error) {
        return dispatch(updateTransactionsCategoryFailure());
      }
      dispatch(updateTransactionsCategorySuccess());
      if (callback) {
        callback();
      }
    });
  }
}

function updateTransactionsCategorySuccess() {
  return {
    type: UPDATE_TRANSACTIONS_CATEGORY_SUCCESS
  };
}

function updateTransactionsCategoryFailure() {
  return {
    type: UPDATE_TRANSACTIONS_CATEGORY_FAILURE
  };
}

export function fetchTransactions(data) {
  return (dispatch) => {
    const queryParameters = serializeObjectToUrlQueryParameters(data);
    const request = Api.getInstance().get(`/transactions?${queryParameters}`);
    dispatch({
      type: FETCH_TRANSACTIONS
    });
    request
        .then(response => {
          !response.error ? dispatch(fetchTransactionsSuccess(response.data)) : dispatch(fetchTransactionsFailure(response.data));
        })
        .catch(error => {
          console.log(error);
        })
  }
}

function fetchTransactionsSuccess(transactions) {
  return {
    type: FETCH_TRANSACTIONS_SUCCESS,
    payload: transactions
  };
}

function fetchTransactionsFailure(error) {
  return {
    type: FETCH_TRANSACTIONS_FAILURE,
    payload: error
  };
}

export function resetTransactions() {
  return {
    type: RESET_TRANSACTIONS
  }
}

export function editTransaction({transaction, redirect}) {
  return (dispatch) => {
    const {id, valueDate, ...params} = transaction;
    const isoDate = DateAdapterFactory.createInstance(valueDate).toISOString();
    const request = Api.getInstance().put(`/transactions/${id}`, {
      ...params,
      valueDate: isoDate
    });

    dispatch({
      type: UPDATE_TRANSACTION
    });

    request.then(response => {
      if (response.error) {
        return dispatch(editTransactionFailure(response.data));
      }
      //esto creo que sobra porque se está haciendo un redirect al actualizar la transacción
      dispatch(editTransactionSuccess(response.data));
      dispatch(resetTransactions());
      if (redirect) {
        return redirect();
      }
    })
  }
}

function editTransactionSuccess(posts) {
  return {
    type: UPDATE_TRANSACTION_SUCCESS,
    payload: posts
  };
}

function editTransactionFailure(error) {
  return {
    type: UPDATE_TRANSACTION_FAILURE,
    payload: error
  };
}

export function createTransaction({transactions, redirect}) {
  return (dispatch) => {
    const request = Api.getInstance().post('/transactions', {
      transactions
    });
    dispatch({
      type: CREATE_TRANSACTION
    });

    request.then(response => {
      if (response.error) {
        return dispatch(createTransactionFailure(response.data));
      }
      dispatch(createTransactionSuccess(response.data));
      dispatch(resetTransactions());
      if (redirect) {
        return redirect();
      }
    })
  }
}

function createTransactionSuccess(posts) {
  return {
    type: CREATE_TRANSACTION_SUCCESS,
    payload: posts
  };
}

function createTransactionFailure(error) {
  return {
    type: CREATE_TRANSACTION_FAILURE,
    payload: error
  };
}

export function fetchTransaction(id) {
  return (dispatch) => {
    const request = Api.getInstance().get(`/transactions/${id}`);

    dispatch({
      type: FETCH_TRANSACTION
    });

    request.then(response => {
      !response.error ? dispatch(fetchTransactionSuccess(response.data)) : dispatch(fetchTransactionFailure(response.data));
    })
  }
}

function fetchTransactionSuccess(msg) {
  return {
    type: FETCH_TRANSACTION_SUCCESS,
    payload: msg
  };
}

function fetchTransactionFailure(error) {
  return {
    type: FETCH_TRANSACTION_FAILURE,
    payload: error
  };
}

export function deleteTransaction({id, redirect}) {
  const request = Api.getInstance().delete(`/transactions/${id}`);

  return (dispatch) => {
    dispatch({
      type: DELETE_TRANSACTION
    });

    request.then(response => {
      if (response.error) {
        return dispatch(deleteTransactionFailure(response.data));
      }
      dispatch(deleteTransactionSuccess(response.data));
      dispatch(resetTransactions());
      if (redirect) {
        return redirect();
      }
    })
  }
}

function deleteTransactionSuccess(msg) {
  return {
    type: DELETE_TRANSACTION_SUCCESS,
    payload: msg
  };
}

function deleteTransactionFailure(error) {
  return {
    type: DELETE_TRANSACTION_FAILURE,
    payload: error
  };
}

export function fetchTransactionsAuto({fileType, account, ignoredToBalance, pendingReturn, accountTypeParams, otp}) {
  return (dispatch) => {
    dispatch(fetchScrapedTransactions());
    const fetchTransactionsAutoPromise = getTransactionsAutoPromise({fileType, account, ignoredToBalance, pendingReturn, accountTypeParams, otp});
    fetchTransactionsAutoPromise
      .then(data => {
        if (data.isOtpNeeded) {
          return dispatch(fetchScrapedTransactionsNeedOtp(data));
        }
        dispatch(fetchScrapedTransactionsSuccess(data));
      })
      .catch(error => {
        dispatch(fetchScrapedTransactionsFailure(error.message));
        dispatch(openToast({message: error.message}))
      });
  }
}

function getTransactionsAutoPromise({fileType, account, ignoredToBalance, pendingReturn, accountTypeParams, otp}) {
  return new Promise ((resolve, reject) => {
    const params = {
      fileType,
      account,
      ignoredToBalance,
      pendingReturn,
      otp
    };
    for (let i = 0; i < accountTypeParams.length; i++) {
      params[accountTypeParams[i].name] = accountTypeParams[i].value;
    }
    const request = Api.getInstance().post('/transactions/scraping', params);
    request
      .then(response => {
        if (response.error) {
          return reject(response.error);
        }
        resolve(response.data);
      })
      .catch(error => {
        return reject(error.response.data);
      })
  });
}

export function fetchTransactionsFile({fileType, account, ignoredToBalance, pendingReturn, fileData, accountTypeParams}) {
  return (dispatch) => {
    dispatch(fetchScrapedTransactions());
    const fetchTransactionsFromFilePromise = getTransactionsFromFilePromise({fileType, account, ignoredToBalance, pendingReturn, fileData, accountTypeParams});
    fetchTransactionsFromFilePromise
      .then(data => {
        dispatch(fetchScrapedTransactionsSuccess(data));
      })
      .catch(error => {
        dispatch(fetchScrapedTransactionsFailure(error.message));
        dispatch(openToast({message: error.message}))
      })
  };
}

function getTransactionsFromFilePromise({fileType, account, ignoredToBalance, pendingReturn, fileData, accountTypeParams}) {
  const params = {
    fileType,
    account,
    ignoredToBalance,
    pendingReturn,
    fileData
  };
  for (let i = 0; i < accountTypeParams.length; i++) {
    params[accountTypeParams[i].name] = accountTypeParams[i].value;
  }
  return new Promise ((resolve, reject) => {
    const request = Api.getInstance().post('/transactions/file', params);
    request
      .then(response => {
        if (response.error) {
          return reject(response.error);
        }
        resolve(response.data);
      })
      .catch(error => {
        return reject(error.response.data);
      })
  });
}

function fetchScrapedTransactions() {
  return {
    type: FETCH_SCRAPED_TRANSACTIONS
  };
}

function fetchScrapedTransactionsNeedOtp() {
  return {
    type: FETCH_SCRAPED_TRANSACTIONS_NEED_OTP,
  };
}

function fetchScrapedTransactionsSuccess(transactions) {
  return {
    type: FETCH_SCRAPED_TRANSACTIONS_SUCCESS,
    payload: transactions
  };
}

function fetchScrapedTransactionsFailure(error) {
  return {
    type: FETCH_SCRAPED_TRANSACTIONS_FAILURE,
    payload: error
  };
}

export function closeAndResetTransactionToImportModal() {
  return {
    type: CLOSE_AND_RESET_TRANSACTION_TO_IMPORT_MODAL
  }
}

export function setUncheckedList({newUnCheckedList}) {
  return (dispatch) => {
    dispatch({
      type: SET_UNCHECKED_LIST,
      payload: newUnCheckedList
    });
  }
}

export function setAllChecked({isSelectAllChecked}) {
  return (dispatch) => {
    dispatch({
      type: SET_ALL_CHECKED,
      payload: isSelectAllChecked
    });
  }
}

export function resetSubTransactions() {
  return {
    type: RESET_SUBTRANSACTIONS
  }
}

export function createSubTransaction({subTransaction, redirect}) {
  return (dispatch) => {
    const request = Api.getInstance().post('/subtransactions', {
      subTransaction
    });
    dispatch({
      type: CREATE_SUBTRANSACTION
    });

    request.then(response => {
      if (response.error) {
        return dispatch(createSubTransactionFailure(response.data));
      }
      dispatch(createSubTransactionSuccess(response.data));
      dispatch(resetTransactions());
      if (redirect) {
        return redirect();
      }
    })
  }
}

function createSubTransactionSuccess(posts) {
  return {
    type: CREATE_SUBTRANSACTION_SUCCESS,
    payload: posts
  };
}

function createSubTransactionFailure(error) {
  return {
    type: CREATE_SUBTRANSACTION_FAILURE,
    payload: error
  };
}

export function editSubTransaction({subTransaction, redirect}) {
  return (dispatch) => {
    const {id, ...params} = subTransaction;
    const request = Api.getInstance().put(`/subtransactions/${id}`, {
      ...params,
    });

    dispatch({
      type: UPDATE_SUBTRANSACTION
    });

    request.then(response => {
      if (response.error) {
        return dispatch(editSubTransactionFailure(response.data));
      }
      dispatch(editSubTransactionSuccess(response.data));
      dispatch(resetSubTransactions());
      if (redirect) {
        return redirect();
      }
    })
  }
}

function editSubTransactionSuccess(posts) {
  return {
    type: UPDATE_SUBTRANSACTION_SUCCESS,
    payload: posts
  };
}

function editSubTransactionFailure(error) {
  return {
    type: UPDATE_SUBTRANSACTION_FAILURE,
    payload: error
  };
}

export function deleteSubTransaction({id, transactionId, redirect}) {
  const request = Api.getInstance().delete(`/subtransactions/${id}`, {data: {transactionId}});

  return (dispatch) => {
    dispatch({
      type: DELETE_SUBTRANSACTION
    });

    request.then(response => {
      if (response.error) {
        return dispatch(deleteSubTransactionFailure(response.data));
      }
      dispatch(deleteSubTransactionSuccess(response.data));
      dispatch(resetSubTransactions());
      if (redirect) {
        return redirect();
      }
    })
  }
}

function deleteSubTransactionSuccess(msg) {
  return {
    type: DELETE_SUBTRANSACTION_SUCCESS,
    payload: msg
  };
}

function deleteSubTransactionFailure(error) {
  return {
    type: DELETE_SUBTRANSACTION_FAILURE,
    payload: error
  };
}

export function fetchTransactionsGroupedByDate() {
  return (dispatch) => {
    const request = Api.getInstance().get(`/transactions/grouped-by-date`);

    dispatch({
      type: FETCH_TRANSACTIONS_GROUPED_BY_DATE
    });

    request.then(response => {
      !response.error ? dispatch(fetchTransactionsGroupedByDateSuccess(response.data)) : dispatch(fetchTransactionsGroupedByDateFailure(response.data));
    })
  }
}

function fetchTransactionsGroupedByDateSuccess(msg) {
  return {
    type: FETCH_TRANSACTIONS_GROUPED_BY_DATE_SUCCESS,
    payload: msg
  };
}

function fetchTransactionsGroupedByDateFailure(error) {
  return {
    type: FETCH_TRANSACTIONS_GROUPED_BY_DATE_FAILURE,
    payload: error
  };
}