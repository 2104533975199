import {
  CLOSE_AND_RESET_TRANSACTION_TO_IMPORT_MODAL,
  CREATE_TRANSACTION,
  CREATE_TRANSACTION_FAILURE,
  CREATE_TRANSACTION_SUCCESS,
  DELETE_TRANSACTION,
  DELETE_TRANSACTION_FAILURE,
  DELETE_TRANSACTION_SUCCESS,
  FETCH_TRANSACTION,
  FETCH_TRANSACTION_FAILURE,
  FETCH_TRANSACTION_SUCCESS,
  FETCH_TRANSACTIONS,
  FETCH_TRANSACTIONS_FAILURE,
  FETCH_TRANSACTIONS_SUCCESS,
  FETCH_SCRAPED_TRANSACTIONS_FAILURE,
  FETCH_SCRAPED_TRANSACTIONS_SUCCESS,
  RESET_ACTIVE_TRANSACTION,
  RESET_DELETED_TRANSACTION,
  RESET_NEW_TRANSACTION,
  RESET_TRANSACTIONS,
  UPDATE_TRANSACTION,
  UPDATE_TRANSACTION_FAILURE,
  UPDATE_TRANSACTION_SUCCESS,
  UPDATE_TRANSACTIONS,
  UPDATE_TRANSACTIONS_FAILURE,
  UPDATE_TRANSACTIONS_SUCCESS,
  UPDATE_TRANSACTIONS_CATEGORY,
  UPDATE_TRANSACTIONS_CATEGORY_FAILURE,
  UPDATE_TRANSACTIONS_CATEGORY_SUCCESS,
  FETCH_SCRAPED_TRANSACTIONS,
  SET_UNCHECKED_LIST,
  SET_ALL_CHECKED,
  FETCH_TRANSACTIONS_GROUPED_BY_DATE,
  FETCH_TRANSACTIONS_GROUPED_BY_DATE_SUCCESS,
  FETCH_TRANSACTIONS_GROUPED_BY_DATE_FAILURE, FETCH_SCRAPED_TRANSACTIONS_NEED_OTP,
} from '../actions/transactionActions';

const initialState = {
  transactionList: {
    transactions: [],
    error: null,
    isFetching: false,
    didInvalidate: false,
  },
  newTransaction:{transaction:null, error: null, isFetching: false},
  activeTransaction:{transaction:null, error:null, isFetching: false},
  deletedTransaction: {transaction: null, error:null, isFetching: false},
  transactionsToImport: {
    error: null,
    isFetching: false,
    isOtpNeeded: false,
    modal: {
      open: false,
      unCheckedList: [],
      selectAllChecked: false,
    },
    transactions: []
  },
  transactionGroupedByDateList: {
    transactions: [],
    error: null,
    isFetching: false
  },
};

const transactionReducer = (state = initialState, action) => {
  let error;
  switch (action.type) {
    case FETCH_TRANSACTIONS:
      return { ...state, transactionList: {transactions:[], error: null, isFetching: true, didInvalidate: false} };

    case FETCH_TRANSACTIONS_SUCCESS:
      return { ...state, transactionList: {transactions: action.payload, error:null, isFetching: false, didInvalidate: false} };

    case FETCH_TRANSACTIONS_FAILURE:
      error = action.payload;
      return { ...state, transactionList: {transactions: [], error: error, isFetching: false, didInvalidate: false} };

    case RESET_TRANSACTIONS:
      return { ...state, transactionList: {transactions: state.transactionList.transactions, error: null, isFetching: false, didInvalidate: true} };

    case FETCH_TRANSACTION:
      return { ...state, activeTransaction: {transaction: null, error: null, isFetching: true} };

    case FETCH_TRANSACTION_SUCCESS:
      return { ...state, activeTransaction: {transaction: action.payload, error:null, isFetching: false} };

    case FETCH_TRANSACTION_FAILURE:
      error = action.payload;
      return { ...state, activeTransaction: {transaction: null, error: error, isFetching: false} };

    case UPDATE_TRANSACTION: {
      const {transactions, error} = state.transactionList;
      return { ...state, transactionList: {transactions, error, isFetching: true} };
    }

    case UPDATE_TRANSACTION_SUCCESS: {
      const {transactions} = state.transactionList;
      const updatedTransactions = transactions.map(transaction => {
        return transaction.id === action.payload.id ? action.payload : transaction;
      });
      return { ...state, transactionList: {transactions: updatedTransactions, error: null, isFetching: false} };
    }

    case UPDATE_TRANSACTION_FAILURE: {
      const {transactions} = state.transactionList;
      return { ...state, transactionList: {transactions, error: action.payload, isFetching: false} };
    }

    case RESET_ACTIVE_TRANSACTION:
      return initialState;

    case CREATE_TRANSACTION: {
      const {transactions, error} = state.transactionList;
      return { ...state, transactionList: {transactions, error, isFetching: true} };
    }

    case CREATE_TRANSACTION_SUCCESS: {
      const transactions = [...state.transactionList.transactions, ...action.payload];
      return { ...state, transactionList: {transactions, error: null, isFetching: false} };
    }

    case CREATE_TRANSACTION_FAILURE: {
      const {transactions} = state.transactionList;
      return { ...state, transactionList: {transactions, error: action.payload, isFetching: false} };
    }

    case UPDATE_TRANSACTIONS: {
      const {transactions, error} = state.transactionList;
      return { ...state, transactionList: {transactions, error, isFetching: true} };
    }

    case UPDATE_TRANSACTIONS_SUCCESS: {
      return initialState;
    }

    case UPDATE_TRANSACTIONS_FAILURE: {
      return initialState;
    }

    case UPDATE_TRANSACTIONS_CATEGORY: {
      const {transactions, error} = state.transactionList;
      return { ...state, transactionList: {transactions, error, isFetching: true} };
    }

    case UPDATE_TRANSACTIONS_CATEGORY_SUCCESS: {
      return initialState;
    }

    case UPDATE_TRANSACTIONS_CATEGORY_FAILURE: {
      return initialState;
    }

    case RESET_NEW_TRANSACTION:
      return initialState;

    case DELETE_TRANSACTION: {
      const {transactions, error} = state.transactionList;
      return { ...state, transactionList: {transactions, error, isFetching: true} };
    }

    case DELETE_TRANSACTION_SUCCESS: {
      const transactions = state.transactionList.transactions.filter(transaction => transaction.id !== action.payload.id);
      return { ...state, transactionList: {transactions, error: null, isFetching: false} };
    }

    case DELETE_TRANSACTION_FAILURE: {
      const {transactions} = state.transactionList;
      return { ...state, transactionList: {transactions, error: action.payload, isFetching: false} };
    }

    case FETCH_SCRAPED_TRANSACTIONS: {
      return { ...state, transactionsToImport: {transactions: [], modal: {open: false, unCheckedList: [], selectAllChecked: false}, error: null, isFetching: true, isOtpNeeded: false}};
    }

    case FETCH_SCRAPED_TRANSACTIONS_SUCCESS: {
      const transactions = action.payload;
      return {
        ...state,
        transactionsToImport: {
          transactions,
          modal: {
            open: true,
            unCheckedList: transactions.map((elem, index) => {
              return {
                index,
                isNew: elem.isNew
              }
            }).filter(elem => !elem.isNew).map(elem => elem.index),
            selectAllChecked: false
          },
          error: null,
          isFetching: false,
          isOtpNeeded: false,
        }
      };
    }

    case FETCH_SCRAPED_TRANSACTIONS_NEED_OTP: {
      return {
        ...state,
        transactionsToImport: {
          transactions: [],
          modal: {
            open: false,
            unCheckedList: [],
            selectAllChecked: false
          },
          error: null,
          isFetching: false,
          isOtpNeeded: true,
        }
      };
    }

    case FETCH_SCRAPED_TRANSACTIONS_FAILURE: {
      return { ...state, transactionsToImport: {transactions: [], modal: {open: false, unCheckedList: [], selectAllChecked: false}, error: action.payload, isFetching: false, isOtpNeeded: false}};
    }

    case CLOSE_AND_RESET_TRANSACTION_TO_IMPORT_MODAL: {
      return { ...state, transactionsToImport: {transactions: [], modal: {open: false, unCheckedList: [], selectAllChecked: false}, error: null, isFetching: false, isOtpNeeded: false}};
    }

    case RESET_DELETED_TRANSACTION:
      return initialState;

    case SET_UNCHECKED_LIST:
      const newUnCheckedList = action.payload;
      return {
        ...state,
        transactionsToImport: {
          transactions: state.transactionsToImport.transactions,
          modal: {
            open: state.transactionsToImport.modal.open,
            unCheckedList: newUnCheckedList,
            selectAllChecked: false,
          },
          error: state.transactionsToImport.error,
          isFetching: state.transactionsToImport.isFetching,
          isOtpNeeded: state.transactionsToImport.isOtpNeeded,
        }
      };
    case SET_ALL_CHECKED:
      const isSelectAllChecked = action.payload;
      return {
        ...state,
        transactionsToImport: {
          transactions: state.transactionsToImport.transactions,
          modal: {
            open: state.transactionsToImport.modal.open,
            unCheckedList: isSelectAllChecked ? [] : state.transactionsToImport.transactions.map((elem, index) => index),
            selectAllChecked: isSelectAllChecked,
          },
          error: state.transactionsToImport.error,
          isFetching: state.transactionsToImport.isFetching,
          isOtpNeeded: state.transactionsToImport.isOtpNeeded,
        }
      };
    case FETCH_TRANSACTIONS_GROUPED_BY_DATE:
      return { ...state, transactionGroupedByDateList: {transactions:[], error: null, isFetching: true} };
    case FETCH_TRANSACTIONS_GROUPED_BY_DATE_SUCCESS:
      return { ...state, transactionGroupedByDateList: {transactions: action.payload, error:null, isFetching: false} };
    case FETCH_TRANSACTIONS_GROUPED_BY_DATE_FAILURE:
      error = action.payload;
      return { ...state, transactionGroupedByDateList: {transactions: [], error: error, isFetching: false} };
    default:
      return {
        ...state
      };
  }
};

export default transactionReducer;