import React from 'react';
import Grid from '@material-ui/core/Grid/Grid';
import {withStyles} from '@material-ui/core';
import Fab from '@material-ui/core/Fab/Fab';
import {Link, Redirect} from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import {sectionStyles} from '../../styles/sectionStyles';
import Header from '../layout/Header';
import {compose} from 'redux';
import {connect} from "react-redux";
import {fetchGroups} from "../../store/actions/groupActions";
import GroupList from "./GroupList";

class Groups extends React.Component {
  componentDidMount() {
    if (!this.props.auth.accessToken) {
      return;
    }
    this.props.fetchGroups();
  }

  render() {
    const title = `Grupos`;
    const {classes, auth} = this.props;

    if (!auth.accessToken) {
      return <Redirect to='/signin' />
    }

    return (
      <div className={classes.wrapperSection}>
        <Header title={title} />
        <div className={classes.wrapperSectionContent}>
          <div className={classes.appBarSpacer} />
          <Grid container className={classes.sectionContent}>
            <Grid item xs={12}>
              <GroupList/>
            </Grid>
          </Grid>
          <div className={classes.appBarSpacer} />
          <div className={classes.appBarSpacer} />
        </div>
        <div className={classes.navbarSection}>
          <Fab color={`primary`} aria-label="add" component={Link} to={`/groups/create`} size={`small`}>
            <AddIcon />
          </Fab>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchGroups: () => dispatch(fetchGroups())
  }
};

export default compose(
    connect(mapStateToProps,mapDispatchToProps),
    withStyles(sectionStyles)
)(Groups);

