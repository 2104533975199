import React from 'react';
import CategoriesList from './CategoriesList';
import Grid from '@material-ui/core/Grid/Grid';
import Header from '../layout/Header';
import {withStyles} from '@material-ui/core';
import {sectionStyles} from '../../styles/sectionStyles';
import {Redirect} from 'react-router-dom';
import {compose} from 'redux';
import {connect} from "react-redux";
import {editCategory, fetchCategories} from '../../store/actions/categoryActions';
import LabelsModal from "./LabelsModal";

class Categories extends React.Component {
  componentDidMount() {
    if (!this.props.auth.accessToken) {
      return;
    }
    this.props.fetchCategories();
  }

  state = {
    open: false,
    labels: '',
    labelsOriginal: '',
    elementIdToEditLabels: null,
  };

  handleOpenModalLabels = ({categoryId, labels}) => {
    this.setState({
      open: true,
      labels,
      labelsOriginal: labels,
      elementIdToEditLabels: categoryId
    });
  };

  handleSetLabels = (e) => {
    e.preventDefault();
    const isValidStateToSave = this.isValidStateToSave();
    if (!isValidStateToSave) {
      return;
    }
    const params = {
      id: this.state.elementIdToEditLabels,
      labels: this.state.labels
    };
    this.props.editCategory(params);
    this.setState({
      open: false
    });
  }

  isValidStateToSave() {
    return this.state.labels === '' || !this.state.labels.split(',').includes('');
  };

  handleOnChangeInput = (labels) => {
    this.setState({
      labels
    });
  }

  handleReset = (e) => {
    e.preventDefault()
    this.setState({
      labels: this.state.labelsOriginal
    });
  };

  handleClose = (e) => {
    e.preventDefault()
    this.setState({
      open: false,
      labels: '',
      labelsOriginal: '',
      elementIdToEditLabels: null,
    });
  };

  render() {
    const title = `Categorias`;
    const {classes, auth} = this.props;

    if (!auth.accessToken) {
      return <Redirect to='/signin' />
    }

    return (
        <div className={classes.wrapperSection}>
          <Header title={title} />
          <div className={classes.wrapperSectionContent}>
            <div className={classes.appBarSpacer} />
            <Grid container className={classes.sectionContent}>
              <Grid item xs={12}>
                <CategoriesList handleOpenModalLabels={this.handleOpenModalLabels}/>
              </Grid>
            </Grid>
            <div className={classes.appBarSpacer} />
          </div>
          <LabelsModal open={this.state.open} labels={this.state.labels}
                       handleClose={this.handleClose}
                       handleReset={this.handleReset}
                       handleSetLabels={this.handleSetLabels}
                       handleOnChangeInput={this.handleOnChangeInput} />
        </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchCategories: () => dispatch(fetchCategories()),
    editCategory: (category) => dispatch(editCategory(category))
  }
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(sectionStyles)
)(Categories);