import React from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import GroupForm from './GroupForm';
import {sectionStyles} from '../../styles/sectionStyles';
import {withStyles} from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress/LinearProgress';
import {Redirect} from 'react-router-dom';
import {
  fetchGroup,
} from '../../store/actions/groupActions';

class GroupEdit extends React.Component {
  componentDidMount() {
    if (!this.props.auth.accessToken) {
      return;
    }
    this.props.fetchGroup(this.props.id);
  }

  render() {
    const {classes, auth} = this.props;

    if (!auth.accessToken) {
      return <Redirect to='/signin' />
    }

    const {group, isFetching, error} = this.props.activeGroup;

    if (isFetching) {
      return (
          <LinearProgress />
      )
    }

    if (error) {
      return (
          <p>{error}</p>
      )
    }

    return (
        <GroupForm group={group} className={classes.wrapperSection}/>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.match.params.id;
  return {
    id,
    auth: state.auth,
    activeGroup: state.group.activeGroup,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchGroup: (id) => dispatch(fetchGroup(id)),
  }
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(sectionStyles)
)(GroupEdit);