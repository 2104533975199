import React from 'react';
import {connect} from "react-redux";
import CategoriesTree from './CategoriesTree';
import CategoryForm from './CategoryForm';
import {editCategory} from '../../store/actions/categoryActions';
import LinearProgress from '@material-ui/core/LinearProgress/LinearProgress';
import {flattenListToTreeList} from '../../utils/listUtils';

class CategoriesList extends React.Component {

  state = {
    isMoving: false,
    elementIdEditingParent: null,
    labels: ''
  };

  handleOnClickMove = (id) => {
    this.setState({
      isMoving: true,
      elementIdEditingParent: id
    }, ()=> {
      console.log(this.state.isMoving, this.state.elementIdEditingParent)
    })
  };

  handleOnClickSetParentId = (id) => {
    const params = {
      id: this.state.elementIdEditingParent,
      parentId: id
    };
    this.props.editCategory(params);
    this.setStateToDefault();
  };

  handleOnClickCancelSetParentId = (e) => {
    e.preventDefault();
    this.setStateToDefault();
  };

  setStateToDefault() {
    this.setState({
      isMoving: false,
      elementIdEditingParent: null
    })
  };

  handleOnClickDropDown = (id, collapsed) => {
    const params = {
      id: id,
      collapsed: !collapsed
    };
    this.props.editCategory(params);
  };

  render() {
    const {handleOpenModalLabels} = this.props;
    const {categories, isFetching, error} = this.props.categoryList;

    if (isFetching) {
      return (
          <LinearProgress />
      )
    }

    if (error) {
      return (
          <>
            <p>{error}</p>
          </>
      )
    }

    if (categories.length === 0) {
      return (
          <>
            <p>No hay categorias creadas. Crea la primera</p>
            <CategoryForm />
          </>
      )
    }
    const node = flattenListToTreeList(categories);
    return (
        <>
          <CategoryForm/>
          <ul className="tree">
            <CategoriesTree node={node} depth={0}
                            handleOnClickMove={this.handleOnClickMove}
                            handleOnClickSetParentId={this.handleOnClickSetParentId}
                            handleOnClickCancelSetParentId={this.handleOnClickCancelSetParentId}
                            isMoving={this.state.isMoving}
                            elementIdEditingParent={this.state.elementIdEditingParent}
                            handleOnClickDropDown={this.handleOnClickDropDown}
                            handleOpenModalLabels={handleOpenModalLabels}
            />
          </ul>
        </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    categoryList: state.category.categoryList
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    editCategory: (category) => dispatch(editCategory(category))
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoriesList);