import React from 'react';
import connect from 'react-redux/es/connect/connect';
import {compose} from 'redux';
import TextField from '@material-ui/core/TextField/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {withStyles} from '@material-ui/core';
import {sectionStyles} from '../../styles/sectionStyles';
import LinearProgress from '@material-ui/core/LinearProgress/LinearProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox/Checkbox';
import {withRouter} from "react-router-dom";
import {getHelperTextNumericValue} from "../../utils/validationUtils";

class SubTransactionDetails extends React.Component {
  render() {
    const {type, concept, alias, amount, ignoredToBalance, pendingReturn, category, comments, handleOnChangeSelect, handleOnChangeInput, categoryList, classes, handleOnChangeCheckbox, error} = this.props;
    const {categories, isFetching: isFetchingCategories, error: errorCategories} = categoryList;
    if (isFetchingCategories) {
      return (
          <LinearProgress />
      )
    }

    if (errorCategories) {
      return (
          <LinearProgress />
      )
    }

    const mapCategoriesToSelect = categories.map(elem => {
      return {
        title: elem.name,
        value: elem.id
      }
    });

    const categoryValue = mapCategoriesToSelect.find(elem => elem.value === category) || '';

    if (type === 'create') {
      return (
          <form className={classes.form} noValidate autoComplete="off">
            <TextField id="concept" label="Concepto" value={concept} onChange={handleOnChangeInput}/>
            <TextField id="alias" label="Alias" value={alias} onChange={handleOnChangeInput}/>
            <TextField id="amount" label="Cantidad" value={amount} onChange={handleOnChangeInput} error={error && error.amount} helperText={getHelperTextNumericValue(error && error.amount)} />
            <Autocomplete
                id="category"
                options={mapCategoriesToSelect}
                getOptionLabel={option => (option.title ? option.title : "")}
                className={classes.autocomplete}
                renderInput={params => (
                    <TextField {...params} label="Categorías" fullWidth />
                )}
                onChange={(event, value) => handleOnChangeSelect(`category`, value)}
                value={categoryValue}
            />
            <FormControlLabel
                control={
                  <Checkbox checked={ignoredToBalance} onChange={(e) => handleOnChangeCheckbox('ignoredToBalance', e.target.checked)} value="checkedA" />
                }
                label="Ignorar para balance"
            />
            <FormControlLabel
                control={
                  <Checkbox checked={pendingReturn} onChange={(e) => handleOnChangeCheckbox('pendingReturn', e.target.checked)} value="checkedA" />
                }
                label="Pendiente de devolución"
            />
          </form>
      )
    }

    if (type === 'edit') {
      return (
          <form className={classes.form} noValidate autoComplete="off">
            <TextField id="concept" label="Concepto" value={concept} onChange={handleOnChangeInput}/>
            <TextField id="alias" label="Alias" value={alias} onChange={handleOnChangeInput}/>
            <TextField id="comments" label="Observaciones" value={comments} readOnly={true} disabled={true}/>
            <TextField id="amount" label="Cantidad" value={amount} onChange={handleOnChangeInput} error={error && error.amount} helperText={getHelperTextNumericValue(error && error.amount)} />
            <Autocomplete
                id="category"
                options={mapCategoriesToSelect}
                getOptionLabel={option => (option.title ? option.title : "")}
                className={classes.autocomplete}
                renderInput={params => (
                    <TextField {...params} label="Categorías" fullWidth />
                )}
                onChange={(event, value) => handleOnChangeSelect(`category`, value)}
                value={categoryValue}
            />
            <FormControlLabel
                control={
                  <Checkbox checked={ignoredToBalance} onChange={(e) => handleOnChangeCheckbox('ignoredToBalance', e.target.checked)} value="ignoredToBalance" />
                }
                label="Ignorar para balance"
            />
            <FormControlLabel
                control={
                  <Checkbox checked={pendingReturn} onChange={(e) => handleOnChangeCheckbox('pendingReturn', e.target.checked)} value="pendingReturn" />
                }
                label="Pendiente de devolución"
            />
          </form>
      )
    }
  }
}


const mapStateToProps = (state) => {
  return {
    categoryList: state.category.categoryList
  };
};

export default withRouter(compose(
    connect(mapStateToProps),
    withStyles(sectionStyles)
)(SubTransactionDetails));