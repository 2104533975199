export const sectionStyles = (theme) => {
  return {
    appBarSpacer: theme.mixins.toolbar,
    wrapperSection: {
      flexGrow: 1,
      height: '100%',
      overflow: 'hidden',
      position: 'relative',
      zIndex: theme.zIndex.drawer + 1,
    },
    wrapperSectionContent: {
      flexGrow: 1,
      height: '100%',
      overflow: 'auto',
      position: 'relative',
      zIndex: theme.zIndex.drawer + 1,
    },
    sectionContent: {
      paddingTop: theme.spacing(1),
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5)
    },
    navbarSection: {
      zIndex: theme.zIndex.drawer + 2,
      bottom: theme.mixins.toolbar.minHeight + 10,
      position: 'absolute',
      right: 10,
      '& > *': {
        marginLeft: theme.spacing(1.8),
      },
    },
    listItemsLinks: {
      display: 'block',
      width: '100%',
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
      paddingLeft: 0,
      paddingRight: 0,
      color: theme.palette.text.primary,
      borderBottom: `1px solid ${theme.palette.background.paper}`
    },

    listItemsLinksDisabled: {
      display: 'block',
      width: '100%',
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
      paddingLeft: 0,
      paddingRight: 0,
      color: theme.palette.text.disabled,
      borderBottom: `1px solid ${theme.palette.background.paper}`
    },

    listItemsLinksHightlight: {
      display: 'block',
      width: '100%',
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
      paddingLeft: 0,
      paddingRight: 0,
      color: theme.palette.warning.main,
      borderBottom: `1px solid ${theme.palette.background.paper}`
    },

    listItemsLinksWrapperColumns: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    listItemsLinksWrapperColumnPrice: {
      flex: '0 0 auto',
      textAlign: 'right',
    },
    form: {
      '& > *': {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        width: '100%',
      },
      '& > input[type="file"]': {
        display: 'none'
      },
    },
    formCategories: {
      '& > div': {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        verticalAlign: 'middle'
      },
      '& > div.is-fullwidth' : {
        width: '100%'
      }
    },
    autocomplete: {
      display: 'inline-flex'
    },
    iconSiblingToTextField: {
      padding: '7px'
    },
    header: {
      zIndex: theme.zIndex.drawer + 2,
      bottom: 'auto',
      top: 0,
      position: 'absolute',
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5),
      justifyContent: 'start',
      ...theme.typography.button
    },
    headerContentWrapper: {
      display: 'block',
      width: '100%'
    },
    headerContentWrapper2: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    headerContentMenuButton: {
      paddingLeft: 0
    },
    green: {
      color: 'green',
    },
    red: {
      color: 'red',
    },
    yellow: {
      color: 'yellow',
    },
    grey: {
      color: 'grey',
    },
    groupSummaryWrapper: {
      display: 'flex',
      width: '100%',
    },
    groupSummaryAvatar: {
      display: 'flex',
      marginRight: theme.spacing(1.5),
    },
    groupSummaryWrapperEmailAndPrice: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    accountSummaryWrapper: {
      display: 'flex',
    },
    accountSummaryAvatar: {
      display: 'flex',
      marginRight: theme.spacing(1.5),
    },
    filterButtons: {
      '& > *': {
        marginRight: theme.spacing(1)
      },
    },
    iconButtonSmall: {
      '& > span svg': {
        fontSize: '1rem'
      }
    },
    transactionToImportList: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    summaryAccountMenu: {
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(2),
    },
    summaryAccountListItemAvatar: {
      minWidth: 'auto',
      marginRight: theme.spacing(1),
    },
    summaryAccountAvatar: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
    accountAvatar: {
      display: 'flex',
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(0),
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
    summaryGroupMenu: {
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(2),
    },
    summaryGroupListItemAvatar: {
      minWidth: 'auto',
      marginRight: theme.spacing(1),
    },
    summaryGroupAvatar: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
    groupAvatar: {
      display: 'flex',
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(0),
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
  }
};