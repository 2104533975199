class DateAdapter {
    get date() {
        return this._date;
    }
    constructor(instance) {
        this._date = instance;
        return this;
    }

    toISOString() {
        return this._date.toISOString();
    }

    toDisplay() {
        return [this._date.getDate(), this._date.getMonth() + 1, this._date.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join(DateAdapter.SLASH_CHARACTER);
    }

    getStartDayMoment() {
        this._date.setHours(0, 0, 0, 0);
        return this;
    }

    getEndDayMoment() {
        this._date.setHours(23, 59, 59, 999);
        return this;
    }

    getDateFromNumberOfDaysBefore(days) {
        this._date.setDate(this._date.getDate() - days);
        return this;
    }

    static getToday() {
        return new Date();
    }

    static getDateFromUnixBirthday() {
        return new Date(1970, 0, 1, 0,0,0,0);
    }

    static getDateFromString(dateString) {
        return new Date(dateString);
    }

    static convertDDMMYYYYtoDate = (value, separator) => {
        const dateSplitted = value.split(separator);
        const iso8601Date = `${dateSplitted[2]}-${dateSplitted[1]}-${dateSplitted[0]}`;
        return new Date(iso8601Date);
    };

    static convertDDMMYYtoDate = (value, separator) => {
        const dateSplitted = value.split(separator);
        const day = dateSplitted[0] < 10 ? `0${dateSplitted[0]}` : dateSplitted[0];
        const month = dateSplitted[1] < 10 ? `0${dateSplitted[1]}` : dateSplitted[1];
        const year = dateSplitted[2] < 99 ? `20${dateSplitted[2]}` : dateSplitted[2];
        const ddmmyyyyValue = [day, month, year].join('/');
        return DateAdapter.convertDDMMYYYYtoDate(ddmmyyyyValue, separator);
    };

    static SLASH_CHARACTER = '/';
}

export default DateAdapter;