import React from 'react';
import TextField from '@material-ui/core/TextField/TextField';
import {sectionStyles} from '../../styles/sectionStyles';
import {withStyles} from '@material-ui/core';
import Button from '@material-ui/core/Button/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Avatar from '@material-ui/core/Avatar/Avatar';
import {getHelperTextNumericValue} from "../../utils/validationUtils";

class AccountDetails extends React.Component {
  render() {
    const {name, priorityPosition, balance, file, handleOnChangeInput, classes, handleOnChangeFile, error} = this.props;
    return (
        <form className={classes.form} noValidate autoComplete="off">
          <TextField id="name" label="Nombre" value={name} onChange={handleOnChangeInput}/>
          <TextField id="priorityPosition" label="Prioridad de Posición" value={priorityPosition} onChange={handleOnChangeInput} error={error && error.priorityPosition} helperText={getHelperTextNumericValue(error && error.priorityPosition)} />
          <TextField id="balance" label="Desajuste" value={balance} onChange={handleOnChangeInput} error={error && error.balance} helperText={getHelperTextNumericValue(error && error.balance)} />
          <div>
            {file ? <Avatar src={file} />: <span>Pulse "<CloudUploadIcon style={{margin: '0 3px', verticalAlign: 'bottom'}} /> SUBIR IMAGEN" para adjuntar la imagen.</span>}
          </div>
          <input
              accept=".jpg, .jpeg, .png"
              id="contained-button-file"
              multiple
              type="file"
              onChange={(e) => {
                const file = e.target.files[0];
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onloadend = () => {
                  handleOnChangeFile(reader.result);
                };
              }}
          />
          <label htmlFor="contained-button-file">
            <Button variant="contained" color="primary" component="span" startIcon={<CloudUploadIcon />}>
              Subir Imagen
            </Button>
          </label>
        </form>
    )
  }
}

export default withStyles(sectionStyles)(AccountDetails);