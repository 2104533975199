import {
  CHANGED_FILTER_PARAMS_SUMMARY,
  CHANGED_FILTER_PARAMS_TRANSACTION,
  CHANGED_FILTER_PARAMS_SET_TRANSACTION, RESET_FILTER_PARAMS_SET_TRANSACTION,
} from "../actions/filterActions";
import DateAdapterFactory from "../../utils/date/DateAdapterFactory";
import DateAdapter from "../../utils/date/DateAdapter";

const initialState = {
  transactions: {
    dateStart: DateAdapterFactory.createInstance(DateAdapter.getToday()).getDateFromNumberOfDaysBefore(90).getStartDayMoment().date,
    dateEnd: DateAdapterFactory.createInstance(DateAdapter.getToday()).getEndDayMoment().date,
    amountStart: '',
    amountEnd: '',
    concept: '',
    alias: '',
    category: '',
    account: '',
    showOnlyMyTransactionsWithoutGroup: false,
    showOnlyMyTransactions: false,
    ignoredToBalance: false,
    pendingReturn: false,
  },
  setTransations: {
    category: '',
    groups: [],
    checkboxCategoryChecked: false,
    checkboxGroupsChecked: false,
  },
  summary: {
    dateStart: DateAdapterFactory.createInstance(DateAdapter.getToday()).getDateFromNumberOfDaysBefore(90).getStartDayMoment().date,
    dateEnd: DateAdapterFactory.createInstance(DateAdapter.getToday()).getEndDayMoment().date,
    accountsExcluded: [],
    nestedTree: false,
  },
};

const filterReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGED_FILTER_PARAMS_TRANSACTION: {
      const params = action.payload;
      return {
        ...state,
        transactions: {...params}
      };
    }
    case CHANGED_FILTER_PARAMS_SET_TRANSACTION: {
      const params = action.payload;
      return {
        ...state,
        setTransations: {...params}
      };
    }
    case RESET_FILTER_PARAMS_SET_TRANSACTION: {
      return {
        ...state,
        setTransations: initialState.setTransations,
      };
    }
    case CHANGED_FILTER_PARAMS_SUMMARY: {
      const params = action.payload;
      return {
        ...state,
        summary: {...params}
      };
    }

    default:
      return {
        ...state
      };
  }
};

export default filterReducer;