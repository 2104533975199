import React from 'react';
import {connect} from 'react-redux';
import {Link, withRouter} from 'react-router-dom';
import {createSubTransaction, deleteSubTransaction, editSubTransaction} from '../../store/actions/transactionActions';
import Grid from '@material-ui/core/Grid/Grid';
import Header from '../layout/Header';
import {withStyles} from '@material-ui/core';
import {sectionStyles} from '../../styles/sectionStyles';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import Fab from '@material-ui/core/Fab/Fab';
import SubTransactionDetails from "./SubTransactionDetails";
import {isNumberField} from "../../utils/validationUtils";

class SubTransactionForm extends React.Component {

  state = {
    id: this.props.subTransaction && this.props.subTransaction.id ? this.props.subTransaction.id: null,
    concept: this.props.subTransaction && this.props.subTransaction.concept ? this.props.subTransaction.concept: '',
    alias: this.props.subTransaction && this.props.subTransaction.alias ? this.props.subTransaction.alias: '',
    amount: this.props.subTransaction && this.props.subTransaction.amount ? this.props.subTransaction.amount[0]: '',
    category: this.props.subTransaction && this.props.subTransaction.category ? this.props.subTransaction.category.id : '',
    comments: this.props.subTransaction && this.props.subTransaction.comments ? this.props.subTransaction.comments: '',
    ignoredToBalance: this.props.subTransaction && this.props.subTransaction.ignoredToBalance ? this.props.subTransaction.ignoredToBalance: false,
    pendingReturn: this.props.subTransaction && this.props.subTransaction.pendingReturn ? this.props.subTransaction.pendingReturn: false,
    error: null,
  };

  handleOnChangeInput = (e) => {
    e.preventDefault();
    this.setState({
      [e.target.id]: e.target.value
    });
  };

  handleOnChangeSelect = (id, selectedOption) => {
    this.setState({
      [id]: selectedOption ? selectedOption.value : ''
    });
  };

  handleOnChangeCheckbox = (id, checked) => {
    this.setState({
      [id]: checked
    });
  };

  handleOnSetInputValue = (id, value) => {
    this.setState({
      [id]: value
    });
  };

  handleOnCreateSubTransaction = (e) => {
    e.preventDefault();
    const isValidState = this.isValidStateToSave();
    if (!isValidState) {
      return;
    }
    if (!isNumberField(this.state['amount'])) {
      this.setState({
        error: {
          amount: true
        }
      });
      return;
    }
    this.setState({
      error: {
        amount: false
      }
    }, () => {
      const paramsToAdd = this.getStateFilteredToAdd();
      const subTransaction = {
        ...paramsToAdd,
        transactionId: this.props.transactionId,
      };
      const redirect = () => this.props.history.push(`/transactions/${this.props.transactionId}`);
      this.props.createSubTransaction({subTransaction, redirect});
    });
  };

  isValidStateToSave () {
    const params = ['concept', 'amount', 'category'];
    return params.every(param => this.state[param] !== '');
  };

  getStateFilteredToAdd() {
    const params = ['concept', 'alias', 'amount', 'category', 'ignoredToBalance', 'pendingReturn'];
    return this.getFilteredParams({params});
  };

  getFilteredParams({params}) {
    return params.reduce((prev, next) => {
      return {
        ...prev,
        [next]: this.state[next]
      }
    }, {})
  };

  handleOnEditTransaction = (e) => {
    e.preventDefault();
    const isValidState = this.isValidStateToSave();
    if (!isValidState) {
      return;
    }
    if (!isNumberField(this.state['amount'])) {
      this.setState({
        error: {
          amount: true
        }
      });
      return;
    }
    this.setState({
      error: {
        amount: false
      }
    }, () => {
      const paramsToEdit = this.getStateFilteredToEdit();
      const subTransaction = {
        ...paramsToEdit,
        transactionId: this.props.transactionId,
      }
      const redirect = () => this.props.history.push(`/transactions/${this.props.transactionId}`);
      this.props.editSubTransaction({subTransaction, redirect});
    });
  };

  getStateFilteredToEdit() {
    const params = ['id', 'concept', 'alias', 'amount', 'category', 'ignoredToBalance', 'pendingReturn'];
    return this.getFilteredParams({params});
  };

  handleOnDeleteTransaction = (e) => {
    e.preventDefault();
    const redirect = () => this.props.history.push(`/transactions/${this.props.transactionId}`);
    this.props.deleteSubTransaction({id: this.state.id, transactionId: this.props.transactionId, redirect});
  };

  getInfoToRender() {
    const {type} = this.props;
    if (type === 'edit') {
      return {
        title: 'EDITAR DIVISIÓN DE LA TRANSACCIÓN',
        component: <SubTransactionDetails type={type} concept={this.state.concept} alias={this.state.alias} amount={this.state.amount} ignoredToBalance={this.state.ignoredToBalance} pendingReturn={this.state.pendingReturn} category={this.state.category} comments={this.state.comments} handleOnSetInputValue={this.handleOnSetInputValue} handleOnChangeInput={this.handleOnChangeInput} handleOnChangeSelect={this.handleOnChangeSelect} handleOnChangeCheckbox={this.handleOnChangeCheckbox} error={this.state.error} />,
        buttons: [
          <Fab color="primary" aria-label="add" size={`small`} onClick={this.handleOnDeleteTransaction}><DeleteIcon /></Fab>,
          <Fab color="primary" aria-label="cancel" size={`small`} component={Link} to={`/transactions/${this.props.transactionId}`}><CloseIcon /></Fab>,
          <Fab color="primary" aria-label="save" size={`small`} onClick={this.handleOnEditTransaction}><SaveIcon /></Fab>
        ]
      }
    }

    return {
      title: 'CREAR DIVISIÓN DE LA TRANSACCIÓN',
      component: <SubTransactionDetails type={type} concept={this.state.concept} alias={this.state.alias} amount={this.state.amount} ignoredToBalance={this.state.ignoredToBalance} pendingReturn={this.state.pendingReturn} category={this.state.category} handleOnSetInputValue={this.handleOnSetInputValue} handleOnChangeInput={this.handleOnChangeInput} handleOnChangeSelect={this.handleOnChangeSelect} handleOnChangeCheckbox={this.handleOnChangeCheckbox} error={this.state.error} />,
      buttons: [
        <Fab color="primary" aria-label="cancel" size={`small`} component={Link} to={`/transactions/${this.props.transactionId}`}><CloseIcon /></Fab>,
        <Fab color="primary" aria-label="save" size={`small`} onClick={this.handleOnCreateSubTransaction}><SaveIcon /></Fab>
      ]
    };

  };

  render() {
    const {classes} = this.props;
    const {title, component, buttons} = this.getInfoToRender();
    return (
        <div className={classes.wrapperSection}>
          <Header title={title} />
          <div className={classes.wrapperSectionContent}>
            <div className={classes.appBarSpacer} />
            <Grid container className={classes.sectionContent}>
              <Grid item xs={12}>
                {component}
              </Grid>
            </Grid>
            <div className={classes.appBarSpacer} />
            <div className={classes.appBarSpacer} />
          </div>
          <div className={classes.navbarSection}>
            {buttons && buttons.map((button, index) => {
              return (
                  <span key={index}>
                    {button}
                  </span>
              )
            })}
          </div>
        </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    createSubTransaction: ({subTransaction, redirect}) => dispatch(createSubTransaction({subTransaction, redirect})),
    editSubTransaction: ({subTransaction, redirect}) => dispatch(editSubTransaction({subTransaction, redirect})),
    deleteSubTransaction: ({id, transactionId, redirect}) => dispatch(deleteSubTransaction({id, transactionId, redirect})),
  }
};

export default withRouter(connect(null, mapDispatchToProps)(withStyles(sectionStyles)(SubTransactionForm)));