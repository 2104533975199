import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {applyMiddleware, createStore} from 'redux';
import ReduxThunk from 'redux-thunk';
import rootReducer from './store/reducers/rootReducer';
import {Provider} from 'react-redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import Api from './services/Api';

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(ReduxThunk)));
Api.create({store}, () => {
  ReactDOM.render(
      <Provider store={store}>
        <App />
      </Provider>
      , document.getElementById('root'));

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.unregister();
});
