import React from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import Navbar from './components/layout/Navbar';
import Toast from './components/layout/Toast';
import Categories from './components/categories/Categories';
import CssBaseline from '@material-ui/core/CssBaseline/CssBaseline';

import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import {purple, teal} from '@material-ui/core/colors';
import SignIn from './components/signIn/SignIn';
import AccountEdit from './components/accounts/AccountEdit';
import Accounts from './components/accounts/Accounts';
import AccountCreate from './components/accounts/AccountCreate';
import Transactions from './components/transactions/Transactions';
import TransactionCreate from './components/transactions/TransactionCreate';
import TransactionUpload from './components/transactions/TransactionUpload';
import TransactionEdit from './components/transactions/TransactionEdit';
import Summary from './components/summary/Summary';
import Register from "./components/register/Register";
import SubTransactionCreate from "./components/transactions/SubTransactionCreate";
import SubTransactionEdit from "./components/transactions/SubTransactionEdit";
import AccountAssessment from "./components/accounts/AccountAssessment";
import Groups from "./components/groups/Groups";
import GroupCreate from "./components/groups/GroupCreate";
import GroupEdit from "./components/groups/GroupEdit";

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    position: 'fixed',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapperGlobal: {
    flexGrow: 1,
    height: '100%',
    overflow: 'hidden',
    position: 'relative',
    zIndex: theme.zIndex.drawer + 1,
  }
}));

const theme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      light: teal[100],
      main: teal[200],
      dark: teal[300]
    },
    secondary: {
      light: purple[100],
      main: purple[200],
      dark: purple[300]
    }
  }
});

function App() {
  const classes = useStyles();
  return (
      <ThemeProvider theme={theme}>
        <div className={classes.root}>
          <CssBaseline />
          <BrowserRouter>
              <main className={classes.wrapperGlobal}>
                <Switch>
                  <Route exact path='/signin' component={SignIn} />
                  <Route exact path='/register' component={Register} />
                  <Route exact path='/' component={SignIn} />
                  <Route exact path='/groups' component={Groups} />
                  <Route exact path='/groups/create' component={GroupCreate} />
                  <Route exact path='/groups/:id' component={GroupEdit} />
                  <Route exact path='/accounts' component={Accounts} />
                  <Route exact path='/accounts/summary' component={AccountAssessment} />
                  <Route exact path='/accounts/create' component={AccountCreate} />
                  <Route exact path='/accounts/:id' component={AccountEdit} />
                  <Route exact path='/categories' component={Categories} />
                  <Route exact path='/transactions' component={Transactions} />
                  <Route exact path='/transactions/create' component={TransactionCreate} />
                  <Route exact path='/transactions/upload' component={TransactionUpload} />
                  <Route exact path='/transactions/:id/subtransactions/create' component={SubTransactionCreate} />
                  <Route exact path='/transactions/:id/subtransactions/:subId' component={SubTransactionEdit} />
                  <Route exact path='/transactions/:id' component={TransactionEdit} />
                  <Route exact path='/summary' component={Summary} />
                </Switch>
              </main>
              <Navbar />
              <Toast />
          </BrowserRouter>
        </div>
      </ThemeProvider>
  )
}

export default App;
