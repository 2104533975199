import React from 'react';
import Grid from '@material-ui/core/Grid/Grid';
import SubTransactionSummary from "./SubTransactionSummary";
import {withStyles} from "@material-ui/core";
import {sectionStyles} from "../../styles/sectionStyles";
class SubTransactionList extends React.Component {

  getMessageTransactionAmountIsNotEqualToSumSubTransactionsAmount() {
    const {classes, transactionAmount, subTransactionsTotalAmount} = this.props;
    if (subTransactionsTotalAmount === transactionAmount) {
      return <p style={{'margin': 0}}> Suma Divisiones <span className={classes.green}>=</span> Total Transacción</p>
    }
    return <p style={{'margin': 0}}> Suma Divisiones <span className={classes.red}>≠</span> Total Transacción</p>
  }

  render() {
    const {subTransactions, transactionId} = this.props;
    const messageTransactionAmountIsNotEqualToSumSubTransactionsAmount = this.getMessageTransactionAmountIsNotEqualToSumSubTransactionsAmount()
    return (
        <>
          <div style={{marginTop: '30px'}}>
            <span>DIVISIONES DE LA TRANSACCIÓN:</span>
            {messageTransactionAmountIsNotEqualToSumSubTransactionsAmount}
          </div>

          <Grid container>
            {subTransactions.map(subTransaction => <SubTransactionSummary key={subTransaction.id} transactionId={transactionId} subTransaction={subTransaction}/>)}
          </Grid>
        </>
    )
  }
}

export default withStyles(sectionStyles)(SubTransactionList);