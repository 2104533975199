import React from 'react';
import {connect} from 'react-redux';
import {createCategory, deleteCategory, editCategory} from '../../store/actions/categoryActions';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton/IconButton';
import SaveIcon from '@material-ui/icons/Save';
import TextField from '@material-ui/core/TextField/TextField';
import {sectionStyles} from '../../styles/sectionStyles';
import {withStyles} from '@material-ui/core';

class CategoryForm extends React.Component {

  state = {
    name: this.props.node && this.props.node.name ? this.props.node.name : '',
  };

  handleOnChange = (e) => {
    e.preventDefault();
    this.setState({
      [e.target.id]: e.target.value
    });
  };

  handleOnClickSave = (e) => {
    e.preventDefault();
    const isValidState = this.isValidStateToSave();
    if (!isValidState) {
      return;
    }
    if (this.props.node) {
      this.props.editCategory({
        id: this.props.node.id,
        name: this.state.name,
      });
      if (!this.props.handleSuccess) {
        return;
      }
      return this.props.handleSuccess();
    }

    this.props.createCategory({
      name: this.state.name,
      parentId: this.props.parentId ? this.props.parentId : null
    });
    this.setStateDefault();
    if (!this.props.handleSuccess) {
      return;
    }
    return this.props.handleSuccess();
  };

  setStateDefault() {
    this.setState({name: ''});
  }

  handleOnClickDelete = (e) => {
    e.preventDefault();
    this.props.deleteCategory(this.props.node.id);
  };

  isValidStateToSave() {
    const params = ['name'];
    return params.every(param => this.state[param] !== '');
  };

  render() {
    const {classes, handleOnClickCancel, isInputFullWidth} = this.props;
    const deleteButton = this.props.node ? <IconButton size="small" aria-label="trash" onClick={this.handleOnClickDelete}><DeleteIcon /></IconButton> : null;
    const cancelButton = handleOnClickCancel ? <IconButton size="small" aria-label="cancel" onClick={handleOnClickCancel}><CloseIcon /></IconButton> : null;
    const classInputFullWidth = isInputFullWidth ? 'is-fullwidth' : '';
    return (
        <form className={classes.formCategories}>
          <TextField className={classInputFullWidth} size="small" variant="outlined" id="name" label="Nombre" value={this.state.name} onChange={this.handleOnChange}/>
          {cancelButton}
          {deleteButton}
          <IconButton className={classes.iconSiblingToTextField} size="small" aria-label="save" onClick={this.handleOnClickSave}><SaveIcon /></IconButton>
        </form>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    createCategory: (category) => dispatch(createCategory(category)),
    editCategory: (category) => dispatch(editCategory(category)),
    deleteCategory: (id) => dispatch(deleteCategory(id))
  }
};

export default connect(null, mapDispatchToProps)(withStyles(sectionStyles)(CategoryForm));