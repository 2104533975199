import React from 'react';
import {connect} from 'react-redux';
import AccountDetails from './AccountDetails';
import {createAccount, deleteAccount, editAccount} from '../../store/actions/accountActions';
import {Link, withRouter} from 'react-router-dom';
import Grid from '@material-ui/core/Grid/Grid';
import {sectionStyles} from '../../styles/sectionStyles';
import {withStyles} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import Fab from '@material-ui/core/Fab/Fab';
import Header from '../layout/Header';
import {isNumberField} from "../../utils/validationUtils";

class AccountForm extends React.Component {

  state = {
    id: this.props.account && this.props.account.id ? this.props.account.id: null,
    name: this.props.account && this.props.account.name ? this.props.account.name: '',
    priorityPosition: this.props.account && this.props.account.priorityPosition ? this.props.account.priorityPosition: '',
    balance: this.props.account && this.props.account.balance ? this.props.account.balance: '',
    file: this.props.account && this.props.account.file ? this.props.account.file: null,
    error: null,
  };

  handleOnChangeFile = (url) => {
    this.setState({file: url});
  };

  handleOnCreateAccount = (e) => {
    e.preventDefault();
    const isValidState = this.isValidStateToSave();
    if (!isValidState) {
      return;
    }
    if (!isNumberField(this.state['balance'])) {
      this.setState({
        error: {
          balance: true
        }
      });
      return;
    }
    this.setState({
      error: {
        balance: false
      }
    }, () => {
      const paramsToAdd = this.getStateFilteredToAdd();
      const redirect = () => this.props.history.push('/accounts');
      this.props.createAccount({account: paramsToAdd, redirect});
    });
  };

  handleOnEditAccount = (e) => {
    e.preventDefault();
    const isValidState = this.isValidStateToSave();
    if (!isValidState) {
      return;
    }
    if (!isNumberField(this.state['balance'])) {
      this.setState({
        error: {
          balance: true
        }
      });
      return;
    }
    this.setState({
      error: {
        balance: false
      }
    }, () => {
      const paramsToEdit = this.getStateFilteredToEdit();
      const redirect = () => this.props.history.push('/accounts');
      this.props.editAccount({account: paramsToEdit, redirect});
    });
  };

  isValidStateToSave () {
    const params = ['name', 'priorityPosition', 'balance', 'file'];
    return params.every(param => this.state[param] !== '');
  };

  getStateFilteredToAdd() {
    const params = ['name', 'priorityPosition', 'balance', 'file'];
    return this.getFilteredParams({params});
  };

  getStateFilteredToEdit() {
    const params = ['id', 'priorityPosition', 'name', 'balance', 'file'];
    return this.getFilteredParams({params});
  };

  getFilteredParams({params}) {
    return params.reduce((prev, next) => {
      return {
        ...prev,
        [next]: this.state[next]
      }
    }, {})
  };

  handleOnDeleteAccount = (e) => {
    e.preventDefault();
    const redirect = () => this.props.history.push('/accounts');
    this.props.deleteAccount({id: this.state.id, redirect});
  };

  handleOnChangeInput = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };

  handleOnSetInputValue = (id, value) => {
    this.setState({
      [id]: value
    });
  };

  getInfoToRender() {
    const {account} = this.props;
    if (account) {
      return {
        title: 'Editar Cuenta',
        component: <AccountDetails name={this.state.name} priorityPosition={this.state.priorityPosition} balance={this.state.balance} file={this.state.file} handleOnChangeInput={this.handleOnChangeInput} handleOnSetInputValue={this.handleOnSetInputValue} handleOnChangeFile={this.handleOnChangeFile} error={this.state.error} />,
        buttons: [
          <Fab color={`primary`} aria-label="add" size={`small`} onClick={this.handleOnDeleteAccount}><DeleteIcon /></Fab>,
          <Fab color={`primary`} aria-label="cancel" size={`small`} component={Link} to={`/accounts`}><CloseIcon /></Fab>,
          <Fab color={`primary`} aria-label="save" size={`small`} onClick={this.handleOnEditAccount}><SaveIcon /></Fab>,
        ]
      }
    }
    return {
      title: 'Crear Cuenta',
      component: <AccountDetails name={this.state.name} priorityPosition={this.state.priorityPosition} balance={this.state.balance} file={this.state.file} handleOnChangeInput={this.handleOnChangeInput} handleOnSetInputValue={this.handleOnSetInputValue} handleOnChangeFile={this.handleOnChangeFile} error={this.state.error}/>,
      buttons: [
        <Fab color={`primary`} aria-label="add" component={Link} to={`/accounts`} size={`small`}><CloseIcon /></Fab>,
        <Fab color={`primary`} aria-label="add" size={`small`} onClick={this.handleOnCreateAccount}><SaveIcon /></Fab>
      ]
    }
  };

  render() {
    const {classes} = this.props;
    const {title, component, buttons} = this.getInfoToRender();
    return (
        <div className={classes.wrapperSection}>
          <Header title={title} />
          <div className={classes.wrapperSectionContent}>
            <div className={classes.appBarSpacer} />
            <Grid container className={classes.sectionContent}>
              <Grid item xs={12}>
                {component}
              </Grid>
            </Grid>
            <div className={classes.appBarSpacer} />
            <div className={classes.appBarSpacer} />
          </div>
          <div className={classes.navbarSection}>
            {buttons && buttons.map((button, index) => {
              return (
                <span key={index}>
                  {button}
                </span>
              )
            })}
          </div>
        </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    createAccount: ({account, redirect}) => dispatch(createAccount({account, redirect})),
    editAccount: ({account, redirect}) => dispatch(editAccount({account, redirect})),
    deleteAccount: ({id, redirect}) => dispatch(deleteAccount({id, redirect}))
  }
};

export default withRouter(connect(null, mapDispatchToProps)(withStyles(sectionStyles)(AccountForm)));