import React from 'react';
import Grid from '@material-ui/core/Grid/Grid';
import {withStyles} from '@material-ui/core';
import {sectionStyles} from '../../styles/sectionStyles';
import Header from '../layout/Header';
import TransactionsListGroupedByDate from './TransactionsListGroupedByDate';
import {compose} from 'redux';
import connect from 'react-redux/es/connect/connect';
import {Redirect} from 'react-router-dom';

class AccountAssessment extends React.Component {

  componentDidMount() {
    if (!this.props.auth.accessToken) {
      return;
    }
  }

  render() {

    const {classes, auth} = this.props;

    if (!auth.accessToken) {
      return <Redirect to='/signin' />
    }

    const title = `Balance`;
    const headerInfo = {
      value: ``,
      classes: null
    };

    return (
      <div className={classes.wrapperSection}>
        <Header title={title} headerInfo={headerInfo}/>
        <div className={classes.wrapperSectionContent}>
          <div className={classes.appBarSpacer} />
          <Grid container className={classes.sectionContent}>
            <Grid item xs={12}>
              <TransactionsListGroupedByDate/>
            </Grid>
          </Grid>
          <div className={classes.appBarSpacer} />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default compose(
  connect(mapStateToProps),
  withStyles(sectionStyles)
)(AccountAssessment);

