import React from 'react';
import AccountSummary from './AccountSummary';
import Grid from '@material-ui/core/Grid/Grid';
import LinearProgress from '@material-ui/core/LinearProgress/LinearProgress';
import {connect} from "react-redux";


class AccountList extends React.Component {
  render() {
    const {accounts, isFetching, error} = this.props.accountList;
    if (isFetching) {
      return (
          <LinearProgress />
      )
    }

    if (error) {
      return (
          <p>{error}</p>
      )
    }

    if (accounts.length === 0) {
      return (
          <p>No hay cuentas creadas</p>
      )
    }

    return (
        <Grid container>
          {
            accounts && accounts.map(account => {
              return(
                  <AccountSummary key={account.id} account={account} />
              )
            })
          }
        </Grid>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    accountList: state.account.accountList
  };
};

export default connect(mapStateToProps)(AccountList);