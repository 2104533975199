export const TOAST_OPENED = 'TOAST_OPEN';
export const TOAST_CLOSED = 'TOAST_CLOSED';

export function closeToast() {
    return {
        type: TOAST_CLOSED
    };
}

export function openToast(params) {
    return {
        type: TOAST_OPENED,
        payload: params
    };
}