import React from 'react';
import AccountList from './AccountList';
import Grid from '@material-ui/core/Grid/Grid';
import {withStyles} from '@material-ui/core';
import Fab from '@material-ui/core/Fab/Fab';
import {Link, Redirect} from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import AssessmentIcon from '@material-ui/icons/Assessment';
import {sectionStyles} from '../../styles/sectionStyles';
import Header from '../layout/Header';
import {compose} from 'redux';
import {connect} from "react-redux";
import {addCurrency, getTextColorClass} from '../../utils/currencyUtils';
import {fetchAccounts} from '../../store/actions/accountActions';
import LinearProgress from '@material-ui/core/LinearProgress';

class Accounts extends React.Component {
  componentDidMount() {
    if (!this.props.auth.accessToken) {
      return;
    }
    this.props.fetchAccounts();
  }

  render() {
    const title = `Cuentas`;
    const {classes, auth} = this.props;

    if (!auth.accessToken) {
      return <Redirect to='/signin' />
    }

    const {value, isFetching, error} = this.props.totalBalance;
    if (isFetching) {
      return (
          <LinearProgress />
      )
    }

    if (error) {
      return (
          <p>{error}</p>
      )
    }

    const headerInfo = {
      value: addCurrency(value[0]),
      classes: getTextColorClass(classes, value[0])
    };

    return (
      <div className={classes.wrapperSection}>
        <Header title={title} headerInfo={headerInfo}/>
        <div className={classes.wrapperSectionContent}>
          <div className={classes.appBarSpacer} />
          <Grid container className={classes.sectionContent}>
            <Grid item xs={12}>
              <AccountList/>
            </Grid>
          </Grid>
          <div className={classes.appBarSpacer} />
          <div className={classes.appBarSpacer} />
        </div>
        <div className={classes.navbarSection}>
          <Fab color={`primary`} aria-label="add" component={Link} to={`/accounts/summary`} size={`small`}>
            <AssessmentIcon />
          </Fab>
          <Fab color={`primary`} aria-label="add" component={Link} to={`/accounts/create`} size={`small`}>
            <AddIcon />
          </Fab>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    totalBalance: state.account.totalBalance
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchAccounts: () => dispatch(fetchAccounts())
  }
};

export default compose(
    connect(mapStateToProps,mapDispatchToProps),
    withStyles(sectionStyles)
)(Accounts);

