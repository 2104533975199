import React from 'react';
import Category from './Category';

class CategoriesTree extends React.Component {
  render() {
    const {node, depth, handleOnClickDropDown, collapsedCategories} = this.props;
    return node && node.map((elem) => {
      const isCollapsed = collapsedCategories.includes(elem.id);
      const children = elem.children && elem.children.length > 0 ?
          <CategoriesTree node={elem.children} depth={depth + 1} handleOnClickDropDown={handleOnClickDropDown} collapsedCategories={collapsedCategories}/> : null;
      const collapsedClass = isCollapsed ? 'd-none': '';
      return (
          <li key={elem.id}>
            <Category node={elem}
                      isDropDownable={children}
                      handleOnClickDropDown={(e) => {
                        e.preventDefault();
                        handleOnClickDropDown(elem.id, isCollapsed);
                      }}
                      isCollapsed={isCollapsed}
            />
            { children ? (
                <ul className={`${collapsedClass}`}>
                  {children}
                </ul>
              ) : null
            }
          </li>
      )
    });
  }
}

export default CategoriesTree;