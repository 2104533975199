import Api from '../../services/Api';
import {serializeObjectToUrlQueryParameters} from '../../utils/paramsUtils';

//Account list
export const FETCH_CATEGORIES = 'FETCH_CATEGORIES';
export const FETCH_CATEGORIES_SUCCESS = 'FETCH_CATEGORIES_SUCCESS';
export const FETCH_CATEGORIES_FAILURE = 'FETCH_CATEGORIES_FAILURE';
export const RESET_CATEGORIES = 'RESET_CATEGORIES';

export const FETCH_CATEGORIES_WITH_TRANSACTIONS = 'FETCH_CATEGORIES_WITH_TRANSACTIONS';
export const FETCH_CATEGORIES_WITH_TRANSACTIONS_SUCCESS = 'FETCH_CATEGORIES_WITH_TRANSACTIONS_SUCCESS';
export const FETCH_CATEGORIES_WITH_TRANSACTIONS_FAILURE = 'FETCH_CATEGORIES_WITH_TRANSACTIONS_FAILURE';
export const RESET_CATEGORIES_WITH_TRANSACTIONS = 'RESET_CATEGORIES_WITH_TRANSACTIONS';

//Create new category
export const CREATE_CATEGORY = 'CREATE_CATEGORY';
export const CREATE_CATEGORY_SUCCESS = 'CREATE_CATEGORY_SUCCESS';
export const CREATE_CATEGORY_FAILURE = 'CREATE_CATEGORY_FAILURE';
export const RESET_NEW_CATEGORY = 'RESET_NEW_CATEGORY';

//Fetch category
export const FETCH_CATEGORY = 'FETCH_CATEGORY';
export const FETCH_CATEGORY_SUCCESS = 'FETCH_CATEGORY_SUCCESS';
export const FETCH_CATEGORY_FAILURE = 'FETCH_CATEGORY_FAILURE';
export const RESET_ACTIVE_CATEGORY = 'RESET_ACTIVE_CATEGORY';

//Update category
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY';
export const UPDATE_CATEGORY_SUCCESS = 'UPDATE_CATEGORY_SUCCESS';
export const UPDATE_CATEGORY_FAILURE = 'UPDATE_CATEGORY_FAILURE';

//Delete category
export const DELETE_CATEGORY = 'DELETE_CATEGORY';
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS';
export const DELETE_CATEGORY_FAILURE = 'DELETE_CATEGORY_FAILURE';
export const RESET_DELETED_CATEGORY = 'RESET_DELETED_CATEGORY';

export function fetchCategories(data = {}) {
  return (dispatch) => {
    const queryParameters = serializeObjectToUrlQueryParameters(data);
    const request = Api.getInstance().get(`/categories?${queryParameters}`);
    dispatch({
      type: FETCH_CATEGORIES
    });
    request
        .then(response => {
          !response.error ? dispatch(fetchCategoriesSuccess(response.data)) : dispatch(fetchCategoriesFailure(response.data));
        })
        .catch(error => {
          console.log(error);
        })
  }
}

export function fetchCategoriesSuccess(categories) {
  return {
    type: FETCH_CATEGORIES_SUCCESS,
    payload: categories
  };
}

export function fetchCategoriesFailure(error) {
  return {
    type: FETCH_CATEGORIES_FAILURE,
    payload: error
  };
}

export function resetCategories() {
  return {
    type: RESET_CATEGORIES
  }
}

export function fetchCategoriesWithTransactions(data = {}) {
  return (dispatch) => {
    const queryParameters = serializeObjectToUrlQueryParameters(data);
    const request = Api.getInstance().get(`/categories?${queryParameters}`);
    dispatch({
      type: FETCH_CATEGORIES_WITH_TRANSACTIONS
    });
    request
        .then(response => {
          !response.error ? dispatch(fetchCategoriesWithTransactionsSuccess(response.data)) : dispatch(fetchCategoriesWithTransactionsFailure(response.data));
        })
        .catch(error => {
          console.log(error);
        })
  }
}

export function fetchCategoriesWithTransactionsSuccess(categories) {
  return {
    type: FETCH_CATEGORIES_WITH_TRANSACTIONS_SUCCESS,
    payload: categories
  };
}

export function fetchCategoriesWithTransactionsFailure(error) {
  return {
    type: FETCH_CATEGORIES_WITH_TRANSACTIONS_FAILURE,
    payload: error
  };
}

export function resetCategoriesWithTransactions() {
  return {
    type: RESET_CATEGORIES_WITH_TRANSACTIONS
  }
}

export function editCategory(category) {
  return (dispatch) => {
    const {id, ...params} = category;
    const request = Api.getInstance().put(`/categories/${id}`, {
      ...params
    });
    // dispatch({
    //   type: UPDATE_CATEGORY
    // });

    request.then(response => {
      !response.error ? dispatch(editCategorySuccess(response.data)) : dispatch(editCategoryFailure(response.data));
    })
  }
}

export function editCategorySuccess(posts) {
  return {
    type: UPDATE_CATEGORY_SUCCESS,
    payload: posts
  };
}

export function editCategoryFailure(error) {
  return {
    type: UPDATE_CATEGORY_FAILURE,
    payload: error
  };
}

export function createCategory(category) {
  return (dispatch) => {
    const request = Api.getInstance().post('/categories', {
      ...category,
      collapsed: false
    });
    dispatch({
      type: CREATE_CATEGORY
    });

    request.then(response => {
      !response.error ? dispatch(createCategorySuccess(response.data)) : dispatch(createCategoryFailure(response.data));
    })
  }
}

export function createCategorySuccess(posts) {
  return {
    type: CREATE_CATEGORY_SUCCESS,
    payload: posts
  };
}

export function createCategoryFailure(error) {
  return {
    type: CREATE_CATEGORY_FAILURE,
    payload: error
  };
}

export function resetActiveCategory() {
  return {
    type: RESET_ACTIVE_CATEGORY
  }
}

export function resetNewCategory() {
  return {
    type: RESET_NEW_CATEGORY
  }
}

export function resetDeletedCategory() {
  return {
    type: RESET_DELETED_CATEGORY
  }
}


export function fetchCategory(id) {
  return (dispatch) => {
    const request = Api.getInstance().get(`/transactions/${id}`);
    dispatch({
      type: FETCH_CATEGORY
    });

    request.then(response => {
      !response.error ? dispatch(fetchCategorySuccess(response.data)) : dispatch(fetchCategoryFailure(response.data));
    })
  }
}

export function fetchCategorySuccess(msg) {
  return {
    type: FETCH_CATEGORY_SUCCESS,
    payload: msg
  };
}

export function fetchCategoryFailure(error) {
  return {
    type: FETCH_CATEGORY_FAILURE,
    payload: error
  };
}

export function deleteCategory(id) {
  return (dispatch) => {
    const request = Api.getInstance().delete(`/categories/${id}`);
    dispatch({
      type: DELETE_CATEGORY
    });

    request.then(response => {
      if (response.error) {
        return dispatch(deleteCategoryFailure(response.data))
      }
      dispatch(deleteCategorySuccess(response.data));
      dispatch(fetchCategories());
    })
  }
}

export function deleteCategorySuccess(msg) {
  return {
    type: DELETE_CATEGORY_SUCCESS,
    payload: msg
  };
}

export function deleteCategoryFailure(error) {
  return {
    type: DELETE_CATEGORY_FAILURE,
    payload: error
  };
}