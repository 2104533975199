import React from 'react';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogContentText
  from '@material-ui/core/DialogContentText/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import Button from '@material-ui/core/Button/Button';
import Dialog from '@material-ui/core/Dialog/Dialog';
import {sectionStyles} from '../../styles/sectionStyles';
import {withStyles} from '@material-ui/core';
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

class LabelsModal extends React.Component {
  handleOnChangeInput = (e) => {
    e.preventDefault();
    const {handleOnChangeInput} = this.props;
    handleOnChangeInput(e.target.value);
  };

  render() {
    const {open, handleSetLabels, handleClose, handleReset, classes, labels} = this.props;

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogContent>
            <DialogContentText>
              Etiquetas
            </DialogContentText>
            <form className={classes.form} noValidate autoComplete="off">
              <TextareaAutosize id="labels" aria-label="labels textarea" placeholder="Etiqueta1, Etiqueta2, Etiqueta 3..." onChange={this.handleOnChangeInput} value={labels} />
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cerrar
            </Button>
            <Button onClick={handleReset} color="primary">
              Reset
            </Button>
            <Button onClick={handleSetLabels} color="primary">
              Guardar
            </Button>
          </DialogActions>
        </Dialog>
    );
  }
}

export default withStyles(sectionStyles)(LabelsModal);