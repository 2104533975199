export const addCurrency = (value) => {
  return `${value} €`
};

export const getTextColorClass = (classes, value) => {

  if (value > 0) {
    return classes.green;
  }

  return classes.red;
}