import React from 'react';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogContentText
  from '@material-ui/core/DialogContentText/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import Button from '@material-ui/core/Button/Button';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DateFnsUtils from '@date-io/date-fns';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import TextField from '@material-ui/core/TextField/TextField';
import {sectionStyles} from '../../styles/sectionStyles';
import {withStyles} from '@material-ui/core';
import {compose} from 'redux';
import connect from 'react-redux/es/connect/connect';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LinearProgress from '@material-ui/core/LinearProgress/LinearProgress';
import Fab from "@material-ui/core/Fab/Fab";
import AllInclusiveIcon from "@material-ui/icons/AllInclusive";
import DateAdapterFactory from "../../utils/date/DateAdapterFactory";
import DateAdapter from "../../utils/date/DateAdapter";
import Avatar from "@material-ui/core/Avatar/Avatar";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";

class FilterModal extends React.Component {
  state = {
    dateStart: this.props.dateStart,
    dateEnd: this.props.dateEnd,
    amountStart: this.props.amountStart,
    amountEnd: this.props.amountEnd,
    concept: this.props.concept,
    alias: this.props.alias,
    category: this.props.category,
    account: this.props.account,
    showOnlyMyTransactionsWithoutGroup: this.props.showOnlyMyTransactionsWithoutGroup,
    showOnlyMyTransactions: this.props.showOnlyMyTransactions,
    ignoredToBalance: this.props.ignoredToBalance,
    pendingReturn: this.props.pendingReturn,
  };

  handleOnChangeCheckbox = (id, checked) => {
    this.setState({
      [id]: checked
    });
  };

  handleOnChangeInput = (e) => {
    e.preventDefault();
    this.setState({
      [e.target.id]: e.target.value
    });
  };

  handleChangeDate = (id, date) => {
    return this.setState({
      [id]: date
    });
  };

  handleOnChangeSelect = (id, selectedOption) => {
    this.setState({
      [id]: selectedOption ? selectedOption.value : ''
    });
  };

  handleClose = () => {
    this.setState({
      dateStart: this.props.dateStart,
      dateEnd: this.props.dateEnd,
      amountStart: this.props.amountStart,
      amountEnd: this.props.amountEnd,
      concept: this.props.concept,
      alias: this.props.alias,
      category: this.props.category,
      account: this.props.account,
      showOnlyMyTransactionsWithoutGroup: this.props.showOnlyMyTransactionsWithoutGroup,
      showOnlyMyTransactions: this.props.showOnlyMyTransactions,
      ignoredToBalance: this.props.ignoredToBalance,
      pendingReturn: this.props.pendingReturn,
    }, () => {
      this.props.handleClose();
    });
  };

  handleReset = () => {
    this.setState({
      dateStart: DateAdapterFactory.createInstance(DateAdapter.getToday()).getDateFromNumberOfDaysBefore(365).getStartDayMoment().date,
      dateEnd: DateAdapterFactory.createInstance(DateAdapter.getToday()).getEndDayMoment().date,
      amountStart: '',
      amountEnd: '',
      concept: '',
      alias: '',
      category: '',
      account: '',
      showOnlyMyTransactionsWithoutGroup: false,
      showOnlyMyTransactions: false,
      ignoredToBalance: false,
      pendingReturn: false,
    }, () => {
      this.props.handleClose();
      this.props.handleSetFilter(this.state);
    });
  };

  handleSetTimeCountingDaysBefore(numberOfDays) {
    this.setState({
      dateStart: DateAdapterFactory.createInstance(DateAdapter.getToday()).getDateFromNumberOfDaysBefore(numberOfDays).getStartDayMoment().date,
      dateEnd: DateAdapterFactory.createInstance(DateAdapter.getToday()).getEndDayMoment().date,
    });
  };

  handleSetTimeFromBegining() {
    this.setState({
      dateStart: DateAdapterFactory.createInstance(DateAdapter.getDateFromUnixBirthday()).getStartDayMoment().date,
      dateEnd: DateAdapterFactory.createInstance(DateAdapter.getToday()).getEndDayMoment().date,
    });
  };

  render() {
    const {open, handleClose, handleSetFilter, classes, categoryList, accountList} = this.props;
    const {categories, isFetching: isFetchingCategories, error: errorCategories} = categoryList;
    const {accounts, isFetching: isFetchingAccounts, error: errorAccounts} = accountList;

    if (isFetchingCategories || errorCategories || isFetchingAccounts || errorAccounts) {
      return (
          <LinearProgress />
      )
    }

    const mapAccountsToSelect = accounts.map(elem => {
      return {
        title: elem.name,
        value: elem.id,
        file: elem.file,
      }
    });

    const accountValue = mapAccountsToSelect.find(elem => elem.value === this.state.account) || '';

    const mapCategoriesToSelect = categories.map(elem => {
      return {
        title: elem.name,
        value: elem.id
      }
    });
    mapCategoriesToSelect.unshift({title: 'Sin Categorizar', value: 'null'});

    const categoryValue = mapCategoriesToSelect.find(elem => elem.value === this.state.category) || '';

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          {/*<DialogTitle id="form-dialog-title">Subscribe</DialogTitle>*/}
          <DialogContent>
            <DialogContentText>
              Búsqueda de Transacciones
            </DialogContentText>
            <form className={classes.form} noValidate autoComplete="off">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                    margin="normal"
                    id="dateStart"
                    label="Fecha Desde"
                    format="dd/MM/yyyy"
                    value={this.state.dateStart}
                    onChange={(date) => this.handleChangeDate("dateStart", date)}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                />
              </MuiPickersUtilsProvider>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                    margin="normal"
                    id="dateEnd"
                    label="Fecha Hasta"
                    format="dd/MM/yyyy"
                    value={this.state.dateEnd}
                    onChange={(date) => this.handleChangeDate("dateEnd", date)}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                />
              </MuiPickersUtilsProvider>
              <DialogContentText style={{margin: '10px 0 0 0'}}>
                Número de Días hasta Hoy
              </DialogContentText>
              <div className={classes.filterButtons}>
                <Fab color={`primary`} aria-label="add" component={Button} onClick={() => this.handleSetTimeCountingDaysBefore(7)} size={`small`}>
                  7
                </Fab>
                <Fab color={`primary`} aria-label="add" component={Button} onClick={() => this.handleSetTimeCountingDaysBefore(30)} size={`small`}>
                  30
                </Fab>
                <Fab color={`primary`} aria-label="add" component={Button} onClick={() => this.handleSetTimeCountingDaysBefore(90)} size={`small`}>
                  90
                </Fab>
                <Fab color={`primary`} aria-label="add" component={Button} onClick={() => this.handleSetTimeCountingDaysBefore(365)} size={`small`}>
                  365
                </Fab>
                <Fab color={`primary`} aria-label="add" component={Button} onClick={() => this.handleSetTimeFromBegining()} size={`small`}>
                  <AllInclusiveIcon />
                </Fab>
              </div>
              <Autocomplete
                  id="account"
                  options={mapAccountsToSelect}
                  getOptionLabel={option => (option.title ? option.title : "")}
                  className={classes.autocomplete}
                  renderInput={params => (
                      <TextField {...params} label="Cuentas" fullWidth />
                  )}
                  renderOption={option => {
                    return (
                      <>
                        <Avatar src={option.file} className={classes.accountAvatar}/>
                        <Typography variant="inherit" noWrap>
                          {option.title ? option.title : ''}
                        </Typography>
                      </>
                    );
                  }}
                  onChange={(event, value) => this.handleOnChangeSelect(`account`, value)}
                  value={accountValue}
              />
              <Autocomplete
                  id="category"
                  options={mapCategoriesToSelect}
                  getOptionLabel={option => (option.title ? option.title : "")}
                  className={classes.autocomplete}
                  renderInput={params => (
                      <TextField {...params} label="Categorías" fullWidth />
                  )}
                  onChange={(event, value) => this.handleOnChangeSelect(`category`, value)}
                  value={categoryValue}
              />
              <TextField id="amountStart" label="Importe Desde" value={this.state.amountStart} onChange={this.handleOnChangeInput}/>
              <TextField id="amountEnd" label="Importe Hasta" value={this.state.amountEnd} onChange={this.handleOnChangeInput}/>
              <TextField id="concept" label="Concepto" value={this.state.concept} onChange={this.handleOnChangeInput}/>
              <TextField id="alias" label="Alias" value={this.state.alias} onChange={this.handleOnChangeInput}/>
              <FormControlLabel
                control={
                  <Checkbox checked={this.state.showOnlyMyTransactionsWithoutGroup} onChange={(e) => this.handleOnChangeCheckbox('showOnlyMyTransactionsWithoutGroup', e.target.checked)} value="checkedA" />
                }
                label="Sólo mis Transacciones sin grupo"
              />
              <FormControlLabel
                control={
                  <Checkbox checked={this.state.showOnlyMyTransactions} onChange={(e) => this.handleOnChangeCheckbox('showOnlyMyTransactions', e.target.checked)} value="checkedA" />
                }
                label="Sólo mis Transacciones"
              />
              <FormControlLabel
                  control={
                    <Checkbox checked={this.state.ignoredToBalance} onChange={(e) => this.handleOnChangeCheckbox('ignoredToBalance', e.target.checked)} value="checkedA" />
                  }
                  label="Sólo Ignoraras de balance"
              />
              <FormControlLabel
                  control={
                    <Checkbox checked={this.state.pendingReturn} onChange={(e) => this.handleOnChangeCheckbox('pendingReturn', e.target.checked)} value="checkedA" />
                  }
                  label="Sólo Pendientes de devolución"
              />
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancelar
            </Button>
            <Button onClick={this.handleReset} color="primary">
              Reset
            </Button>
            <Button onClick={() => handleSetFilter(this.state)} color="primary">
              Buscar
            </Button>
          </DialogActions>
        </Dialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    categoryList: state.category.categoryList,
    accountList: state.account.accountList
  };
};

export default compose(
    connect(mapStateToProps),
    withStyles(sectionStyles)
)(FilterModal);