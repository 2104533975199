import React from 'react';

class DotLoader extends React.Component {
  render() {
    return (
      <span className={'loading'}>
        <span className={'dot'}>.</span>
        <span className={'dot'}>.</span>
        <span className={'dot'}>.</span>
      </span>
    )
  }
}

export default DotLoader;

