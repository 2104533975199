export const RESET_FILTER_PARAMS_SET_TRANSACTION = 'RESET_FILTER_PARAMS_SET_TRANSACTION';
export const FILTER_PARAMS_SET_TRANSACTION = 'FILTER_PARAMS_SET_TRANSACTION';
export const FILTER_PARAMS_TRANSACTION = 'FILTER_PARAMS_TRANSACTION';
export const FILTER_PARAMS_SUMMARY = 'FILTER_PARAMS_SUMMARY';
export const CHANGED_FILTER_PARAMS_TRANSACTION = 'CHANGED_FILTER_PARAMS_TRANSACTION';
export const CHANGED_FILTER_PARAMS_SET_TRANSACTION = 'CHANGED_FILTER_PARAMS_SET_TRANSACTION';
export const CHANGED_FILTER_PARAMS_SUMMARY = 'CHANGED_FILTER_PARAMS_SUMMARY';

export function handleOnChangeParamsFilter(data) {
    switch (data.type) {
        case FILTER_PARAMS_TRANSACTION:
            return {
                type: CHANGED_FILTER_PARAMS_TRANSACTION,
                payload: data.params
            };
        case FILTER_PARAMS_SET_TRANSACTION:
            return {
                type: CHANGED_FILTER_PARAMS_SET_TRANSACTION,
                payload: data.params
            };
        case FILTER_PARAMS_SUMMARY:
            return {
                type: CHANGED_FILTER_PARAMS_SUMMARY,
                payload: data.params
            };
        default:
    }
}

export function resetFilterParamsSetTransaction() {
    return {
        type: RESET_FILTER_PARAMS_SET_TRANSACTION,
    };
}