import {
  CREATE_ACCOUNT,
  CREATE_ACCOUNT_FAILURE,
  CREATE_ACCOUNT_SUCCESS,
  DELETE_ACCOUNT,
  DELETE_ACCOUNT_FAILURE,
  DELETE_ACCOUNT_SUCCESS,
  FETCH_ACCOUNT,
  FETCH_ACCOUNT_FAILURE,
  FETCH_ACCOUNT_SUCCESS,
  FETCH_ACCOUNTS,
  FETCH_ACCOUNTS_FAILURE,
  FETCH_ACCOUNTS_SUCCESS, FETCH_ACCOUNTS_TYPES, FETCH_ACCOUNTS_TYPES_FAILURE, FETCH_ACCOUNTS_TYPES_SUCCESS,
  RESET_ACCOUNTS,
  RESET_ACTIVE_ACCOUNT,
  RESET_DELETED_ACCOUNT,
  RESET_NEW_ACCOUNT,
  UPDATE_ACCOUNT,
  UPDATE_ACCOUNT_FAILURE,
  UPDATE_ACCOUNT_SUCCESS,
} from '../actions/accountActions';

const initialState = {
  totalBalance: {
    value: 0,
    error: null,
    isFetching: false
  },
  accountList: {
    accounts: [],
    error: null,
    isFetching: false
  },
  accountTypesList: {
    accounts: [],
    error: null,
    isFetching: false
  },
  newAccount:{account:null, error: null, isFetching: false},
  activeAccount:{account:null, error:null, isFetching: false},
  deletedAccount: {account: null, error:null, isFetching: false},
};

const accountReducer = (state = initialState, action) => {
  let error;
  switch (action.type) {
    case FETCH_ACCOUNTS:
      return {
        ...state,
        totalBalance: {value: 0, error: null, isFetching: true},
        accountList: {accounts:[], error: null, isFetching: true}
      };
    case FETCH_ACCOUNTS_SUCCESS:
      return {
        ...state,
        totalBalance: {value: action.payload.totalBalance, error: null, isFetching: false},
        accountList: {accounts: action.payload.accounts, error:null, isFetching: false}
      };
    case FETCH_ACCOUNTS_FAILURE:
      error = action.payload;
      return {
        ...state,
        totalBalance: {value: 0, error: error, isFetching: false},
        accountList: {accounts: [], error: error, isFetching: false}
      };
    case RESET_ACCOUNTS:
      return initialState;
    case FETCH_ACCOUNT:
      return { ...state, activeAccount: {account: null, error: null, isFetching: true} };
    case FETCH_ACCOUNT_SUCCESS:
      return { ...state, activeAccount: {account: action.payload, error:null, isFetching: false} };
    case FETCH_ACCOUNT_FAILURE:
      error = action.payload;
      return { ...state, activeAccount: {account: null, error: error, isFetching: false} };
    case RESET_ACTIVE_ACCOUNT: {
      return initialState;
    }

    case UPDATE_ACCOUNT: {
      const {accounts, error} = state.accountList;
      return { ...state, accountList: {accounts, error, isFetching: true} };
    }

    case UPDATE_ACCOUNT_SUCCESS: {
      const {accounts} = state.accountList;
      const updatedAccounts = accounts.map(account => {
        return account.id === action.payload.id ? action.payload : account;
      });
      return { ...state, accountList: {accounts: updatedAccounts, error: null, isFetching: false} };
    }

    case UPDATE_ACCOUNT_FAILURE: {
      const {accounts} = state.accountList;
      return { ...state, accountList: {accounts, error: action.payload, isFetching: false} };
    }

    case CREATE_ACCOUNT: {
      const {accounts, error} = state.accountList;
      return { ...state, accountList: {accounts, error, isFetching: true} };
    }

    case CREATE_ACCOUNT_SUCCESS: {
      const accounts = [...state.accountList.accounts, action.payload];
      return { ...state, accountList: {accounts, error: null, isFetching: false} };
    }

    case CREATE_ACCOUNT_FAILURE: {
      const {accounts} = state.accountList;
      return { ...state, accountList: {accounts, error: action.payload, isFetching: false} };
    }

    case RESET_NEW_ACCOUNT: {
      return initialState;
    }

    case DELETE_ACCOUNT: {
      const {accounts, error} = state.accountList;
      return { ...state, accountList: {accounts, error, isFetching: true} };
    }

    case DELETE_ACCOUNT_SUCCESS: {
      const accounts = state.accountList.accounts.filter(account => account.id !== action.payload.id);
      return { ...state, accountList: {accounts, error: null, isFetching: false} };
    }

    case DELETE_ACCOUNT_FAILURE: {
      const {accounts} = state.accountList;
      return { ...state, accountList: {accounts, error: action.payload, isFetching: false} };
    }

    case RESET_DELETED_ACCOUNT: {
      return initialState;
    }

    case FETCH_ACCOUNTS_TYPES:
      return {
        ...state,
        accountTypesList: {
          accounts: [],
          error: null,
          isFetching: true
        },
      };
    case FETCH_ACCOUNTS_TYPES_SUCCESS:
      return {
        ...state,
        accountTypesList: {
          accounts: action.payload,
          error: null,
          isFetching: false
        },
      };
    case FETCH_ACCOUNTS_TYPES_FAILURE:
      error = action.payload;
      return {
        ...state,
        accountTypesList: {
          accounts: [],
          error: action.payload,
          isFetching: false
        },
      };

    default:
      return {
        ...state
      };
  }
};

export default accountReducer;