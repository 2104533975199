import React from 'react';
import TextField from '@material-ui/core/TextField/TextField';
import {sectionStyles} from '../../styles/sectionStyles';
import {withStyles} from '@material-ui/core';
import Button from '@material-ui/core/Button/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Avatar from '@material-ui/core/Avatar/Avatar';
import {getHelperTextCommaSeparatedValue} from "../../utils/validationUtils";

class GroupDetails extends React.Component {

  render() {
    const {name, file, guests, isOwnerUser, handleOnChangeInput, classes, handleOnChangeFile, error} = this.props;
    return (
        <form className={classes.form} noValidate autoComplete="off">
          <TextField id="name"
                     label="Nombre"
                     value={name}
                     inputProps={
                       { readOnly: !isOwnerUser, }
                     }
                     onChange={handleOnChangeInput}
          />
          <TextField id="guests"
                     label="Invitados"
                     value={guests}
                     inputProps={
                       { readOnly: !isOwnerUser, }
                     }
                     onChange={handleOnChangeInput}
                     error={error && error.guests}
                     helperText={getHelperTextCommaSeparatedValue(error && error.guests)}
          />
          <div>
            {file ? <Avatar src={file} /> : (isOwnerUser ? <span>Pulse "<CloudUploadIcon style={{margin: '0 3px', verticalAlign: 'bottom'}} /> SUBIR IMAGEN" para adjuntar la imagen.</span> : null)}
          </div>
          {
            isOwnerUser ? <>
              <input
                accept=".jpg, .jpeg, .png"
                id="contained-button-file"
                multiple
                type="file"
                onChange={(e) => {
                  const file = e.target.files[0];
                  const reader = new FileReader();
                  reader.readAsDataURL(file);
                  reader.onloadend = () => {
                    handleOnChangeFile(reader.result);
                  };
                }}
              />
              <label htmlFor="contained-button-file">
                <Button variant="contained" color="primary" component="span" startIcon={<CloudUploadIcon />}>
                  Subir Imagen
                </Button>
              </label>
            </> : null
          }
        </form>
    )
  }
}

export default withStyles(sectionStyles)(GroupDetails);