import React from 'react';
import {Link} from 'react-router-dom';
import Grid from '@material-ui/core/Grid/Grid';
import {withStyles} from '@material-ui/core';
import {sectionStyles} from '../../styles/sectionStyles';
import {addCurrency} from '../../utils/currencyUtils';
import Button from '@material-ui/core/Button/Button';
import DateAdapterFactory from "../../utils/date/DateAdapterFactory";
import DateAdapter from "../../utils/date/DateAdapter";
import GroupIcon from "@material-ui/icons/Group";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";


const TransactionSummary = ({transaction, classes, selectedTransactions, showTransactionCheckbox, handleOnChangeTransactionCheckbox}) => {
    const isVisibleCheckbox = transaction.isUserTransactionOwner && showTransactionCheckbox;
    const checkbox = isVisibleCheckbox
        ? <Checkbox checked={selectedTransactions.includes(transaction.id)} value="checkedA" />
        : null
    return (
      <Grid item xs={12}>
        <Button disabled={!transaction.isUserTransactionOwner}  underline={`none`}
                className={transaction.ignoredToBalance ? classes.listItemsLinksDisabled : (transaction.pendingReturn? classes.listItemsLinksHightlight : classes.listItemsLinks)}
                {...(isVisibleCheckbox ? {
                    onClick: (e) => {
                        e.preventDefault();
                         handleOnChangeTransactionCheckbox(transaction.id);
                        }
                    } : {})
                }
                {...(!isVisibleCheckbox ? { component: Link, to: `/transactions/${transaction.id}` } : {})}
        >
          <Grid className={classes.listItemsLinksWrapperColumns}>
            {checkbox}
            <div>
              { DateAdapterFactory.createInstance(DateAdapter.getDateFromString(transaction.valueDate)).toDisplay() } - {transaction.category && transaction.category.name}
              { transaction.groups && transaction.groups.length > 0 ? <> - <span style={{display: 'inline-block', verticalAlign: 'middle'}}>
                  <GroupIcon className={classes.icon} />
                </span>
                {
                  transaction.isUserTransactionOwner ? null : <> {transaction.user.email} </>
                }
              </> : null
              }
              <br/>
              {transaction.alias && transaction.alias !== '' ? transaction.alias : transaction.concept}
            </div>
            <div className={classes.listItemsLinksWrapperColumnPrice}>
              {
                transaction.balance ? <>
                  <span className={classes.grey}>{addCurrency(transaction.balance[0])}</span>
                  <br/>
                </> : null
              }
              {addCurrency(transaction.amount[0])}
            </div>
          </Grid>
        </Button>
      </Grid>
  )
};

export default withStyles(sectionStyles)(TransactionSummary);