//Group list
import Api from '../../services/Api';

export const FETCH_GROUPS = 'FETCH_GROUPS';
export const FETCH_GROUPS_SUCCESS = 'FETCH_GROUPS_SUCCESS';
export const FETCH_GROUPS_FAILURE = 'FETCH_GROUPS_FAILURE';
export const RESET_GROUPS = 'RESET_GROUPS';

//Create new group
export const CREATE_GROUP = 'CREATE_GROUP';
export const CREATE_GROUP_SUCCESS = 'CREATE_GROUP_SUCCESS';
export const CREATE_GROUP_FAILURE = 'CREATE_GROUP_FAILURE';
export const RESET_NEW_GROUP = 'RESET_NEW_GROUP';

//Fetch group
export const FETCH_GROUP = 'FETCH_GROUP';
export const FETCH_GROUP_SUCCESS = 'FETCH_GROUP_SUCCESS';
export const FETCH_GROUP_FAILURE = 'FETCH_GROUP_FAILURE';
export const RESET_ACTIVE_GROUP = 'RESET_ACTIVE_GROUP';

//Update group
export const UPDATE_GROUP = 'UPDATE_GROUP';
export const UPDATE_GROUP_SUCCESS = 'UPDATE_GROUP_SUCCESS';
export const UPDATE_GROUP_FAILURE = 'UPDATE_GROUP_FAILURE';

//Delete group
export const DELETE_GROUP = 'DELETE_GROUP';
export const DELETE_GROUP_SUCCESS = 'DELETE_GROUP_SUCCESS';
export const DELETE_GROUP_FAILURE = 'DELETE_GROUP_FAILURE';
export const RESET_DELETED_GROUP = 'RESET_DELETED_GROUP';

export const FETCH_GROUPS_TYPES = 'FETCH_GROUPS_TYPES';
export const FETCH_GROUPS_TYPES_SUCCESS = 'FETCH_GROUPS_TYPES_SUCCESS';
export const FETCH_GROUPS_TYPES_FAILURE = 'FETCH_GROUPS_TYPES_FAILURE';

export function fetchGroups() {
  return (dispatch) => {
    const request = Api.getInstance().get('/groups');
    dispatch({
      type: FETCH_GROUPS
    });
    request
        .then((response) => {
          if (response.error) {
            return dispatch(fetchGroupsFailure(response.data));
          }

          return dispatch(fetchGroupsSuccess(response.data));
        })
        .catch(error => {
          console.log(error);
        })
  }
}

export function fetchGroupsSuccess(groups) {
  return {
    type: FETCH_GROUPS_SUCCESS,
    payload: groups
  };
}

export function fetchGroupsFailure(error) {
  return {
    type: FETCH_GROUPS_FAILURE,
    payload: error
  };
}

export function resetGroups() {
  return {
    type: RESET_GROUPS
  }
}


export function editGroup({group, redirect}) {
  return (dispatch) => {
    const {id, ...params} = group;
    const request = Api.getInstance().put(`/groups/${id}`, {
      ...params
    });
    dispatch({
      type: UPDATE_GROUP
    });

    request.then(response => {
      if (response.error) {
        return dispatch(editGroupFailure(response.data));
      }
      dispatch(editGroupSuccess(response.data));
      dispatch(resetGroups());
      if (redirect) {
        return redirect();
      }
    })
  }
}

export function editGroupSuccess(posts) {
  return {
    type: UPDATE_GROUP_SUCCESS,
    payload: posts
  };
}

export function editGroupFailure(error) {
  return {
    type: UPDATE_GROUP_FAILURE,
    payload: error
  };
}

export function resetActiveGroup() {
  return {
    type: RESET_ACTIVE_GROUP
  }
}

export function resetNewGroup() {
  return {
    type: RESET_NEW_GROUP
  }
}

export function resetDeletedGroup() {
  return {
    type: RESET_DELETED_GROUP
  }
}

export function createGroup({group, redirect}) {
  return (dispatch) => {
    const {...params} = group;
    const request = Api.getInstance().post('/groups', {
      ...params
    });
    dispatch({
      type: CREATE_GROUP
    });

    request.then(response => {
      if (response.error) {
        return dispatch(createGroupFailure(response.data));
      }
      dispatch(createGroupSuccess(response.data));
      dispatch(resetGroups());
      if (redirect) {
        return redirect();
      }
    })
  }
}

export function createGroupSuccess(posts) {
  return {
    type: CREATE_GROUP_SUCCESS,
    payload: posts
  };
}

export function createGroupFailure(error) {
  return {
    type: CREATE_GROUP_FAILURE,
    payload: error
  };
}

export function fetchGroup(id) {
  return (dispatch) => {
    const request = Api.getInstance().get(`/groups/${id}`);
    dispatch({
      type: FETCH_GROUP
    });

    request.then(response => {
      !response.error ? dispatch(fetchGroupSuccess(response.data)) : dispatch(fetchGroupFailure(response.data));
    })
  }
}

export function fetchGroupSuccess(msg) {
  return {
    type: FETCH_GROUP_SUCCESS,
    payload: msg
  };
}

export function fetchGroupFailure(error) {
  return {
    type: FETCH_GROUP_FAILURE,
    payload: error
  };
}

export function deleteGroup({id, redirect}) {
  return (dispatch) => {
    const request = Api.getInstance().delete(`/groups/${id}`);
    dispatch({
      type: DELETE_GROUP
    });

    request.then((response) => {
      if (response.error) {
        return dispatch(deleteGroupFailure(response.data));
      }
      dispatch(deleteGroupSuccess(response.data));
      dispatch(resetGroups());
      if (redirect) {
        return redirect();
      }
    });
  }
}

export function deleteGroupSuccess(msg) {
  return {
    type: DELETE_GROUP_SUCCESS,
    payload: msg
  };
}

export function deleteGroupFailure(error) {
  return {
    type: DELETE_GROUP_FAILURE,
    payload: error
  };
}

export function fetchGroupTypes() {
  return (dispatch) => {
    const request = Api.getInstance().get('/group-types');
    dispatch({
      type: FETCH_GROUPS_TYPES
    });
    request
      .then((response) => {
        if (response.error) {
          return dispatch(fetchGroupTypesFailure(response.data));
        }

        return dispatch(fetchGroupTypesSuccess(response.data));
      })
      .catch(error => {
        console.log(error);
      })
  }
}

export function fetchGroupTypesSuccess(groupTypes) {
  return {
    type: FETCH_GROUPS_TYPES_SUCCESS,
    payload: groupTypes
  };
}

export function fetchGroupTypesFailure(error) {
  return {
    type: FETCH_GROUPS_TYPES_FAILURE,
    payload: error
  };
}