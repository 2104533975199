import React from 'react';
import TransactionList from './TransactionList';
import Grid from '@material-ui/core/Grid/Grid';
import {Link, Redirect} from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import FormatListNumbered from '@material-ui/icons/FormatListNumbered';
import SaveIcon from '@material-ui/icons/Save';
import CreateIcon from '@material-ui/icons/Create';
import CategoryIcon from '@material-ui/icons/Category';
import Header from '../layout/Header';
import Fab from '@material-ui/core/Fab/Fab';
import {sectionStyles} from '../../styles/sectionStyles';
import {withStyles} from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import FilterModal from './FilterModal';
import SetModal from './SetModal';
import {compose} from 'redux';
import connect from 'react-redux/es/connect/connect';
import {fetchTransactions, updateTransactionsCategory, updateTransactions} from '../../store/actions/transactionActions';
import {
  FILTER_PARAMS_SET_TRANSACTION,
  FILTER_PARAMS_TRANSACTION,
  handleOnChangeParamsFilter, resetFilterParamsSetTransaction
} from "../../store/actions/filterActions";
import {fetchCategories} from "../../store/actions/categoryActions";
import DateAdapterFactory from "../../utils/date/DateAdapterFactory";
import Badge from "@material-ui/core/Badge";
import {fetchAccounts} from "../../store/actions/accountActions";
import {fetchGroups} from "../../store/actions/groupActions";

class Transactions extends React.Component {
  componentDidMount() {
    if (!this.props.auth.accessToken) {
      return;
    }
    this.fetchTransactions();
    this.props.fetchCategories();
    this.props.fetchAccounts();
    this.props.fetchGroups();
  }

  state = {
    open: false,
    openSetModal: false,
    showUpdateCategoriesButtons: false,
    showUpdateTransactionsButtons: false,
    selectedTransactions: [],
  };

  handleOnChangeTransactionCheckbox = (id) => {
    if (this.state.selectedTransactions.includes(id)) {
      this.setState({
        selectedTransactions: this.state.selectedTransactions.filter(el => el !== id),
      });
    } else {
      this.setState({
        selectedTransactions: [...this.state.selectedTransactions, id],
      });
    }
  };

  handleClickUpdateCategories = (updateAll) => {
    const callback = this.fetchTransactions;
    this.props.updateTransactionsCategory({updateAll, callback});
  };

  handleClickUpdateTransactions = () => {
    const selectedTransactions = this.state.selectedTransactions;
    const callback = () => {
      this.setState({
        showUpdateTransactionsButtons: false,
        selectedTransactions: [],
      });
      this.props.resetFilterParamsSetTransaction();
      this.fetchTransactions();
    };
    this.props.updateTransactions({selectedTransactions, filterSetTransactions: this.props.filterSetTransactions, callback});
  };

  handleClickOpenCloseUpdateCategories = (e) => {
    e.preventDefault();
    this.setState({
      showUpdateCategoriesButtons: !this.state.showUpdateCategoriesButtons
    })
  }

  handleClickOpenCloseUpdateTransactions = (e) => {
    e.preventDefault();
    this.setState({
      showUpdateTransactionsButtons: !this.state.showUpdateTransactionsButtons
    })
  }

  handleClickOpen = () => {
    this.setState({open: true});
  };

  handleClickOpenSet = () => {
    this.setState({openSetModal: true});
  };

  handleClose = () => {
    this.setState({
      open: false
    });
  };

  handleCloseSetModal = () => {
    this.setState({
      openSetModal: false
    });
  };

  handleSetFilter = (state) => {
    const type = FILTER_PARAMS_TRANSACTION;
    this.props.handleOnChangeParamsFilter({
      type,
      params: {
        ...state
      }
    });
    this.setState({
      open: false
    }, () => {
      this.fetchTransactions();
    });
  };

  handleSetModalParams = (state) => {
    const type = FILTER_PARAMS_SET_TRANSACTION;
    this.props.handleOnChangeParamsFilter({
      type,
      params: {
        ...state
      }
    });
    this.setState({
      openSetModal: false
    });
  };

  fetchTransactions = () => {
    const {dateStart, dateEnd, amountStart, amountEnd, concept, alias, category, account, showOnlyMyTransactionsWithoutGroup, showOnlyMyTransactions, ignoredToBalance, pendingReturn} = this.props.filterTransactions;
    const otherFilterList = [{amountStart}, {amountEnd}, {concept}, {alias}, {category}, {account}];
    const filtersToSearch = otherFilterList.reduce((prev, next) => {
      return Object.values(next)[0] !== '' ? {...prev, ...next} : prev;
    }, {
      dateStart: DateAdapterFactory.createInstance(dateStart).getStartDayMoment().toISOString(),
      dateEnd: DateAdapterFactory.createInstance(dateEnd).getEndDayMoment().toISOString(),
      ...(showOnlyMyTransactionsWithoutGroup && {showOnlyMyTransactionsWithoutGroup}),
      ...(showOnlyMyTransactions && {showOnlyMyTransactions}),
      ...(ignoredToBalance && {ignoredToBalance}),
      ...(pendingReturn && {pendingReturn}),
    });
    this.props.fetchTransactions(filtersToSearch);
  }

  getUpdateCategoriesButtons() {
    if (!this.state.showUpdateCategoriesButtons) {
      return null;
    }
    return (
        <>
          <Fab color={`secondary`} aria-label="filter" onClick={() => this.handleClickUpdateCategories(true)} size={`small`}>
            <AllInclusiveIcon />
          </Fab>
          <Fab color={`secondary`} aria-label="filter" onClick={() => this.handleClickUpdateCategories(false)} size={`small`}>
            <NotInterestedIcon />
          </Fab>
        </>
    )
  }

  getUpdateTransactionsButtons() {
    if (!this.state.showUpdateTransactionsButtons) {
      return null;
    }
    return (
        <>
          <Fab color={`secondary`} aria-label="filter" onClick={() => this.handleClickUpdateTransactions()} size={`small`}>
            <SaveIcon />
          </Fab>
          <Fab color={`secondary`} aria-label="filter" onClick={() => this.handleClickOpenSet()} size={`small`}>
            <CreateIcon />
          </Fab>
        </>
    )
  }

  getBadgeFilterTransactionContent() {
    const {transactions, isFetching, error} = this.props.transactionList;
    if (isFetching) {
      return '...';
    }
    if (error) {
      return 'x';
    }
    return transactions.length;
  }

  render() {
    const {classes, auth, filterTransactions} = this.props;
    if (!auth.accessToken) {
      return <Redirect to='/signin' />
    }
    const title = `Transacciones`;
    const headerInfo = {
      value: `${DateAdapterFactory.createInstance(filterTransactions.dateStart).toDisplay()} - ${DateAdapterFactory.createInstance(filterTransactions.dateEnd).toDisplay()}`,
      classes: null
    };
    const updateCategoriesButtons = this.getUpdateCategoriesButtons();
    const updateTransactionsButtons = this.getUpdateTransactionsButtons();
    const badgeFilterTransactionContent = this.getBadgeFilterTransactionContent();
    return (
        <div className={classes.wrapperSection}>
          <Header title={title} headerInfo={headerInfo}/>
          <div className={classes.wrapperSectionContent}>
            <div className={classes.appBarSpacer} />
            <Grid container className={classes.sectionContent}>
              <Grid item xs={12}>
                <TransactionList selectedTransactions={this.state.selectedTransactions} handleOnChangeTransactionCheckbox={this.handleOnChangeTransactionCheckbox} showTransactionCheckbox={this.state.showUpdateTransactionsButtons}/>
              </Grid>
            </Grid>
            <div className={classes.appBarSpacer} />
            <div className={classes.appBarSpacer} />
          </div>
          <div className={classes.navbarSection}>
            {updateCategoriesButtons}
            <Fab color={`primary`} aria-label="filter" onClick={this.handleClickOpenCloseUpdateCategories} size={`small`}>
              <CategoryIcon />
            </Fab>
            {updateTransactionsButtons}
            <Fab color={`primary`} aria-label="filter" onClick={this.handleClickOpenCloseUpdateTransactions} size={`small`}>
              <FormatListNumbered />
            </Fab>
            <Badge color={`secondary`} badgeContent={badgeFilterTransactionContent} max={999} showZero
                   anchorOrigin={{
                     vertical: 'top',
                     horizontal: 'right',
                   }}
            >
              <Fab color={`primary`} aria-label="filter" onClick={this.handleClickOpen} size={`small`}>
                <FilterListIcon />
              </Fab>
            </Badge>
            <Fab color={`primary`} aria-label="add" component={Link} to={`/transactions/upload`} size={`small`}>
              <CloudUploadIcon />
            </Fab>
            <Fab color={`primary`} aria-label="add" component={Link} to={`/transactions/create`} size={`small`}>
              <AddIcon />
            </Fab>
          </div>
          <FilterModal open={this.state.open} dateStart={filterTransactions.dateStart} dateEnd={filterTransactions.dateEnd} amountStart={filterTransactions.amountStart} amountEnd={filterTransactions.amountEnd} concept={filterTransactions.concept} alias={filterTransactions.alias} category={filterTransactions.category} account={filterTransactions.account} showOnlyMyTransactionsWithoutGroup={filterTransactions.showOnlyMyTransactionsWithoutGroup} showOnlyMyTransactions={filterTransactions.showOnlyMyTransactions} ignoredToBalance={filterTransactions.ignoredToBalance} pendingReturn={filterTransactions.pendingReturn}  handleClose={this.handleClose} handleSetFilter={this.handleSetFilter} />
          <SetModal open={this.state.openSetModal}
                    category={this.props.filterSetTransactions.category} groups={this.props.filterSetTransactions.groups}
                    checkboxCategoryChecked={this.props.filterSetTransactions.checkboxCategoryChecked} checkboxGroupsChecked={this.props.filterSetTransactions.checkboxGroupsChecked}
                    handleClose={this.handleCloseSetModal} handleSetModalParams={this.handleSetModalParams} />
        </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    filterTransactions: state.filter.transactions,
    filterSetTransactions: state.filter.setTransations,
    transactionList: state.transaction.transactionList
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchTransactions: (data) => dispatch(fetchTransactions(data)),
    fetchCategories: () => dispatch(fetchCategories()),
    fetchAccounts: () => dispatch(fetchAccounts()),
    fetchGroups: () => dispatch(fetchGroups()),
    handleOnChangeParamsFilter: (data) => dispatch(handleOnChangeParamsFilter(data)),
    resetFilterParamsSetTransaction: () => dispatch(resetFilterParamsSetTransaction()),
    updateTransactionsCategory: ({updateAll, callback}) => dispatch(updateTransactionsCategory({updateAll, callback})),
    updateTransactions: ({selectedTransactions, filterSetTransactions, callback}) => dispatch(updateTransactions({selectedTransactions, filterSetTransactions, callback}))
  };
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(sectionStyles)
)(Transactions);