import React from 'react';
import Category from './Category';

class CategoriesTree extends React.Component {

  render() {
    const {node, depth, handleOnClickMove, handleOnClickSetParentId, handleOnClickCancelSetParentId, isMoving, elementIdEditingParent, handleOnClickDropDown, handleOpenModalLabels} = this.props;
    return node && node.map((elem) => {
      const children = elem.children.length > 0 ? <CategoriesTree node={elem.children} depth={depth + 1}
                                                                  handleOnClickMove={handleOnClickMove}
                                                                  handleOnClickSetParentId={handleOnClickSetParentId}
                                                                  handleOnClickCancelSetParentId={handleOnClickCancelSetParentId}
                                                                  isMoving={isMoving}
                                                                  elementIdEditingParent={elementIdEditingParent}
                                                                  handleOnClickDropDown={handleOnClickDropDown}
                                                                  handleOpenModalLabels={handleOpenModalLabels}
                                                                  /> : null;
      const collapsedClass = elem.collapsed ? 'd-none': '';
      return (
          <li key={elem.id}>
            <Category node={elem}
                      handleOnClickMove={(e) => {
                        e.preventDefault();
                        handleOnClickMove(elem.id);
                      }}
                      handleOnClickSetParentId={(e) => {
                        e.preventDefault();
                        handleOnClickSetParentId(elem.id);
                      }}
                      handleOnClickCancelSetParentId={handleOnClickCancelSetParentId}
                      isMoving={isMoving}
                      elementIdEditingParent={elementIdEditingParent}
                      isDropDownable={children}
                      handleOnClickDropDown={(e) => {
                        e.preventDefault();
                        handleOnClickDropDown(elem.id, elem.collapsed);
                      }}
                      collapsed={elem.collapsed}
                      handleOpenModalLabels={handleOpenModalLabels}
            />
            { children ? (
                <ul className={`${collapsedClass}`}>
                  {children}
                </ul>
              ) : null
            }
          </li>
      )
    });
  }
}

export default CategoriesTree;