import React from 'react';
import Grid from '@material-ui/core/Grid/Grid';
import {Link} from 'react-router-dom';
import {sectionStyles} from '../../styles/sectionStyles';
import {withStyles} from '@material-ui/core';
import {addCurrency} from '../../utils/currencyUtils';
import Avatar from '@material-ui/core/Avatar/Avatar';
import Button from '@material-ui/core/Button/Button';

const AccountSummary = ({account, classes}) => {
  return (
        <Grid item xs={12}>
          <Button component={Link} underline={`none`} className={classes.listItemsLinks} to={`/accounts/${account.id}`}>
            <div className={classes.headerContentWrapper}>
              <div className={classes.headerContentWrapper2}>
                <div className={classes.accountSummaryWrapper}>
                  <Avatar src={account.file} className={classes.accountSummaryAvatar}/>
                  <div>
                    {account.name}
                    <br />
                    {addCurrency(account.balance)}
                  </div>
                </div>
                <div>
                  {addCurrency(account.transactionSummation[0])}
                </div>
              </div>
            </div>
          </Button>
        </Grid>
  )
};

export default withStyles(sectionStyles)(AccountSummary);