import React from 'react';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogContentText
  from '@material-ui/core/DialogContentText/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import Button from '@material-ui/core/Button/Button';
import Dialog from '@material-ui/core/Dialog/Dialog';
import TextField from '@material-ui/core/TextField/TextField';
import {sectionStyles} from '../../styles/sectionStyles';
import {withStyles} from '@material-ui/core';
import {compose} from 'redux';
import connect from 'react-redux/es/connect/connect';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LinearProgress from '@material-ui/core/LinearProgress/LinearProgress';
import Avatar from "@material-ui/core/Avatar/Avatar";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import FormControl from "@material-ui/core/FormControl";

class SetModal extends React.Component {
  state = {
    category: this.props.category,
    groups: this.props.groups,
    checkboxCategoryChecked: this.props.checkboxCategoryChecked,
    checkboxGroupsChecked: this.props.checkboxGroupsChecked,
  };

  handleChangeMultiple = (event) => {
    const groupsSelected = event.target.value;
    const newGroups = this.props.groupList.groups.map(elem => elem.id).filter(elem => groupsSelected.includes(elem));
    this.setState({
      'groups': newGroups,
    })
  };

  handleOnChangeSelect = (id, selectedOption) => {
    this.setState({
      [id]: selectedOption ? selectedOption.value : ''
    });
  };

  handleClose = () => {
    this.setState({
      category: this.props.category,
      groups: this.props.groups,
      checkboxCategoryChecked: this.props.checkboxCategoryChecked,
      checkboxGroupsChecked: this.props.checkboxGroupsChecked,
    }, () => {
      this.props.handleClose();
    });
  };

  handleReset = () => {
    this.setState({
      category: '',
      groups: [],
      checkboxCategoryChecked: false,
      checkboxGroupsChecked: false,
    }, () => {
      this.props.handleClose();
      this.props.handleSetModalParams(this.state);
    });
  };

  render() {
    const {open, handleClose, handleSetModalParams, classes, categoryList, groupList} = this.props;
    const {categories, isFetching: isFetchingCategories, error: errorCategories} = categoryList;
    const {groups, isFetching: isFetchingGroups, error: errorGroups} = groupList;

    if (isFetchingCategories || errorCategories || isFetchingGroups || errorGroups) {
      return (
          <LinearProgress />
      )
    }

    const mapCategoriesToSelect = categories.map(elem => {
      return {
        title: elem.name,
        value: elem.id
      }
    });
    mapCategoriesToSelect.unshift({title: 'Sin Categorizar', value: 'null'});

    const categoryValue = mapCategoriesToSelect.find(elem => elem.value === this.state.category) || '';

    const groupsValue = groups.map(elem => elem.id).filter(elem => this.state.groups.includes(elem));

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          {/*<DialogTitle id="form-dialog-title">Subscribe</DialogTitle>*/}
          <DialogContent>
            <DialogContentText>
              Seteo Masivo de Transacciones
            </DialogContentText>
            <form className={classes.form} noValidate autoComplete="off">
              <Checkbox style={{width: 'auto'}} checked={this.state.checkboxCategoryChecked} onChange={(event) => {
                const { checked } = event.target;
                this.setState({
                  'checkboxCategoryChecked': checked,
                  ...(!checked && {category: ''})
                })
              }}/> Modificar Categoría
              <Autocomplete
                  disabled={!this.state.checkboxCategoryChecked}
                  id="category"
                  options={mapCategoriesToSelect}
                  getOptionLabel={option => (option.title ? option.title : "")}
                  className={classes.autocomplete}
                  renderInput={params => (
                      <TextField {...params} label="Categorías" fullWidth />
                  )}
                  onChange={(event, value) => this.handleOnChangeSelect(`category`, value)}
                  value={categoryValue}
              />
              <Checkbox style={{width: 'auto'}} checked={this.state.checkboxGroupsChecked} onChange={(event) => {
                const { checked } = event.target;
                this.setState({
                  'checkboxGroupsChecked': checked,
                  ...(!checked && {groups: []})
                })
              }}/> Modificar Grupos
              <FormControl style={{width: '100%'}}>
                <InputLabel id="accounts-label">Grupos</InputLabel>
                <Select
                    disabled={!this.state.checkboxGroupsChecked}
                    labelId="groups-label"
                    id="groups"
                    multiple
                    value={groupsValue}
                    onChange={this.handleChangeMultiple}
                    input={<Input />}
                    renderValue={(selected) => selected.join(', ')}
                    MenuProps={
                      {
                        MenuListProps: { disablePadding: true },
                        getContentAnchorEl: () => null // avoid menu movement onSelect element
                      }
                    }
                >
                  {
                    groups.map((elem, index) => (
                        <MenuItem key={index} value={elem.id} className={classes.summaryGroupMenu}>
                          <Checkbox checked={this.state.groups.includes(elem.id)} />
                          <ListItemAvatar className={classes.summaryGroupListItemAvatar}>
                            <Avatar src={elem.file} className={classes.summaryGroupAvatar}/>
                          </ListItemAvatar>
                          <Typography variant="inherit" noWrap>
                            {elem.name}
                          </Typography>
                        </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancelar
            </Button>
            <Button onClick={this.handleReset} color="primary">
              Reset
            </Button>
            <Button onClick={() => {
              handleSetModalParams(this.state);
            }} color="primary">
              Guardar
            </Button>
          </DialogActions>
        </Dialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    categoryList: state.category.categoryList,
    groupList: state.group.groupList,
  };
};

export default compose(
    connect(mapStateToProps),
    withStyles(sectionStyles)
)(SetModal);