import React from 'react';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogContentText
  from '@material-ui/core/DialogContentText/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import Button from '@material-ui/core/Button/Button';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DateFnsUtils from '@date-io/date-fns';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import {withStyles} from "@material-ui/core";
import {sectionStyles} from "../../styles/sectionStyles";
import Fab from "@material-ui/core/Fab/Fab";
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';
import DateAdapterFactory from "../../utils/date/DateAdapterFactory";
import DateAdapter from "../../utils/date/DateAdapter";
import {compose} from "redux";
import connect from 'react-redux/es/connect/connect';
import LinearProgress from "@material-ui/core/LinearProgress/LinearProgress";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import Avatar from "@material-ui/core/Avatar/Avatar";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";

class FilterSummaryModal extends React.Component {
  state = {
    dateStart: this.props.dateStart,
    dateEnd: this.props.dateEnd,
    accountsExcluded: this.props.accountsExcluded,
    nestedTree: this.props.nestedTree,
  };

  handleSetTimeCountingDaysBefore(numberOfDays) {
    this.setState({
      dateStart: DateAdapterFactory.createInstance(DateAdapter.getToday()).getDateFromNumberOfDaysBefore(numberOfDays).getStartDayMoment().date,
      dateEnd: DateAdapterFactory.createInstance(DateAdapter.getToday()).getEndDayMoment().date
    }, () => {
      this.props.handleSetFilter(this.state)
    });
  };

  handleSetTimeFromBegining() {
    this.setState({
      dateStart: DateAdapterFactory.createInstance(DateAdapter.getDateFromUnixBirthday()).getStartDayMoment().date,
      dateEnd: DateAdapterFactory.createInstance(DateAdapter.getToday()).getEndDayMoment().date
    }, () => {
      this.props.handleSetFilter(this.state)
    });
  };

  handleChangeDate = (id, date) => {
    return this.setState({
      [id]: date
    });
  };

  handleOnChangeCheckbox = (id, checked) => {
    this.setState({
      [id]: checked
    }, () => {
      this.props.handleSetFilter(this.state)
    });
  };

  handleChangeMultiple = (event) => {
    const accountsIncluded = event.target.value;
    const accountsExcluded = this.props.accountList.accounts.map(elem => elem.id).filter(elem => !accountsIncluded.includes(elem));
    this.setState({
      'accountsExcluded': accountsExcluded,
    })
  };

  handleClose = () => {
    this.setState({
      dateStart: this.props.dateStart,
      dateEnd: this.props.dateEnd
    }, () => {
      this.props.handleClose();
    });
  };

  render() {
    const {open, handleClose, handleSetFilter, classes, accountList} = this.props;
    const {accounts, isFetching, error} = accountList;

    if (isFetching || error) {
      return (
        <LinearProgress />
      )
    }

    const accountValue = accounts.map(elem => elem.id).filter(elem => !this.state.accountsExcluded.includes(elem))
    console.log(this.state);
    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogContent>
            <DialogContentText>
              Visualización:
            </DialogContentText>
            <FormControlLabel
                control={
                  <Checkbox checked={this.state.nestedTree} onChange={(e) => this.handleOnChangeCheckbox('nestedTree', e.target.checked)} value="checkedA" />
                }
                label="Arbol anidado"
            />
            <DialogContentText>
              Número de Días hasta Hoy
            </DialogContentText>
            <div className={classes.filterButtons}>
              <Fab color={`primary`} aria-label="add" component={Button} onClick={() => this.handleSetTimeCountingDaysBefore(7)} size={`small`}>
                7
              </Fab>
              <Fab color={`primary`} aria-label="add" component={Button} onClick={() => this.handleSetTimeCountingDaysBefore(30)} size={`small`}>
                30
              </Fab>
              <Fab color={`primary`} aria-label="add" component={Button} onClick={() => this.handleSetTimeCountingDaysBefore(90)} size={`small`}>
                90
              </Fab>
              <Fab color={`primary`} aria-label="add" component={Button} onClick={() => this.handleSetTimeCountingDaysBefore(365)} size={`small`}>
                365
              </Fab>
              <Fab color={`primary`} aria-label="add" component={Button} onClick={() => this.handleSetTimeFromBegining()} size={`small`}>
                <AllInclusiveIcon />
              </Fab>
            </div>
            <DialogContentText style={{margin: '20px 0 0 0'}}>
              Filtrar por Fechas
            </DialogContentText>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                  margin="normal"
                  id="dateStart"
                  label="Fecha Desde"
                  format="dd/MM/yyyy"
                  value={this.state.dateStart}
                  onChange={(date) => this.handleChangeDate("dateStart", date)}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
              />
            </MuiPickersUtilsProvider>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                  margin="normal"
                  id="dateEnd"
                  label="Fecha Hasta"
                  format="dd/MM/yyyy"
                  value={this.state.dateEnd}
                  onChange={(date) => this.handleChangeDate("dateEnd", date)}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
              />
            </MuiPickersUtilsProvider>
            <FormControl style={{width: '100%'}}>
              <InputLabel id="accounts-label">Cuentas</InputLabel>
              <Select
                labelId="accounts-label"
                id="accounts"
                multiple
                value={accountValue}
                onChange={this.handleChangeMultiple}
                input={<Input />}
                renderValue={(selected) => selected.join(', ')}
                MenuProps={
                  {
                    MenuListProps: { disablePadding: true },
                    getContentAnchorEl: () => null // avoid menu movement onSelect element
                  }
                }
              >
                {
                  accounts.map((elem, index) => (
                    <MenuItem key={index} value={elem.id} className={classes.summaryAccountMenu}>
                      <Checkbox checked={!this.state.accountsExcluded.includes(elem.id)} />
                      <ListItemAvatar className={classes.summaryAccountListItemAvatar}>
                        <Avatar src={elem.file} className={classes.summaryAccountAvatar}/>
                      </ListItemAvatar>
                      <Typography variant="inherit" noWrap>
                        {elem.name}
                      </Typography>
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={() => handleSetFilter(this.state)} color="primary">
              Set
            </Button>
          </DialogActions>
        </Dialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    accountList: state.account.accountList
  };
};

export default compose(
  connect(mapStateToProps),
  withStyles(sectionStyles)
)(FilterSummaryModal);