import {
  CREATE_CATEGORY,
  CREATE_CATEGORY_FAILURE,
  CREATE_CATEGORY_SUCCESS,
  DELETE_CATEGORY,
  DELETE_CATEGORY_FAILURE,
  DELETE_CATEGORY_SUCCESS,
  FETCH_CATEGORIES,
  FETCH_CATEGORIES_FAILURE,
  FETCH_CATEGORIES_SUCCESS,
  FETCH_CATEGORIES_WITH_TRANSACTIONS,
  FETCH_CATEGORIES_WITH_TRANSACTIONS_FAILURE,
  FETCH_CATEGORIES_WITH_TRANSACTIONS_SUCCESS,
  RESET_ACTIVE_CATEGORY,
  RESET_CATEGORIES, RESET_CATEGORIES_WITH_TRANSACTIONS,
  RESET_DELETED_CATEGORY,
  RESET_NEW_CATEGORY,
  UPDATE_CATEGORY,
  UPDATE_CATEGORY_FAILURE,
  UPDATE_CATEGORY_SUCCESS,
} from '../actions/categoryActions';

const initialState = {
  categoryList: {
    categories: [],
    error: null,
    isFetching: false
  },
  categoryWithTransactionsList: {
    categoryTypes: null,
    error: null,
    isFetching: false,
    didInvalidate: false
  },
  newCategory:{category:null, error: null, isFetching: false},
  activeCategory:{category:null, error:null, isFetching: false},
  deletedCategory: {category: null, error:null, isFetching: false},
};

const categoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CATEGORIES: {
      return { ...state, categoryList: {categories:[], error: null, isFetching: true} };
    }

    case FETCH_CATEGORIES_SUCCESS: {
      return { ...state, categoryList: {categories: action.payload, error:null, isFetching: false} };
    }

    case FETCH_CATEGORIES_FAILURE: {
      const error = action.payload;
      return { ...state, categoryList: {categories: [], error: error, isFetching: false} };
    }

    case RESET_CATEGORIES: {
      return initialState;
    }

    case FETCH_CATEGORIES_WITH_TRANSACTIONS: {
      return { ...state, categoryWithTransactionsList: {categoryTypes: null, error: null, isFetching: true, didInvalidate: false} };
    }

    case FETCH_CATEGORIES_WITH_TRANSACTIONS_SUCCESS: {
      return { ...state, categoryWithTransactionsList: {categoryTypes: action.payload, error:null, isFetching: false, didInvalidate: false} };
    }

    case FETCH_CATEGORIES_WITH_TRANSACTIONS_FAILURE: {
      const error = action.payload;
      return { ...state, categoryWithTransactionsList: {categoryTypes: null, error: error, isFetching: false, didInvalidate: false} };
    }

    case RESET_CATEGORIES_WITH_TRANSACTIONS: {
      return { ...state, categoryWithTransactionsList: {categoryTypes: state.categoryWithTransactionsList.categoryTypes, error:null, isFetching: false, didInvalidate: true} };
    }

    case UPDATE_CATEGORY: {
      const {categories, error} = state.categoryList;
      return { ...state, categoryList: {categories, error, isFetching: true} };
    }

    case UPDATE_CATEGORY_SUCCESS: {
      const {categories} = state.categoryList;
      const updatedCategories = categories.map(category => {
        return category.id === action.payload.id ? action.payload : category;
      });
      return { ...state, categoryList: {categories: updatedCategories, error: null, isFetching: false} };
    }

    case RESET_ACTIVE_CATEGORY: {
      return initialState;
    }

    case UPDATE_CATEGORY_FAILURE: {
      const {categories} = state.categoryList;
      return { ...state, categoryList: {categories, error: action.payload, isFetching: false} };
    }

    case CREATE_CATEGORY: {
      const {categories, error} = state.categoryList;
      return { ...state, categoryList: {categories, error, isFetching: true} };
    }

    case CREATE_CATEGORY_SUCCESS: {
      const categories = [...state.categoryList.categories, action.payload];
      return { ...state, categoryList: {categories, error: null, isFetching: false} };
    }

    case CREATE_CATEGORY_FAILURE: {
      const {categories} = state.categoryList;
      return { ...state, categoryList: {categories, error: action.payload, isFetching: false} };
    }

    case RESET_NEW_CATEGORY: {
      return initialState;
    }

    case DELETE_CATEGORY: {
      const {categories, error} = state.categoryList;
      return { ...state, categoryList: {categories, error, isFetching: true} };
    }

    case DELETE_CATEGORY_SUCCESS: {
      const categories = state.categoryList.categories.filter(category => category.id !== action.payload.id);
      return { ...state, categoryList: {categories, error: null, isFetching: false} };
    }

    case DELETE_CATEGORY_FAILURE: {
      const {categories} = state.categoryList;
      return { ...state, categoryList: {categories, error: action.payload, isFetching: false} };
    }

    case RESET_DELETED_CATEGORY: {
      return initialState;
    }

    default: {
      return {
        ...state
      };
    }

  }
};

export default categoryReducer;