import React from 'react';
import {connect} from 'react-redux';
import TransactionDetails from './TransactionDetails';
import {Link, withRouter} from 'react-router-dom';
import {
  createTransaction,
  deleteTransaction,
  editTransaction, fetchTransactionsFile,
} from '../../store/actions/transactionActions';
import Grid from '@material-ui/core/Grid/Grid';
import Header from '../layout/Header';
import {withStyles} from '@material-ui/core';
import {sectionStyles} from '../../styles/sectionStyles';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import Fab from '@material-ui/core/Fab/Fab';
import DateAdapterFactory from "../../utils/date/DateAdapterFactory";
import DateAdapter from "../../utils/date/DateAdapter";
import TransactionsToImportModal from "./TransactionsToImportModal";
import {isNumberField} from "../../utils/validationUtils";

class TransactionForm extends React.Component {

  state = {
    id: this.props.transaction && this.props.transaction.id ? this.props.transaction.id: null,
    file: null,
    fileType: '',
    concept: this.props.transaction && this.props.transaction.concept ? this.props.transaction.concept: '',
    alias: this.props.transaction && this.props.transaction.alias ? this.props.transaction.alias: '',
    valueDate: this.props.transaction && this.props.transaction.valueDate ?
        DateAdapterFactory.createInstance(DateAdapter.getDateFromString(this.props.transaction.valueDate)).date :
        DateAdapterFactory.createInstance(DateAdapter.getToday()).date,
    amount: this.props.transaction && this.props.transaction.amount ? this.props.transaction.amount: '',
    category: this.props.transaction && this.props.transaction.category ? this.props.transaction.category: '',
    account: this.props.transaction && this.props.transaction.account ? this.props.transaction.account: '',
    groups: this.props.transaction && this.props.transaction.groups ? this.props.transaction.groups: [],
    comments: this.props.transaction && this.props.transaction.comments ? this.props.transaction.comments: '',
    ignoredToBalance: this.props.transaction && this.props.transaction.ignoredToBalance ? this.props.transaction.ignoredToBalance: false,
    pendingReturn: this.props.transaction && this.props.transaction.pendingReturn ? this.props.transaction.pendingReturn: false,
    accountTypeParams: null,
    subTransactions: this.props.transaction && this.props.transaction.subTransactions ? this.props.transaction.subTransactions: [],
    subTransactionsTotalAmount: this.props.transaction && this.props.transaction.subTransactionsTotalAmount ? this.props.transaction.subTransactionsTotalAmount : null,
    error: null,
    otp: '',
  };

  handleOnChangeInput = (e) => {
    e.preventDefault();
    this.setState({
      [e.target.id]: e.target.value
    });
  };

  handleChangeMultiple = (event) => {
    const groupsSelected = event.target.value;
    const newGroups = this.props.groupList.groups.map(elem => elem.id).filter(elem => groupsSelected.includes(elem));
    this.setState({
      'groups': newGroups,
    })
  };

  handleOnChangeSelect = (id, selectedOption) => {
    this.setState({
      [id]: selectedOption ? selectedOption.value : ''
    });
  };

  handleOnChangeSelectAccountType = (selectedOption) => {
    const {accounts, isFetching, error} = this.props.accountTypesList;
    if (isFetching || error) {
      return;
    }
    if (!selectedOption || !selectedOption.value) {
      return this.setState({
        accountTypeParams: null,
        fileType: '',
      });
    }
    const accountType = accounts && accounts.find(account => account.value === selectedOption.value);
    const accountTypeParams = accountType && accountType.params ? accountType.params.map(elem => {
      return {
        ...elem,
        value: elem.type === 'date' ? new Date(1987, 7, 13) : elem.value,
      };
    }) : null;
    this.setState({
      accountTypeParams,
      fileType: selectedOption.value,
    });
  };

  handleOnChangeInputAccountTypeParam = (id, value) => {
    const accountTypeParams = this.state.accountTypeParams.map(elem => elem.name === id ? {...elem, value: value } : elem);
    this.setState({
      accountTypeParams
    });
  };

  handleOnChangeCheckbox = (id, checked) => {
    this.setState({
      [id]: checked
    });
  };

  handleOnChangeFile = (e) => {
    e.preventDefault();
    this.setState({ file: e.target.files[0] });
  };

  handleOnClickProcess = (e) => {
    e.preventDefault();

    if (!this.state.file) {
      console.log('attach file first');
      return;
    }

    const {fileType, account, accountTypeParams} = this.state;
    const params = [...accountTypeParams.filter(elem => elem.globalRequired).map(elem => elem.value), fileType, account];
    const isValidParams = params.every(param => param && param !== '');
    if (!isValidParams) {
      return;
    }

    const accountTypeParamsToSend = accountTypeParams
    .filter(elem => elem.globalRequired)
    .map(elem => {
      return {
        name: elem.name,
        value: elem.type === 'date' ? DateAdapterFactory.createInstance(elem.value).getStartDayMoment().toISOString() : elem.value,
      }
    });

    const ignoredToBalance = this.props.ignoredToBalance;
    const pendingReturn = this.props.pendingReturn;

    const reader = new FileReader();
    reader.onload = () => {
      const fileData = reader.result;
      this.props.fetchTransactionsFile({fileType, account, ignoredToBalance, pendingReturn, fileData, accountTypeParams: accountTypeParamsToSend});
    };
    reader.readAsBinaryString(this.state.file);
  };

  handleChangeDate = (date) => {
    return this.setState({
      valueDate: date
    });
  };

  handleOnSetInputValue = (id, value) => {
    this.setState({
      [id]: value
    });
  };

  handleOnCreateTransaction = (e) => {
    e.preventDefault();
    const isValidState = this.isValidStateToSave();
    if (!isValidState) {
      return;
    }
    if (!isNumberField(this.state['amount'])) {
      this.setState({
        error: {
          amount: true
        }
      });
      return;
    }
    this.setState({
      error: {
        amount: false
      }
    }, () => {
      const paramsToAdd = this.getStateFilteredToAdd();
      const transaction = {
        paramsToAdd
      };
      const transactions = [transaction];
      const redirect = () => this.props.history.push('/transactions');
      this.props.createTransaction({transactions, redirect});
    });
  };

  isValidStateToSave () {
    const params = ['concept', 'valueDate', 'amount', 'category', 'account', 'groups'];
    return params.every(param => this.state[param] !== '');
  };

  getStateFilteredToAdd() {
    const params = ['concept', 'alias', 'valueDate', 'amount', 'category', 'account', 'ignoredToBalance', 'pendingReturn', 'groups'];
    return this.getFilteredParams({params});
  };

  getFilteredParams({params}) {
    return params.reduce((prev, next) => {
      return {
        ...prev,
        [next]: this.state[next]
      }
    }, {})
  };

  handleOnEditTransaction = (e) => {
    e.preventDefault();
    const isValidState = this.isValidStateToSave();
    if (!isValidState) {
      return;
    }
    if (!isNumberField(this.state['amount'])) {
      this.setState({
        error: {
          amount: true
        }
      });
      return;
    }
    this.setState({
      error: {
        amount: false
      }
    }, () => {
      const paramsToEdit = this.getStateFilteredToEdit();
      const redirect = () => this.props.history.push('/transactions');
      this.props.editTransaction({transaction: paramsToEdit, redirect});
    });
  };

  getStateFilteredToEdit() {
    const params = ['id', 'concept', 'alias', 'valueDate', 'amount', 'category', 'account', 'ignoredToBalance', 'pendingReturn', 'groups'];
    return this.getFilteredParams({params});
  };

  handleOnDeleteTransaction = (e) => {
    e.preventDefault();
    const redirect = () => this.props.history.push('/transactions');
    this.props.deleteTransaction({id: this.state.id, redirect});
  };

  getInfoToRender() {
    const {type} = this.props;
    if (type === 'edit') {
      return {
        title: 'Editar Transacción',
        component: <TransactionDetails transactionId={this.state.id} subTransactions={this.state.subTransactions} subTransactionsTotalAmount={this.state.subTransactionsTotalAmount} type={type} concept={this.state.concept} alias={this.state.alias} amount={this.state.amount} ignoredToBalance={this.state.ignoredToBalance} pendingReturn={this.state.pendingReturn} valueDate={this.state.valueDate} category={this.state.category} account={this.state.account} groups={this.state.groups} comments={this.state.comments} handleOnSetInputValue={this.handleOnSetInputValue} handleOnChangeInput={this.handleOnChangeInput} handleChangeDate={this.handleChangeDate} handleOnChangeSelect={this.handleOnChangeSelect} handleOnChangeCheckbox={this.handleOnChangeCheckbox} handleChangeMultiple={this.handleChangeMultiple} error={this.state.error} />,
        buttons: [
          <Fab color="primary" aria-label="add" size={`small`} onClick={this.handleOnDeleteTransaction}><DeleteIcon /></Fab>,
          <Fab color="primary" aria-label="cancel" size={`small`} component={Link} to={"/transactions"}><CloseIcon /></Fab>,
          <Fab color="primary" aria-label="save" size={`small`} onClick={this.handleOnEditTransaction}><SaveIcon /></Fab>
        ]
      }
    }

    if (type === 'upload') {
      return {
        title: 'Importar Transacciones',
        component: <TransactionDetails type={type} fileType={this.state.fileType} file={this.state.file} account={this.state.account} groups={this.state.groups} otp={this.state.otp} accountTypeParams={this.state.accountTypeParams} ignoredToBalance={this.state.ignoredToBalance} pendingReturn={this.state.pendingReturn} handleOnChangeSelect={this.handleOnChangeSelect} handleOnChangeFile={this.handleOnChangeFile} handleOnChangeInput={this.handleOnChangeInput} handleOnClickProcess={this.handleOnClickProcess} handleOnChangeSelectAccountType={this.handleOnChangeSelectAccountType} handleOnChangeInputAccountTypeParam={this.handleOnChangeInputAccountTypeParam}/>,
        buttons: [
          <Fab color="primary" aria-label="cancel" size={`small`}  component={Link} to={"/transactions"}><CloseIcon /></Fab>,
        ]
      };
    }

    return {
      title: 'Crear Transacción',
      component: <TransactionDetails type={type} concept={this.state.concept} alias={this.state.alias} amount={this.state.amount} ignoredToBalance={this.state.ignoredToBalance} pendingReturn={this.state.pendingReturn} valueDate={this.state.valueDate} category={this.state.category} account={this.state.account} groups={this.state.groups} handleOnSetInputValue={this.handleOnSetInputValue} handleOnChangeInput={this.handleOnChangeInput} handleChangeDate={this.handleChangeDate} handleOnChangeSelect={this.handleOnChangeSelect} handleOnChangeCheckbox={this.handleOnChangeCheckbox} handleChangeMultiple={this.handleChangeMultiple} error={this.state.error} />,
      buttons: [
        <Fab color="primary" aria-label="cancel" size={`small`} component={Link} to={"/transactions"}><CloseIcon /></Fab>,
        <Fab color="primary" aria-label="save" size={`small`} onClick={this.handleOnCreateTransaction}><SaveIcon /></Fab>
      ]
    };

  };

  render() {
    const {classes} = this.props;
    const {title, component, buttons} = this.getInfoToRender();
    return (
        <div className={classes.wrapperSection}>
          <Header title={title} />
          <div className={classes.wrapperSectionContent}>
            <div className={classes.appBarSpacer} />
            <Grid container className={classes.sectionContent}>
              <Grid item xs={12}>
                {component}
              </Grid>
            </Grid>
            <div className={classes.appBarSpacer} />
            <div className={classes.appBarSpacer} />
          </div>
          <div className={classes.navbarSection}>
            {buttons && buttons.map((button, index) => {
              return (
                  <span key={index}>
                    {button}
                  </span>
              )
            })}
          </div>
          <TransactionsToImportModal/>
        </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    accountTypesList: state.account.accountTypesList,
    groupList: state.group.groupList,
  };
};


const mapDispatchToProps = (dispatch) => {
  return {
    createTransaction: ({transactions, redirect}) => dispatch(createTransaction({transactions, redirect})),
    editTransaction: ({transaction, redirect}) => dispatch(editTransaction({transaction, redirect})),
    deleteTransaction: ({id, redirect}) => dispatch(deleteTransaction({id, redirect})),
    fetchTransactionsFile: ({fileType, account, ignoredToBalance, pendingReturn, fileData, accountTypeParams}) => dispatch(fetchTransactionsFile({fileType, account, ignoredToBalance, pendingReturn, fileData, accountTypeParams}))
  }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(sectionStyles)(TransactionForm)));